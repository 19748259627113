import Button from "../../items/Button";
import useLanguage from "../../hooks/useLanguage";
import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import SteamIcon from "@assets/icons/general/SteamIcon";
import { GoogleLogoModal } from "@assets/icons/general/GoogleLogo";
import st from "../../styles/components/Auth.module.scss";
import { useExternalAuth } from "@hooks/useExternalAuth";

export default function AltSignIn() {
  const L = useLanguage(["common", "auth", "forms"]);
  const { steam, metaMaskLogin, googleLogin } = useExternalAuth();

  return (
    <div className={st["alt-signin"]}>
      <h3>{L("other_top", {})} </h3>
      <div className={st["btn-content"]}>
        <Button
          method={steam}
          classType={["meta-steam"]}
          icon={<SteamIcon />}
        />
        <Button
          method={metaMaskLogin}
          classType={["meta-steam"]}
          icon={<MetaMaskIcon />}
        />
        <Button
          method={googleLogin}
          classType={["meta-steam"]}
          icon={<GoogleLogoModal />}
        />
      </div>
    </div>
  );
}
