import {
  chatOpenAtom,
  menuToggleAtom,
  profileDropdownOpenAtom,
} from "@store/global";
import { CasinoIcon, ChatIcon, MenuIcon, SportsIcon } from "./icons";
import { useAtom, useAtomValue } from "jotai";
import Link from "next/link";
import { useCallback } from "react";
import { cn } from "@lib";
import { usePathname } from "next/navigation";
import ProfileDropdown from "./profile-dropdown";
import useToken from "@hooks/useToken";

// Define navigation items
const NAV_ITEMS = [
  {
    label: "Casino",
    icon: CasinoIcon,
    href: "/casino",
  },
  {
    label: "Sports",
    icon: SportsIcon,
    href: "/sportsbook",
  },
] as const;

interface NavItemProps {
  icon: typeof CasinoIcon;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  href?: string;
}

function NavItem({ icon: Icon, label, isActive, onClick, href }: NavItemProps) {
  const content = (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-[6px] w-[80px] h-full relative cursor-pointer",
        isActive &&
          "after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-[#2B9EFF]"
      )}
      onClick={onClick}
    >
      <Icon
        className={cn("size-[22px]", isActive && "[&_path]:fill-[#2B9EFF]")}
      />
      <p
        className={cn(
          "text-xs text-[#E8E5FF] font-normal",
          isActive && "text-[#2B9EFF]"
        )}
      >
        {label}
      </p>
    </div>
  );

  if (href) {
    return (
      <Link href={href} className="h-full">
        {content}
      </Link>
    );
  }

  return content;
}

export function MobileBottomNav() {
  const pathname = usePathname();
  const [menuToggle, setMenuToggle] = useAtom(menuToggleAtom);
  const profileDropdownOpen = useAtomValue(profileDropdownOpenAtom);
  const [chatOpen, setChatOpen] = useAtom(chatOpenAtom);
  const token = useToken();

  const openMenuMobile = useCallback(() => {
    setChatOpen(false);
    setMenuToggle((prev) => !prev);
  }, [setChatOpen, setMenuToggle]);

  const openChatMobile = useCallback(() => {
    setMenuToggle(false);
    setChatOpen(!chatOpen);
  }, [setMenuToggle, setChatOpen, chatOpen]);

  return (
    <div
      className={cn(
        "fixed bottom-0 right-0 left-0 z-[5004] h-[67px] bg-[#141931] border-t border-solid border-[#E8E5FF]/5 grid justify-items-center 2lg:hidden",
        token ? "grid-cols-5" : "grid-cols-4"
      )}
    >
      <NavItem
        icon={MenuIcon}
        label="Menu"
        isActive={menuToggle && !profileDropdownOpen}
        onClick={openMenuMobile}
      />

      {NAV_ITEMS.map((item) => (
        <NavItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          href={item.href}
          isActive={
            pathname.includes(item.href) &&
            !menuToggle &&
            !chatOpen &&
            !profileDropdownOpen
          }
        />
      ))}

      <NavItem
        icon={ChatIcon}
        label="Chat"
        isActive={chatOpen && !profileDropdownOpen}
        onClick={openChatMobile}
      />

      {token && (
        <div>
          <ProfileDropdown trigger />
        </div>
      )}
    </div>
  );
}
