// base: The basic information of the user;
// profile: The profile object attribute;
// settings: Extra information to show on settings page;
// wallet: Adds extra information to the resulting payload related to wallet contents;
// affiliate: Adds extra information to the resulting payload related to affiliate contents;
// promotion: Adds the promotion object to the resulting payload;

import useSWR, {
  SWRConfiguration,
  unstable_serialize,
  useSWRConfig,
} from "swr";
import useToken from "./useToken";
import { NODE_API } from "@lib/api/api";
import useWallet from "./useWallet";
import { Filters, User } from "@/types/user";
import { useSession } from "next-auth/react";
import useRewards from "./useRewards";
import { resetAll } from "@lib/tools/reset";

// DO NOT USE useUserSession HERE
// WILL CAUSE DEPENDENCY LOOP

export const ALL_FILTERS: Filters[] = [
  "base",
  "profile",
  "settings",
  "wallet",
  "affiliate",
  "promotion",
];

export const getUser = async (
  token: string,
  filters: Filters[] = [],
  headers: Record<string, string> = {}
) => {
  const filter = filters?.join(",") || ALL_FILTERS.join(",");

  const params = new URLSearchParams({
    filter,
  });

  return NODE_API.get<User>(`v1/user?${params.toString()}`, {
    headers: { Authorization: `Bearer ${token}`, ...headers },
  })
    .then((res) => res.data)
    .then((user) => {
      if (user?.deleted) {
        try {
          resetAll();
        } catch {
          throw new Error("user_deleted");
        }
      }

      return user;
    });
};

type Options = {
  filters?: Filters[];
  swr?: SWRConfiguration;
  headers?: Record<string, string>;
  debug?: boolean;
};

const useUser = (
  options: Options = {
    filters: ALL_FILTERS,
  }
) => {
  const { cache } = useSWRConfig();
  const token = useToken();
  const session = useSession();
  const serverUser = session?.data?.user?.data;

  const key = unstable_serialize(["v1/user", options.filters]);

  // run only on server
  if (serverUser && (typeof window === "undefined" || !cache.get(key)?.data)) {
    cache.set(key, { data: serverUser });
  }

  return useSWR<User, User, SWRConfiguration>(
    token ? key : null,
    () => getUser(token, options.filters, options.headers),
    {
      keepPreviousData: true,
      fallbackData: cache.get(key)?.data,
      revalidateOnMount: !cache.get(key)?.data,
      ...options.swr,
    }
  );
};

export default useUser;

// fields migrate map

// user-data
// FIELD -> "success"
// FIELD -> "username"
// FIELD -> "balance"
// FIELD -> "vault_balance"
// FIELD -> "email_verified_at"
// FIELD -> "currency"
// FIELD -> "promo_eligible"
// FIELD -> "type"
// FIELD -> "2fa"
// FIELD -> "steam_link"
// FIELD -> "steam_config"
// FIELD -> "total_wagered"
// FIELD -> "affiliate_balance"
// success -> removed
// username -> username
// balance -> moved to wallet
// vault_balance -> moved to wallet / not especifically used
// email_verified_at -> auth.email_verified_at
// currency -> moved to wallet
// promo_eligible -> promotion.eligible
// type -> auth.type
// 2fa -> auth.has_2fa
// steam_link -> deprecated
// steam_config -> deprecated
// total_wagered -> profile.wagered_amount
// affiliate_balance -> affiliate.balance

// user-details
// FIELD -> 'username'
// FIELD -> 'email'
// FIELD -> 'language'
// FIELD -> 'registered_time'
// FIELD -> 'public_profile'
// FIELD -> 'public_statistics'
// FIELD -> 'wagered_amount'
// FIELD -> 'total_bets'
// FIELD -> 'win_count'
// FIELD -> 'chat_eligible'
// FIELD -> 'affiliate_eligible'
// FIELD -> 'intercom'
// FIELD -> 'default_payment_method'
// FIELD -> 'preferences'
// FIELD -> 'accepted_chat_rules'
// FIELD -> 'betRank'
// FIELD -> 'betRankLevel'
// FIELD -> 'nextRank'
// FIELD -> 'nextRankLevel'
// FIELD -> 'required_to_next_rank_usd'
// FIELD -> 'percentage'
// FIELD -> 'rakeback_boost'
// FIELD -> 'self_exclusion_expired'
// username -> username
// email -> email
// language -> preferences.language
// registered_time -> profile.registered_at
// public_profile -> preferences.public_profile
// public_statistics -> preferences.public_statistics
// wagered_amount -> profile.wagered_amount
// total_bets -> profile.bet_count
// win_count -> profile.win_count
// chat_eligible -> chat.eligible
// affiliate_eligible -> affiliate.eligible
// intercom -> intercom
// default_payment_method -> preferences.default_payment_method
// accepted_chat_rules -> chat.accepted_rules
// betRank -> rank.bet_rank
// betRankLevel -> rank.bet_rank_level
// nextRank -> rank.next_rank
// nextRankLevel -> rank.next_rank_level
// required_to_next_rank_usd -> rank.required_to_next_rank_usd
// percentage -> rank.percentage
// rakeback_boost -> rakeback_boost // missing
// self_exclusion_expired -> self_exclusion_expired // missing

/**
 * @deprecated
 */
export const useUserLegacy = (options: Options = {}) => {
  const token = useToken();
  const {
    data: user,
    mutate: mutateUser,
    ...swr
  } = useUser({
    ...options,
    filters: ALL_FILTERS,
  });

  const {
    activeBalance,
    walletBalanceList,
    currencies,
    balanceMutate: mutateWallet,
  } = useWallet();
  const { data: walletBalanceListData } = walletBalanceList;

  const { data: rewards, mutate: mutateRewards } = useRewards();

  const currency =
    currencies?.[walletBalanceListData?.active?.currency || "USD"];

  const data = {
    ...user,
    moderator: false, // missing
    self_exclusion: user?.self_exclusion || undefined, // fixing new false return
    total_wagered: user?.profile?.wagered_amount,
    balance_promo: walletBalanceListData?.active?.promotional || 0,
    username: user?.username,
    affiliate: user?.affiliate,
    balance: activeBalance,
    vault_balance: walletBalanceListData?.active?.vault || 0,
    email_verified_at: user?.auth?.email_verified_at,
    currency: {
      ...currency,
      code: walletBalanceListData?.active?.currency || "USD",
    },
    promo_eligible: user?.promotion?.eligible,
    type: user?.auth?.type,
    "2fa": user?.auth?.has_2fa,
    email: user?.email,
    language: user?.preferences?.language,
    registered_time: user?.profile?.registered_at,
    public_profile: user?.preferences?.public_profile,
    public_statistics: user?.preferences?.public_statistics,
    wagered_amount: user?.profile?.wagered_amount,
    total_bets: user?.profile?.bet_count,
    win_count: 0, // not used / deprecated
    chat_eligible: user?.chat?.eligible,
    affiliate_eligible: user?.affiliate?.eligible,
    intercom: user?.intercom,
    default_payment_method: user?.preferences?.default_payment_method,
    accepted_chat_rules: user?.chat?.accepted_rules,
    betRank: user?.rank?.bet_rank,
    betRankLevel: user?.rank?.bet_rank_level,
    nextRank: user?.rank?.next_rank,
    nextRankLevel: user?.rank?.next_rank_level,
    required_to_next_rank_usd: user?.rank?.required_to_next_rank_usd,
    percentage: user?.rank?.percentage,
    rakeback_boost: {
      ...rewards?.rakeback_boost,
      rakeback_rate: rewards?.rakeback_boost?.rate,
    },
  };

  return {
    data: token ? data : null,
    mutateRewards,
    mutateWallet,
    mutateUser,
    mutate: mutateUser,
    ...swr,
  };
};
