import { useEffect } from "react";
import { useState } from "react";
import GamesIcons from "@items/GamesIcons";
import { useRouter } from "next/router";
import GlobalSoundManager from "@items/GlobalSoundManager";
import FairButton from "./FairButton";

const GAME_CONFIG = {
  crash: { title: "Crash" },
  "case-opening": { title: "Case Open" },
  "gold-chests": { title: "Gold Chests" },
  dice: { title: "Dice" },
  plinko: { title: "Plinko" },
  roulette: { title: "Roulette" },
  keno: { title: "Keno" },
  mines: { title: "Mines" },
} as const;

export const GAMES_WITH_SOUND = ["Dice", "Plinko", "Roulette", "Keno", "Mines"];

interface GameInfo {
  title: string;
}

const getGameInfo = (game: string | undefined): GameInfo => {
  if (!game) return { title: "No Game" };
  return GAME_CONFIG[game] || { title: "slots" };
};

const GameTitle = ({ title }: { title: string }) => (
  <div className="flex gap-2 items-center">
    <GamesIcons code={title} />
    <h1 className="text-[17px]">{title}</h1>
  </div>
);

export default function GameHeading() {
  const router = useRouter();
  const [currentGame, setCurrentGame] = useState<GameInfo>({
    title: "No Game",
  });
  const { game } = router.query;

  useEffect(() => {
    setCurrentGame(getGameInfo(game as string));
  }, [game]);

  if (
    currentGame.title === "slots" ||
    game === "keno" ||
    game === "mines-game"
  ) {
    return null;
  }

  return (
    <div className="flex items-center justify-between pt-[25px] w-full pb-4">
      <GameTitle title={currentGame.title} />

      {game !== "case-opening" && (
        <div className="flex gap-2 items-center">
          {GAMES_WITH_SOUND.includes(currentGame.title) && (
            <GlobalSoundManager />
          )}
          <FairButton classItem={undefined} />
        </div>
      )}
    </div>
  );
}
