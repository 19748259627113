import useWallet from "@hooks/useWallet";
import Button from "@items/Button";
import NodeAPI from "@lib/api/nodeApi";
import st from "@styles/components/BalanceDropDown.module.scss";
import { useState, useEffect, useMemo, useCallback } from "react";
import { formatValueDisplay } from "@lib/tools/convert";
import GiftIcon from "@assets/icons/general/GiftIcon";
import ToolTip from "@items/ToolTip";
import useLanguage from "@hooks/useLanguage";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import { useUserSession } from "@hooks/useUserSession";
import WalletSettingsIcon from "@assets/icons/general/WalletSettingsIcon";
import ValueDisplay from "@items/ValueDisplay";
import CurrencyIcons from "@items/CurrencyIcons";
import { handleCatchPopup } from "@lib/tools/helpers";
import { classnames } from "@lib/tools/helpers";
import VaultIcon from "@assets/icons/general/VaultIcon";
import { logError } from "@lib/tools/logger";
import { PopoverTrigger } from "@components/new-ui/popover";
import { Popover, PopoverContent } from "@components/new-ui/popover";
import { cn } from "@lib";
import { setCookie } from "nookies";
import { useUpdateUserPreferences } from "@hooks/useUpdateUserPreferences";

function InPlay({
  mutate,
  activeBalance,
  userBalanceType,
  textValue,
  isValidating,
}) {
  return (
    <ToolTip
      placement="bottom"
      text={
        isValidating ? (
          <LoadingSpinner type={"inPlay"} />
        ) : (
          <div className={st["inPlay-balance-dropdown-container"]}>
            <div>
              {formatValueDisplay(activeBalance, {
                raw: false,
              })}
            </div>
          </div>
        )
      }
      size={"big"}
      transparent={undefined}
    >
      <span
        style={{ cursor: "pointer" }}
        onMouseEnter={() => {
          mutate();
        }}
        className="flex items-center gap-2"
      >
        {textValue}
        {userBalanceType ? <GiftIcon /> : null}
      </span>
    </ToolTip>
  );
}

export function BalanceDropdown() {
  const {
    activeBalance,
    inPlay,
    setUserBalanceType,
    userBalanceType,
    walletSetting,
    walletBalanceList,
    balanceMutate,
  } = useWallet();

  const { userData: data, mutate } = useUserSession();
  const [isOpen, setIsOpen] = useState(false);
  const L = useLanguage(["BalanceDropDown"]);
  const { data: walletBalanceListData, mutate: mutateWalletBalanceListMutate } =
    walletBalanceList;
  const { mutate: updatePreferences } = useUpdateUserPreferences();

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      mutateWalletBalanceListMutate();
    }
  }, [isOpen, balanceMutate, mutateWalletBalanceListMutate]);

  useEffect(() => {
    // is promo and there isnt promotional in wallet force it to be primary
    if (
      userBalanceType &&
      walletBalanceListData?.active?.promotional === undefined
    ) {
      balanceMutate();
      mutateWalletBalanceListMutate();
      setUserBalanceType(false);
      setCookie(null, "userBalanceType", String(false), {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        sameSite: "lax",
      });
      updatePreferences({
        user_balance_type: false,
      });
    }
  }, [
    balanceMutate,
    mutateWalletBalanceListMutate,
    setUserBalanceType,
    userBalanceType,
    walletBalanceListData?.active?.promotional,
  ]);

  const walletChanges = useCallback(
    async (fiatKey, url) => {
      try {
        await NodeAPI.put(`v1/user/currency/${url}`, {
          currency: fiatKey,
        });
        await Promise.all([mutate(), balanceMutate()]);
      } catch (error) {
        handleCatchPopup(logError)(error);
      }
    },
    [balanceMutate, mutate]
  );

  const quickBalanceSelect = useMemo(() => {
    if (
      walletBalanceListData?.displayed &&
      walletBalanceListData?.displayed.length > 0
    ) {
      return (
        <div className={st["balance-quick-select-container"]}>
          <span className={st["quick-select-heading"]}>
            <span>{L("quick_select_header", {})}</span>
          </span>
          {walletBalanceListData?.displayed.map((item, index) => {
            return (
              <Button
                key={index}
                classType={[
                  "full-width",
                  "balance-drop-item",
                  "balance-drop-item__history",
                ]}
                method={() => {
                  walletChanges(item.currency, "switch");
                }}
                extraContent={
                  <div className="flex gap-5 w-full justify-between">
                    <div className="flex items-center gap-[6px] text-xs text-primary-font-color">
                      <CurrencyIcons code={item.currency} size={"mid"} />
                      {item.currency}
                    </div>
                    <div className="flex items-center gap-[6px] text-sm text-primary-font-color">
                      <ValueDisplay
                        amount={item.amount}
                        raw={true}
                        size={"small"}
                        decimals={2}
                        textSize={12}
                        icon={false}
                        symbolPosition
                        currencyCode={item.currency}
                      />
                    </div>
                  </div>
                }
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={st["current-currency-displayed"]}>
          <span>{L("current_currency", {})}</span>
          <CurrencyIcons size={"small"} />
          {walletSetting}
        </div>
      );
    }
  }, [L, walletBalanceListData?.displayed, walletChanges, walletSetting]);

  return (
    <div>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger
          className={cn(
            "h-[48px] px-[15px] flex items-center text-[13px] justify-center rounded-md transition-all duration-300 ease [@media(max-width:767px)]:px-3 [@media(max-width:767px)]:rounded-r-none",
            isOpen ? "bg-[#212749]" : "bg-[#262c52]"
          )}
        >
          {!inPlay ? (
            <div className="flex items-center [@media(max-width:450px)]:gap-[2px] gap-[10px]">
              <div className="flex items-center gap-[5px]">
                <ValueDisplay
                  amount={activeBalance}
                  raw
                  debug
                  symbolPosition
                  icon={false}
                />

                <ToolTip
                  placement="bottom"
                  text={L("promotional_balance", {}) as string}
                  size={"small"}
                  transparent={undefined}
                >
                  {userBalanceType ? <GiftIcon /> : null}
                </ToolTip>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className={`transition-transform duration-200 ease-in-out ${isOpen ? "rotate-180" : "rotate-0"}`}
              >
                <path
                  d="M8.99961 12.6008C8.87046 12.6008 8.74551 12.5767 8.62476 12.5286C8.50336 12.4806 8.4023 12.4164 8.32158 12.3363L3.86598 7.91185C3.6884 7.73551 3.59961 7.51109 3.59961 7.23857C3.59961 6.96605 3.6884 6.74162 3.86598 6.56528C4.04356 6.38895 4.26956 6.30078 4.544 6.30078C4.81844 6.30078 5.04445 6.38895 5.22203 6.56528L8.99961 10.3164L12.7772 6.56528C12.9548 6.38895 13.1808 6.30078 13.4552 6.30078C13.7297 6.30078 13.9557 6.38895 14.1332 6.56528C14.3108 6.74162 14.3996 6.96605 14.3996 7.23857C14.3996 7.51109 14.3108 7.73551 14.1332 7.91185L9.67764 12.3363C9.58077 12.4325 9.47584 12.5004 9.36284 12.5402C9.24983 12.5806 9.12876 12.6008 8.99961 12.6008Z"
                  fill="#525984"
                />
              </svg>
            </div>
          ) : (
            <InPlay
              mutate={balanceMutate}
              activeBalance={activeBalance}
              userBalanceType={userBalanceType}
              textValue={L("inPlay", {})}
              isValidating={false}
            />
          )}
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="flex flex-col gap-2 w-[270px] shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2 max-w-[330px]"
        >
          <div
            className={` ${st["balance-select"]} ${!!data?.balance_promo ? st["has-promo"] : ""}
          `}
          >
            <Button
              method={() => {
                setUserBalanceType(false);
                setCookie(null, "userBalanceType", String(false), {
                  path: "/",
                  maxAge: 60 * 60 * 24 * 365,
                  sameSite: "lax",
                });
                updatePreferences({
                  user_balance_type: false,
                });
              }}
              activeItem={!userBalanceType}
              classType={["full-width", "balance-drop-item"]}
              customInnerContent={
                <div className={st["balance-drop-item"]}>
                  <div>
                    <span
                      className={` ${st["balance-name"]} ${!userBalanceType && st["balance-name__active"]}`}
                    >
                      {L("primary", {})}
                    </span>

                    <div className="mt-2 flex items-center text-[14px]">
                      <ValueDisplay
                        amount={Number(walletBalanceListData?.active?.primary)}
                        raw={true}
                        symbolPosition
                        icon={false}
                      />
                    </div>
                  </div>
                  {walletBalanceListData?.active?.promotional !== undefined && (
                    <div
                      {...classnames(
                        st,
                        "radio-icon",
                        !userBalanceType && "radio-icon__active"
                      )}
                    >
                      <div className={st["radio-icon-inner"]}></div>
                    </div>
                  )}
                </div>
              }
            />

            {walletBalanceListData?.active?.promotional !== undefined && (
              <Button
                activeItem={userBalanceType}
                classType={["full-width", "balance-drop-item"]}
                method={() => {
                  setUserBalanceType(true);
                  setCookie(null, "userBalanceType", String(true), {
                    path: "/",
                    maxAge: 60 * 60 * 24 * 365,
                    sameSite: "lax",
                  });
                  updatePreferences({
                    user_balance_type: true,
                  });
                }}
                customInnerContent={
                  <div className={st["balance-drop-item"]}>
                    <div>
                      <span
                        className={`flex items-baseline font-size gap-2 ${st["balance-name"]}
                         ${userBalanceType && st["balance-name__active"]}
                      `}
                      >
                        {L("promotional_balance", {})}
                        <GiftIcon />
                      </span>

                      <div className="mt-2 flex items-center text-[14px]">
                        <ValueDisplay
                          amount={Number(
                            walletBalanceListData?.active?.promotional
                          )}
                          raw
                          symbolPosition
                          icon={false}
                        />
                      </div>
                    </div>
                    <div
                      {...classnames(
                        st,
                        "radio-icon",
                        userBalanceType && "radio-icon__active"
                      )}
                    >
                      <div className={st["radio-icon-inner"]}></div>
                    </div>
                  </div>
                }
              />
            )}

            {!!walletBalanceListData?.active?.vault && (
              <div className={st["vault-balance-container"]}>
                <div className={st["desc"]}>
                  <VaultIcon width={20} />
                  <span>{L("vault_notice", {})}</span>
                </div>
              </div>
            )}
          </div>

          <div className={st["balance-drop-down-footer"]}>
            <div className={st["balance-drop-down-footer-line"]}></div>
            {quickBalanceSelect}

            <Button
              isShallow
              buttonType={"link"}
              query={{ modal: "wallet-settings" }}
              text={L("wallet_settings", {})}
              linkMethod={() => {
                setIsOpen(true);
              }}
              classType={["drop-down", "wallet-setting", "icon-left"]}
              icon={<WalletSettingsIcon />}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
