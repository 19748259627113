import API from "@lib/api/api";
import useUser from "./useUser";
import { Preferences } from "@/types/user";

export function useUpdateUserPreferences() {
  const { mutate } = useUser();

  return {
    mutate: async function (preferences: Partial<Preferences>) {
      const response = await API.post("user/update-settings", {
        preferences,
      });

      if (response.data.success) {
        await mutate();
      }

      return response;
    },
  };
}
