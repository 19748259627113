import { Button } from "@components/new-ui/button";
import { Input } from "@components/new-ui/input";
import useLanguage, { Dict } from "@hooks/useLanguage";
import { getSessionData } from "@hooks/useSessions";
import API from "@lib/api/api.js";
import { PropsWithChildren, useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useModifySearchParams } from "@hooks/useModifySearchParams";
import { NewLogoIcon } from "@assets/icons/general/NewLogo";

export type LoginForm = {
  username: string;
  password: string;
  twoFactorCode?: string;
};

type ResponseData = {
  success: boolean;
  tokens?: {
    jwt: string;
    refresh_token: string;
  };
  error?: string;
};

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ResponseData>(null);

  const submit = useCallback(
    async ({ username, password, twoFactorCode: tfa }: LoginForm) => {
      setIsLoading(true);

      API.post<ResponseData>("/user/log-in", {
        device: await getSessionData(),
        username,
        password,
        ...(tfa && { code: tfa, security_code: tfa }),
      })
        .then((r) => r.data)
        .then((data) => {
          if (data?.error) throw data?.error;

          setData(data);
          setError(null);
          return data;
        })
        .catch((err) => {
          setData(err);
          setError(err?.data?.error || err?.error || err);
        })
        .finally(() => setIsLoading(false));
    },
    []
  );

  const isSecurityCheck = error === "er_security_check_required";
  const has2FA = error === "two-factor-missing";

  return {
    error,
    isSecurityCheck,
    has2FA,
    submit,
    isLoading,
    data,
  };
};

type LoginProps = PropsWithChildren<{
  form: UseFormReturn<LoginForm>;
  onSubmit: (data: LoginForm) => void;
  isLoading?: boolean;
}>;

export default function Login({ form, onSubmit }: LoginProps) {
  const L = useLanguage(["forms"]) as any;

  const { register, handleSubmit, watch } = form;
  const { add } = useModifySearchParams();

  const username = watch("username");
  const password = watch("password");
  const disabled = !username || !password;

  return (
    <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-[10px]">
        <div className="flex justify-start gap-4 items-center">
          <NewLogoIcon height={25} width={25} />
          <h2 className="text-lg !font-medium">{L("login", {})}</h2>
        </div>
      </div>
      <div className="grid gap-2.5">
        <div className="grid gap-4 relative">
          <label htmlFor="username-input">
            <Dict
              name="username"
              as="span"
              section="forms"
              className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer"
            />
          </label>

          <div>
            <Input
              id="username-input"
              {...register("username", { required: true })}
            />
          </div>
        </div>

        <div className="grid gap-4">
          <label htmlFor="password-input">
            <Dict
              name="password"
              as="span"
              section="forms"
              className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer"
            />
          </label>

          <div>
            <Input
              id="password-input"
              type="password"
              {...register("password")}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 pt-[14px] pb-2">
          <Button className="border" type="submit" disabled={disabled}>
            {L("login", {})}
          </Button>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => {
              add("recover", "true");
            }}
            className="[&_span]:text-[#7179a5] text-[13px] hover:text-[#a1a9d5] hover:brightness-125"
          >
            <Dict name="recover_account" as="span" section="forms" />
          </button>
        </div>
      </div>
    </form>
  );
}
