import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function useTheaterMode(type = "slot") {
  const [screenHeight, setScreenHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [theaterMode, setTheaterMode] = useState(
    type === "original" ? false : true
  );
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);

      const handleResize = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  const slotGameContainerStyle = useMemo(
    () => ({
      maxHeight:
        theaterMode &&
        screenHeight -
          (screenWidth > 1100 ? 148 : screenWidth < 724 ? 250 : 220),
    }),
    [screenHeight, screenWidth, theaterMode]
  );

  const aspectRatioStyle = useMemo(
    () => ({
      height:
        theaterMode &&
        (screenWidth > 1100
          ? "calc(100vh - 148px)"
          : screenWidth < 724
            ? "calc(100vh - 250px)"
            : "calc(100vh - 230px)"),
      paddingBottom: theaterMode && 0,
    }),
    [screenWidth, theaterMode]
  );

  const toggleTheaterMode = useCallback(() => {
    setTheaterMode((prevMode) => !prevMode);
  }, []);

  //remove max-width from inner-body-container when in theater mode method
  const removeMaxWidth = useCallback(() => {
    const element = document.querySelector(".inner-body-container");
    const elementBody = document.querySelector(".body-content-container");

    const currentPath = router.asPath;
    const isInCasinoGamesPage = currentPath.includes(`${router.query.game}`);

    if (element && isInCasinoGamesPage) {
      element.style.maxWidth = "";
      elementBody.style.paddingTop = "";
    }
  }, [router.asPath, router.query.game]);

  useEffect(() => {
    const element = document.querySelector(".inner-body-container");
    const elementBody = document.querySelector(".body-content-container");

    const currentPath = router.asPath;
    const isInCasinoGamesPage = currentPath.includes(
      `${router.query.game || router.query.slug}`
    );

    if (element && isInCasinoGamesPage) {
      if (theaterMode) {
        element.style.maxWidth = "100%";
        elementBody.style.paddingTop = "0px";
      } else {
        element.style.maxWidth = "";
        elementBody.style.paddingTop = "";
      }
    }

    return () => {
      if (element && isInCasinoGamesPage) {
        element.style.maxWidth = "";
        elementBody.style.paddingTop = "";
      }
    };
  }, [
    theaterMode,
    router.asPath,
    router.query.game,
    router.query.slug,
    screenWidth,
  ]);

  return {
    theaterMode,
    toggleTheaterMode,
    slotGameContainerStyle,
    aspectRatioStyle,
    setTheaterMode,
    removeMaxWidth,
  };
}
