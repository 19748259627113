import { chatOpenAtom } from "@store/global";
import { useAtomValue } from "jotai";
import dynamic from "next/dynamic";

const Chat = dynamic(() => import("@components/chat"));

interface ChatProps {
  chatSocket: unknown;
  isMod: boolean;
}

export default function ChatContainer({ chatSocket, isMod }: ChatProps) {
  const chatOpen = useAtomValue(chatOpenAtom);

  if (!chatOpen) return null;

  return <Chat isMod={isMod} chatSocket={chatSocket} />;
}
