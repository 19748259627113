import { SVGProps } from "react";

export default function InfoBordered({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.875 7.35886C6.875 7.15995 6.95402 6.96918 7.09467 6.82853C7.23532 6.68788 7.42609 6.60886 7.625 6.60886C7.82391 6.60886 8.01468 6.68788 8.15533 6.82853C8.29598 6.96918 8.375 7.15995 8.375 7.35886V11.8589C8.375 12.0578 8.29598 12.2485 8.15533 12.3892C8.01468 12.5298 7.82391 12.6089 7.625 12.6089C7.42609 12.6089 7.23532 12.5298 7.09467 12.3892C6.95402 12.2485 6.875 12.0578 6.875 11.8589V7.35886ZM7.625 3.66211C7.42609 3.66211 7.23532 3.74113 7.09467 3.88178C6.95402 4.02243 6.875 4.2132 6.875 4.41211C6.875 4.61102 6.95402 4.80179 7.09467 4.94244C7.23532 5.08309 7.42609 5.16211 7.625 5.16211C7.82391 5.16211 8.01468 5.08309 8.15533 4.94244C8.29598 4.80179 8.375 4.61102 8.375 4.41211C8.375 4.2132 8.29598 4.02243 8.15533 3.88178C8.01468 3.74113 7.82391 3.66211 7.625 3.66211Z"
        fill="#E8E5FF"
        fill-opacity="0.75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.625 0.625C3.48275 0.625 0.125 3.98275 0.125 8.125C0.125 12.2673 3.48275 15.625 7.625 15.625C11.7673 15.625 15.125 12.2673 15.125 8.125C15.125 3.98275 11.7673 0.625 7.625 0.625ZM1.625 8.125C1.625 9.7163 2.25714 11.2424 3.38236 12.3676C4.50758 13.4929 6.0337 14.125 7.625 14.125C9.2163 14.125 10.7424 13.4929 11.8676 12.3676C12.9929 11.2424 13.625 9.7163 13.625 8.125C13.625 6.5337 12.9929 5.00758 11.8676 3.88236C10.7424 2.75714 9.2163 2.125 7.625 2.125C6.0337 2.125 4.50758 2.75714 3.38236 3.88236C2.25714 5.00758 1.625 6.5337 1.625 8.125Z"
        fill="#E8E5FF"
        fill-opacity="0.75"
      />
    </svg>
  );
}
