import { useCallback } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export const useModifySearchParams = () => {
  const { replace } = useRouter();
  const search = useSearchParams();
  const pathname = usePathname();

  const add = useCallback(
    (key: string, value: string) => {
      const url = new URLSearchParams(search);
      url.set(key, value);
      replace(`${pathname}?${url.toString()}`);
    },
    [pathname, search, replace]
  );

  const remove = useCallback(
    (key: string) => {
      const url = new URLSearchParams(search);
      url.delete(key);
      replace(`${pathname}?${url.toString()}`);
    },
    [pathname, search, replace]
  );

  return { add, remove };
};
