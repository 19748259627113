import CircleArrowIcon from "@assets/icons/general/CircleArrowIcon";
import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import SteamIcon from "@assets/icons/general/SteamIcon";
import BgBanner from "@assets/Images/banner-blackjack.png";
import Character from "@assets/Images/rainman-blackjack.png";
import THEHAT from "@assets/Images/rainman_hat.png";
import useAuth from "@hooks/useAuth";
import { useExternalAuth } from "@hooks/useExternalAuth";
import useLanguage from "@hooks/useLanguage";
import clsx from "clsx";
import { motion } from "framer-motion";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

export function LoggedOutBanner({ heading }) {
  useAuth(true);
  const L = useLanguage(["pageContent", "common"]);
  const { metaMaskLogin, steam } = useExternalAuth();
  const { push } = useRouter();

  return (
    <motion.div
      className="@container flex h-full w-full justify-between gap-0 sm:gap-[40px] min-h-[270px] relative mb-5 shadow-[0_0_30px_0_rgba(0,119,219,.3)] rounded-xl mt-2.5"
      animate={{
        width: "100%",
      }}
      transition={{ duration: 0.2 }}
    >
      <Image
        alt="banner image"
        src={BgBanner}
        priority
        quality={97}
        className="object-cover object-right float-right w-full h-full rounded-md absolute"
        style={{
          zIndex: -1,
        }}
      />

      {/* overlay border */}
      <div
        className="absolute w-full h-full z-10 pointer-events-none"
        style={{
          border: "2.5px solid #2A9CFB",
          borderRadius: "6px",
        }}
      />

      <div className="relative z-1 px-6 xs:px-14 py-5 xs:py-8">
        <div className="flex flex-col h-full max-w-[625px] gap-4 leading-normal">
          <motion.h1
            className="text-base sm:text-[25px] font-bold sm:leading-[32.5px]"
            transition={{ duration: 0.2 }}
          >
            {heading || L("homeBanner.loggedOut_banner.welcome")}
          </motion.h1>

          <div className="font-medium break-words hidden xs:block md:text-md">
            <span className="text-white/85">
              {L("homeBanner.loggedOut_banner.firstSpan")}{" "}
              <span className="text-[#FDD866]">
                {L("homeBanner.loggedOut_banner.secondSpan")}
              </span>{" "}
              {L("homeBanner.loggedOut_banner.thirdSpan")}
              <span className="text-[#FDD866]">
                {L("homeBanner.loggedOut_banner.fourthSpan")}
              </span>{" "}
              {L("homeBanner.loggedOut_banner.fifthSpan")}
            </span>
          </div>

          <div className="flex items-center gap-1 sm:gap-[18px] mt-4 sm:mt-auto flex-col sm:flex-row">
            <div className="bg-white/5 p-1 sm:p-2 border border-solid border-white/5 rounded-full z-19 w-full sm:max-w-[320px] max-w-[220px]">
              <button
                onClick={() => push(`?modal=auth&tab=login`)}
                className={clsx(
                  "bg-gradient-to-t from-[#0064b8] to-[#0077db] border border-[#2099ff] rounded-full text-white cursor-pointer transition-all duration-200 hover:brightness-110",
                  "text-normal font-medium whitespace-nowrap",
                  "flex justify-center items-center gap-3 py-2.5 sm:py-[15px] w-full"
                )}
              >
                <span className="text-sm leading-normal">
                  {L("homeBanner.loggedOut_banner.button")}
                </span>
                <CircleArrowIcon className="h-[25px] w-[25px]" />
              </button>
            </div>

            <span className="text-[rgba(232,229,255,0.5)] text-normal block">
              or
            </span>

            <div
              className={clsx(
                "bg-white/5 p-1 sm:p-2 border border-solid border-white/5 rounded-full flex gap-2 items-center",
                "[&>*]:transition-all [&>*]:duration-300 [&>*]:hover:brightness-110 [&>*]:ease-in-out"
              )}
            >
              <button
                onClick={steam}
                className="items-center bg-white/10 border border-solid border-white/5 rounded-full flex p-3 sm:p-4 hover:bg-[hsla(0,0%,100%,0.25)]"
              >
                <SteamIcon className="w-5 h-5 sm:h-[25px] sm:w-[25px] text-white [&_path]:fill-white" />
              </button>

              <button
                onClick={metaMaskLogin}
                className="items-center bg-white/10 border border-solid border-white/5 rounded-full flex p-3 sm:p-4 hover:bg-[hsla(0,0%,100%,0.25)]"
              >
                <MetaMaskIcon className="w-5 h-5 sm:h-[25px] sm:w-[25px]" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Image
        alt="Character image"
        src={Character}
        priority
        quality={99}
        className="pointer-events-none hidden @5xl:block object-cover object-right float-right h-full rounded-md absolute right-0 top-0"
        style={{
          zIndex: -1,
        }}
      />

      <Image
        data-hat
        alt="hat"
        src={THEHAT}
        className="absolute z-30 hidden @5xl:block pointer-events-none"
        quality={99}
        style={{
          right: 116,
          top: -24,
        }}
      />
    </motion.div>
  );
}
