import { NewLogoIcon } from "@assets/icons/general/NewLogo";
import SortByIcon from "@assets/svgs/SortByIcon";
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import usePopup from "@hooks/usePopup";
import Button from "@items/Button";
import InputItem from "@items/InputItem";
import ProviderDropDown from "@items/ProviderDropdown";
import SecondaryDropdown from "@items/SecondaryDropdown";
import { NODE_API } from "@lib/api/api";
import { EVENTS, usePubSub } from "@lib/pubsub";
import { classnames, isMobileUserAgent } from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import st from "@styles/components/SlotsList.module.scss";
import { clsx } from "clsx";
import dynamic from "next/dynamic";
import { useRouter, useSearchParams } from "next/navigation";
import { memo, startTransition, useEffect, useMemo, useState } from "react";
import useSWR, { unstable_serialize } from "swr";
import GhostSlots from "./GhostSlots";
import QuickLinks from "./quick-links";

const Slots = dynamic(() => import("@assets/icons/games/Slots"), {});
const SlotLink = dynamic(() => import("@items/SlotLink"), {});
const Baccarat = dynamic(() => import("@assets/icons/games/Baccarat"), {});
const BlackJack = dynamic(() => import("@assets/icons/games/Blackjack"), {});
const RouletteSlots = dynamic(
  () => import("@assets/icons/games/RouletteSlots")
);
const GameShowIcon = dynamic(() => import("@assets/icons/games/GameShowIcon"));
const LiveGamesIcon = dynamic(
  () => import("@assets/icons/games/LiveGamesIcon")
);
const NewReleasesIcon = dynamic(
  () => import("@assets/icons/games/NewReleasesIcon")
);
const SlotsHourglassIcon = dynamic(
  () => import("@assets/icons/games/SlotsHourglassIcon")
);
const CarouselSlider = dynamic(() => import("@items/CarouselSlider"));

const getIconAndRoute = (category) => {
  switch (category) {
    case "popular_blackjack":
      return {
        icon: BlackJack,
        route: "/casino/blackjack",
      };
    case "popular_baccarat":
      return {
        icon: Baccarat,
        route: "/casino/baccarat",
      };
    case "popular_roulette":
      return {
        icon: RouletteSlots,
        route: "/casino/roulette",
      };
    case "popular_game_shows":
      return {
        icon: GameShowIcon,
        route: "/casino/game-shows",
      };
    case "popular_live_games":
      return {
        icon: LiveGamesIcon,
        route: "/casino/live-casino",
      };
    case "newReleases":
      return {
        icon: NewReleasesIcon,
      };
    case "rainbetGames":
      return {
        icon: () => <NewLogoIcon width="18" height="18" />,
        route: "/casino/originals",
      };
    case "home.recently_played_games":
      return {
        icon: SlotsHourglassIcon,
      };
    default:
      return {
        icon: Slots,
        route: "/casino/slots",
      };
  }
};

const Heading = ({ heading, width = 18, height = 18, fill = "#7179A5" }) => {
  const { icon: Icon, route } = getIconAndRoute(heading);
  const L = useLanguage(["meta", "common", "SlotsList"]);
  const { push } = useRouter();

  return (
    <span
      className={`${st["heading-item"]} ${st["clickable"]}`}
      onClick={() => {
        if (route) push(route);
      }}
    >
      <Icon
        width={width}
        height={height}
        fill={fill}
        className={st["heading-icon"]}
      />
      {L(heading, {})}
    </span>
  );
};

export const getSlotList = async (
  data: {
    device: "mobile" | "desktop";
    sort_by:
      | "a-z"
      | "z-a"
      | "popular"
      | "recommended"
      | "recommended_mini"
      | "release"
      | "random";
    search: string;
    grouping: string;
    provider: string | string[];
    num: number;
    page_num: number;
    category?: string;
    theme?: string;
    type?: string;
  },
  headers = {}
) => {
  const res = await NODE_API.get("v1/slots/list", { params: data, headers });
  return res?.data || [];
};

const SlotsList = ({
  heading,
  number = 36,
  category = null,
  theme = null,
  searchSection = false,
  sortBy = "recommended",
  setSortBy,
  carousel = false,
  isLive,
  grouping,
  popularFormat = false,
  providerName,
  providerSearchArea,
  isStatic = false,
  initialData = [],
  count = Infinity,
  shouldShowQuickLinks = false,
}: {
  heading: string;
  number?: number;
  category?: string;
  theme?: string;
  searchSection?: boolean;
  sortBy?:
    | "a-z"
    | "z-a"
    | "popular"
    | "recommended"
    | "recommended_mini"
    | "release"
    | "random";
  // eslint-disable-next-line no-unused-vars
  setSortBy?: (sortBy: string) => void;
  carousel?: boolean;
  isLive?: boolean;
  grouping?: string;
  popularFormat?: boolean;
  providerName?: string;
  providerSearchArea?: boolean;
  isStatic?: boolean;
  initialData?: any;
  count?: number;
  shouldShowQuickLinks?: boolean;
}) => {
  const query = useSearchParams();
  const queryProvider = query.get("provider");
  const [localSearch, setLocalSearch] = useState("");
  const providerList = usePubSub(EVENTS.PROVIDERS);
  const [provider, setProvider] = useState(
    queryProvider === null ? [] : [queryProvider]
  );
  const [page, setPage] = useState(1);
  const L = useLanguage(["SlotsList", "Slots", "Casino"]);
  const setMessage = usePopup();
  const isMobile = useMobile({ breakPointThree: 600 } as any);

  const minSearchLength = useMemo(() => {
    if (localSearch.length > 0 && localSearch.length < 3)
      return "Please enter at least 3 characters";
    return false;
  }, [localSearch]);

  const swrOptions = useMemo(
    () => ({
      num: number,
      category,
      theme,
      page_num: page,
      search: localSearch && localSearch.length > 2 ? localSearch : "",
      provider: providerName
        ? [providerName]
        : provider.length === 0
          ? "all"
          : provider,
      sort_by: sortBy,
      type: isLive ? "live" : null,
      grouping,
    }),
    [
      category,
      grouping,
      isLive,
      localSearch,
      number,
      page,
      provider,
      providerName,
      sortBy,
      theme,
    ]
  );

  const hash = useMemo(
    () => unstable_serialize(["v1/slots/list", swrOptions]),
    [swrOptions]
  );

  const {
    data: list,
    isLoading,
    error,
  } = useSWR(
    isStatic ? null : ["v1/slots/list", swrOptions],
    ([, data]) =>
      getSlotList({
        ...data,
        device: isMobileUserAgent() ? "mobile" : "desktop",
        grouping: data?.grouping || undefined,
      })
        .then((res) => {
          if (res.error) {
            throw res.error || "er_launching_game";
          }

          return res;
        })
        .catch((err) => {
          throw err.response.data.error || "er_launching_game";
        }),
    {
      revalidateOnMount: !initialData?.length,
      revalidateOnFocus: false,
      fallback: {
        [hash]: initialData,
      },
    }
  );

  const [slotsList_, setList] = useState(initialData);
  const slotsList = isStatic ? initialData : slotsList_;
  const noResults = slotsList?.length === 0;
  const resultCount = list?.count || count;

  useEffect(() => {
    if (localSearch !== "") {
      setPage(1);
    }
  }, [localSearch]);

  useEffect(() => {
    if (!setSortBy) return;
    startTransition(() => {
      setSortBy(sortBy);
      setPage(1);
    });
  }, [sortBy, setSortBy]);

  useEffect(() => {
    startTransition(() => {
      setProvider(queryProvider === null ? [] : [queryProvider]);
      setPage(1);
    });
  }, [queryProvider]);

  useEffect(() => {
    if (isLoading || isStatic || !list?.games) return;

    if (error) {
      setMessage({ code: "responses.er_network", type: 2 });
      logError("SLOT LIST ERROR", error);
      return;
    }

    if (swrOptions.page_num > 1) {
      setList((prev) => {
        const uniques = list?.games.filter(
          (game) => !prev.some((prevGame) => prevGame.id === game.id)
        );
        return [...prev, ...uniques];
      });
      return;
    }

    setList(list?.games);
  }, [isLoading, isStatic, list, error, setMessage, swrOptions.page_num]);

  const options = useMemo(
    () => [
      ...(
        providerList?.filter((provider) =>
          provider?.categories?.some((category) =>
            category.name.includes(grouping)
          )
        ) || []
      )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((provider) => ({
          value: provider.name,
          label: provider.name,
          count:
            provider.categories.find((category) => category.name === grouping)
              ?.count ?? 0,
        })),
    ],
    [providerList, grouping]
  );

  return (
    <section
      className={clsx(
        classnames(st, "list-container", isMobile)?.className,
        "overflow-x-hidden pt-1"
      )}
    >
      {heading ? <Heading heading={heading} /> : null}
      {searchSection ? (
        <div className={`${st["search-area"]} ${st[isMobile as any]}`}>
          <div className={`${st["row-area"]}`}>
            <InputItem
              classType={["search", "search-input", "full-width"]}
              name="search"
              value={localSearch}
              method={setLocalSearch}
              placeholder={L("search", {})}
              toolTip={{
                text: minSearchLength,
                forceShow: minSearchLength,
                showMode: true,
                placement: "top",
              }}
              icon={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.19526 9.19526C9.45561 8.93491 9.87772 8.93491 10.1381 9.19526L13.8047 12.8619C14.0651 13.1223 14.0651 13.5444 13.8047 13.8047C13.5444 14.0651 13.1223 14.0651 12.8619 13.8047L9.19526 10.1381C8.93491 9.87772 8.93491 9.45561 9.19526 9.19526Z"
                    fill="#5B628C"
                    stroke="#5B628C"
                    strokeWidth="0.5"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.66667 3.33333C4.82572 3.33333 3.33333 4.82572 3.33333 6.66667C3.33333 8.50762 4.82572 10 6.66667 10C8.50762 10 10 8.50762 10 6.66667C10 4.82572 8.50762 3.33333 6.66667 3.33333ZM2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667Z"
                    fill="#5B628C"
                    stroke="#5B628C"
                    strokeWidth="0.5"
                  />
                </svg>
              }
            />
            <div className={`${st["filter-area"]} ${st[isMobile as any]}`}>
              {!providerSearchArea && (
                <ProviderDropDown
                  options={options}
                  onSelectOption={(value) => {
                    startTransition(() => {
                      // setProvider(value);
                      setProvider((providers) => {
                        if (providers.includes(value))
                          return providers.filter((p) => p !== value);
                        else return [...providers, value];
                      });
                      setPage(1);
                    });
                  }}
                  onClear={() => {
                    startTransition(() => {
                      setProvider([]);
                      setPage(1);
                    });
                  }}
                  selectedOptions={provider}
                  icon={false}
                  labelText={L("provider_label", {})}
                  name={L("provider", {})}
                  placeholder={L("provider", {})}
                  id="provider_secondary"
                />
              )}
              <SecondaryDropdown
                id="sort_by_secondary"
                selectedOption={sortBy}
                icon={<SortByIcon />}
                name={L("sort_by", {})}
                labelText={L("sort_by", {})}
                onSelectOption={(value) => {
                  startTransition(() => {
                    setSortBy(value);
                    setPage(1);
                  });
                }}
                options={[
                  { value: "recommended", label: L("recommended", {}) },
                  { value: "popular", label: L("popular", {}) },
                  { value: "release", label: L("release", {}) },
                  { value: "a-z", label: L("alphabetical", {}) + " (A-Z)" },
                  { value: "z-a", label: L("alphabetical", {}) + " (Z-A)" },
                ]}
                placeholder={L("sort_by", {})}
              />
            </div>
          </div>

          {shouldShowQuickLinks && <QuickLinks setSortBy={setSortBy} />}
        </div>
      ) : null}
      {noResults || error ? (
        <div className={st["no-found-message"]}>
          {L("no_results_found", {})}
        </div>
      ) : (
        <div
          className={
            popularFormat ? st["lists-container__mini"] : st["lists-container"]
          }
        >
          {carousel && slotsList?.length > 3 ? (
            <CarouselSlider
              items={slotsList}
              resultCountNumber={Number.isInteger(carousel) ? carousel : 8}
            />
          ) : (
            <div className={`${st["slots-list"]} ${st[isMobile as any]}`}>
              {isLoading ? (
                <GhostSlots mini={popularFormat} />
              ) : slotsList?.length > 0 ? (
                slotsList.map((item) => (
                  <SlotLink key={item.id} grouping={grouping} {...item} />
                ))
              ) : null}
            </div>
          )}
        </div>
      )}
      <div className={st["more-slots-container"]}>
        {searchSection ? (
          <>
            {resultCount > number &&
            slotsList.length < resultCount &&
            !noResults ? (
              <Button
                text={L("load_more", {})}
                method={() => {
                  if (isLoading) return;
                  if (isStatic) return;

                  setPage((p) => p + 1);
                }}
                classType={["load-more"]}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </section>
  );
};

export default memo(SlotsList);
