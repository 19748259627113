import { SVGProps } from "react";

export default function Hidden(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.06794 9.05706C1.88576 7.76882 0.875 6 0.875 6C0.875 6 3.375 1.625 9 1.625C9.81532 1.625 10.565 1.71692 11.2509 1.8741L9.56166 3.56334C9.38112 3.5219 9.19312 3.5 9 3.5C7.61929 3.5 6.5 4.61929 6.5 6C6.5 6.19312 6.5219 6.38112 6.56334 6.56166L4.06794 9.05706ZM6.7491 10.1259C7.43502 10.2831 8.18468 10.375 9 10.375C14.625 10.375 17.125 6 17.125 6C17.125 6 16.1142 4.23118 13.9321 2.94294L11.4367 5.43834C11.4781 5.61888 11.5 5.80688 11.5 6C11.5 7.38071 10.3807 8.5 9 8.5C8.80688 8.5 8.61888 8.4781 8.43834 8.43666L6.7491 10.1259ZM10.875 6C10.875 6.47985 10.6919 6.95971 10.3258 7.32583C9.95971 7.69194 9.47985 7.875 9 7.875L10.875 6ZM9 4.125C8.52015 4.125 8.04029 4.30806 7.67417 4.67417C7.30806 5.04029 7.125 5.52015 7.125 6L9 4.125ZM14 0.375L3.375 11L4 11.625L14.625 1L14 0.375Z"
        fill="#7179A5"
      />
    </svg>
  );
}
