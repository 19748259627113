import st from "./raffle-side-bar.module.scss";
import { memo, useState, useEffect } from "react";
import { useAtom } from "jotai";
import { raffleDetailsAtom } from "../../jotai-raffle";
import useRaffleConvert from "../../helpers/use-raffle-convert";
import MiniTicketIcon from "../../assets/mini-ticket.png";
import Image from "next/image";
import { useDict } from "@/hooks/useLanguage";
import Link from "next/link";

function RaffleSideBar() {
  const [raffleDetails, _] = useAtom(raffleDetailsAtom);
  const timeObj = useTimeLeft(raffleDetails?.ends_at);
  const dict = useDict("raffle");
  const convertRaffleValue = useRaffleConvert();

  // console.log("time code", timeObj);
  let displayValue;
  if (timeObj?.timeScale === "day") {
    displayValue = (
      <>
        {timeObj?.value}{" "}
        {dict({ name: timeObj.value === 1 ? "day" : "days", as: "span" })}
      </>
    );
  } else if (timeObj?.timeScale === "hour") {
    displayValue = <>{timeObj?.value} H</>;
  } else if (timeObj?.timeScale === "soon") {
    displayValue = dict({ name: "soon", as: "span" });
  } else {
    displayValue = dict({ name: "soon", as: "span" });
  }

  return (
    <Link href="/raffle" className={st["raffle-side-bar-container"]}>
      <div className={st["left-side"]}>
        <div className={st["icon-container"]}>
          <Image
            src={MiniTicketIcon}
            width={25}
            height={"auto"}
            alt="mini-ticket-icon"
          />
        </div>
        <div className={st["heading-container"]}>
          <span className={st["title"]}>{convertRaffleValue(20000)}</span>
          {dict({
            name: "weekly_raffle",
            as: "span",
            className: st["sub-title"],
          })}
        </div>
      </div>
      <div className={st["right-side"]}>
        <div className={st["days-left-container"]}>
          <div className={st["days-left-container-inner"]}>
            <span>{displayValue}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default memo(RaffleSideBar);

function useTimeLeft(targetDate) {
  const calculateTimeLeft = () => {
    if (!targetDate) return { timeScale: "soon", value: null };

    const now = new Date().getTime();
    const target = new Date(targetDate).getTime();
    let difference = target - now;

    // -----------------------------------------
    // TESTING CODE (comment/uncomment as needed)
    // -----------------------------------------

    // Simulate a scenario with multiple days left:
    //  difference = 1 * 24 * 60 * 60 * 1000; // 3 days in ms

    // // Simulate a scenario with hours left:
    // difference = 23 * 60 * 60 * 1000; // 5 hours in ms

    // // Simulate a scenario with minutes left:
    // difference = 30 * 60 * 1000; // 30 minutes in ms

    // // Simulate a scenario where time is already up:
    // difference = -1000; // negative means "soon"

    // -----------------------------------------

    if (difference <= 0) {
      return { timeScale: "soon", value: null };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference %= 1000 * 60 * 60 * 24;
    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference %= 1000 * 60 * 60;
    const minutes = Math.floor(difference / (1000 * 60));

    if (days > 0) {
      return { timeScale: "day", value: days };
    } else if (hours > 0) {
      return { timeScale: "hour", value: hours };
    } else if (minutes > 0) {
      return { timeScale: "soon", value: null };
    } else {
      return { timeScale: "soon", value: null };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // If you need periodic updates (e.g., every hour or minute), you can do so here.
  // For now, this just calculates once. If needed:
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 3600000); // update every hour
    return () => clearInterval(interval);
  }, []);

  return timeLeft;
}
