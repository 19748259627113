import GameDescription from "@Games/GameDescription";
import AlertIcon from "@assets/icons/general/AlertIcon";
import ErrorIcon from "@assets/icons/general/ErrorIcon";
import Logo from "@assets/icons/general/Logo";
import PlayIcon from "@assets/icons/general/PlayIcon";
import FullFrameIcon from "@assets/icons/iframe/FullFrameIcon";
import KeyholeIcon from "@assets/icons/iframe/KeyholeIcon";
import TheatherModeIcon from "@assets/icons/iframe/TheatherModeIcon";
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import usePopup from "@hooks/usePopup";
import useTheaterMode from "@hooks/useTheaterMode";
import { useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import Button from "@items/Button";
import Toggle from "@items/Toggle";
import ToolTip from "@items/ToolTip";
import { NODE_API } from "@lib/api/api";
import StorageService from "@lib/services/Storage.Service";
import {
  classnames,
  handleCatchPopup,
  isMobileUserAgent,
  mergeClasses,
  toggleFullScreen,
  useLocale,
} from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import st from "@styles/pages/Slots.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
import Script from "next/script";
import {
  memo,
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useSWR, { unstable_serialize } from "swr";
import SlotsList from "./SlotsList";
import { setCookie } from "nookies";
import { useUpdateUserPreferences } from "@hooks/useUpdateUserPreferences";
import { WorldLockIcon } from "@assets/icons/general/WorldLockIcon";

const ProviderGamesDisplay = ({ gameDetails, gameContent, type }) => {
  const L = useLanguage(["common", "Slots"]);
  const [isScriptLoaded, setIsScriptLoaded] = useState(
    typeof window !== "undefined" && "sg" in window
  );
  const [overlay, setOverlay] = useState(false);
  const router = useRouter();
  const { userChecked, hasUserData, userData } = useUserSession();
  const { activeBalance } = useWallet();
  const lang = useLocale();
  const [demo, setDemo] = useState(!hasUserData);
  const [mobileFullScreen, setMobileFullScreen] = useState(undefined);
  const { mutate: updatePreferences } = useUpdateUserPreferences();

  const scrollToTop = () => {
    document?.querySelector(".body-content-container")?.scrollTo(0, 0);
  };

  useEffect(() => {
    const value = StorageService.getLocalStorageValue(
      "slots-mobile-fullscreen"
    );
    if (value !== "undefined") {
      setMobileFullScreen(value);
    } else {
      setMobileFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (mobileFullScreen !== undefined) {
      StorageService.setLocalStorageValue(
        "slots-mobile-fullscreen",
        mobileFullScreen
      );
    }
  }, [mobileFullScreen]);

  const setMessage = usePopup();
  const gameInstanceRef = useRef(null);

  const {
    setInPlay,
    userBalanceType,
    setUserBalanceType,
    walletSetting,
    balanceMutate,
  } = useWallet();

  const isViewMobile = useMobile({
    breakPointOne: 998,
    breakPointTwo: 768,
    breakPointThree: 680,
  });
  const [aspectRatio] = useState([55, 31]);
  const [errorDisplay, setErrorDisplay] = useState<string | null>(null);
  const game_url = router?.query?.game || router?.query?.slug;
  const mobile_real_demo_setting = router?.query?.play;

  const game = useSWR(
    game_url ? ["v1/slots/details", game_url] : null,
    ([url, game_url]) =>
      NODE_API.post(url, { game_url, type })
        .then((res) => {
          if (res.status !== 200) {
            return null;
          }

          return res.data;
        })
        .catch((e) => {
          handleCatchPopup(setMessage)(e);
          return null;
        }),
    {
      fallback: {
        [unstable_serialize(["v1/slots/details", game_url])]: gameDetails,
      },
      revalidateOnFocus: false,
      revalidateOnMount: !!gameDetails,
    }
  );

  const metaGameInfo = game.data || gameDetails;
  const promoAllowed = game.data?.promo_balance_eligible || false;

  // Live games not allowed demo if guest, If logged in and no balance, allow live game but cant do anything
  const blockLiveGameDemo = game.data?.type === "live";

  useEffect(() => {
    const setAspectRatio = () => {
      const paddingBottom = (aspectRatio[1] / aspectRatio[0]) * 100;
      document.documentElement.style.setProperty(
        "--aspectratio",
        `${paddingBottom}%`
      );
    };

    setAspectRatio(); // Set the aspect ratio initially.

    // Set up the resize listener.
    window.addEventListener("resize", setAspectRatio);

    // Clean up function.
    return () => {
      // Remove the resize listener.
      window.removeEventListener("resize", setAspectRatio);
    };
  }, [aspectRatio]);

  const sanitizeSpecificStyles = (iframe) => {
    if (!iframe) return;

    const styles = iframe?.style;

    // Remove unwanted styles
    if (styles?.left === "-10000px") {
      styles.removeProperty("left");
    }

    if (styles?.position === "absolute") {
      styles.removeProperty("position");
    }
  };

  const mode = useMemo(() => {
    const localDemo = blockLiveGameDemo && userData?.username ? false : demo;

    if (isViewMobile) {
      if (!mobile_real_demo_setting) {
        return null;
      }
      return mobile_real_demo_setting === "fun" ? "demo" : "realplay";
    }

    return localDemo ? "demo" : "realplay";
  }, [
    blockLiveGameDemo,
    userData?.username,
    demo,
    isViewMobile,
    mobile_real_demo_setting,
  ]);

  const slot = useMemo(() => {
    if (type === "original") {
      return null;
    }

    //Prevent launch if promo not allowed and promo balance is selected
    if (!promoAllowed && userBalanceType !== false) {
      return false;
    }

    const promoBalance = promoAllowed ? userBalanceType : false;

    if (isViewMobile) {
      if (!mobile_real_demo_setting) {
        gameInstanceRef.current = null;

        let iframe;
        if (typeof window !== "undefined") {
          iframe = document
            .getElementById("game_wrapper")
            ?.querySelector("iframe");
          if (iframe) {
            iframe.remove();
          }
        }

        return false;
      }
    }

    return [game_url, lang, promoBalance, walletSetting, mode];
  }, [
    game_url,
    isViewMobile,
    lang,
    mobile_real_demo_setting,
    promoAllowed,
    userBalanceType,
    walletSetting,
    mode,
  ]);

  const slotSwr = useSWR(
    slot || null,
    async ([game_url, language, balance_type, currency, mode]) => {
      try {
        const res = await NODE_API.post(`v1/slots/launch`, {
          device: isMobileUserAgent() ? "mobile" : "desktop",
          mode,
          game_url,
          language,
          balance_type,
          currency,
        });

        if (res.status !== 200) {
          throw new Error(res.data?.error || "er_launching_game");
        }
        return res.data;
      } catch (e) {
        if (e?.response?.data?.error) {
          throw new Error(e.response.data.error);
        }

        throw new Error("er_launching_game");
      }
    },
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
    }
  );

  // Clear the cache when slotKey changes
  useEffect(() => {
    if (slot) return;
    slotSwr.mutate(slot, null);
  }, [slot, slotSwr]);
  const launch_url = slotSwr.data?.launch_url;

  // error handlers
  useEffect(() => {
    startTransition(() => {
      if (slotSwr.error?.message) {
        if (slotSwr.error.message === "er_demo_not_available") {
          if (!hasUserData) {
            setOverlay(false);
          }
        }

        if (
          slotSwr.error.message !== "er_live_game_demo_mode" &&
          slotSwr.error.message !== "er_country_blocked_game" &&
          slotSwr.error.message !== "er_currency_not_allowed"
        ) {
          setMessage({
            code: "responses." + slotSwr.error.message,
            type: 0,
          });
        }
        return setErrorDisplay(slotSwr.error.message);
      }

      setErrorDisplay(null);
    });
  }, [hasUserData, slotSwr.data, slotSwr.error, setMessage]);

  useEffect(() => {
    if (!userChecked) return;

    startTransition(() => {
      setDemo(() => !hasUserData);
      if (hasUserData) setOverlay(true);

      if (blockLiveGameDemo && userData?.username) setDemo(false);

      if (!promoAllowed && userBalanceType !== false) {
        setUserBalanceType(false);
        setCookie(null, "userBalanceType", String(false), {
          path: "/",
          maxAge: 60 * 60 * 24 * 365,
          sameSite: "lax",
        });
        updatePreferences({
          user_balance_type: false,
        });
        setMessage({ code: "responses.no_only_main_balance", type: 2 });
      }
    });
  }, [
    blockLiveGameDemo,
    hasUserData,
    promoAllowed,
    setMessage,
    setUserBalanceType,
    updatePreferences,
    userBalanceType,
    userChecked,
    userData?.username,
  ]);

  useEffect(() => {
    if (!isScriptLoaded || !launch_url) return;

    // Remove iframe if game failed, only launch the game if the gameInfo has changed
    if (gameInstanceRef.current !== null) {
      // Remove the existing iframe
      const iframe = document
        .getElementById("game_wrapper")
        .querySelector("iframe");
      if (iframe) {
        iframe.remove();
      }
    }
    const gameLaunchOptions = {
      target_element: "game_wrapper",
      launch_options: {
        game_url: launch_url,
        game_launcher_url: "https://cdn.launcher.a8r.games/index.html",
        strategy: "iframe",
      },
    };

    const success = (gameDispatcher) => {
      gameInstanceRef.current = gameDispatcher;

      // This is to fix the iframe position and size after the game loads when user has browser extensions enabled
      const iframe = document
        ?.getElementById("game_wrapper")
        ?.querySelector("iframe");
      if (iframe) {
        iframe.onload = () => sanitizeSpecificStyles(iframe);
      }
    };

    const error = (error) => {
      logError("Slots Error:", error);
    };

    if ("iframeAttributes" in window.sg) {
      const attributes = window.sg.iframeAttributes?.allow;
      window.sg.iframeAttributes.allow = attributes?.filter?.(
        (item) => item !== "fullscreen"
      );
    }

    window.sg.launch(gameLaunchOptions, success, error);

    // removeFullscreenFromIframe();
  }, [isScriptLoaded, launch_url]);

  useEffect(() => {
    if (type !== "original") {
      setInPlay(true);
    }
    if (balanceMutate) {
      balanceMutate();
    }

    return () => {
      if (type !== "original") {
        setInPlay(false);
      }
    };
  }, [setInPlay, balanceMutate, type]);

  const fullScreen = () => {
    const parentElement = document.getElementById("game_wrapper");
    const iframe = parentElement.querySelector("iframe");
    toggleFullScreen(iframe);
  };

  const refOverlay = useRef(null);

  const {
    theaterMode,
    toggleTheaterMode,
    slotGameContainerStyle,
    aspectRatioStyle,
    removeMaxWidth,
  } = useTheaterMode(type);

  //Turn off theater mode when leaving slots
  useEffect(() => {
    return () => {
      removeMaxWidth();
    };
  }, [removeMaxWidth]);

  const errorContent = useMemo(() => {
    if (errorDisplay) {
      return (
        <div className={st["error-display"]}>
          <div className={st["error-content"]}>
            {errorDisplay === "er_launching_game" && (
              <>
                <ErrorIcon height={50} width={50} />
                <p> {L("error_launching_game", {})}</p>
                <Button
                  text={L("go_back", {})}
                  method={() => {
                    if (
                      window.document.referrer.includes(window.location.origin)
                    ) {
                      router.back();
                    } else {
                      router.push("/");
                    }
                  }}
                  classType={["green"]}
                />
              </>
            )}

            {errorDisplay === "er_country_blocked_game" && (
              <>
                <WorldLockIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("error_blocked_game", {})}</p>
                <Button
                  text={L("go_back", {})}
                  method={() => {
                    if (
                      window.document.referrer.includes(window.location.origin)
                    ) {
                      router.back();
                    } else {
                      router.push("/");
                    }
                  }}
                  classType={["green"]}
                />
              </>
            )}

            {errorDisplay === "er_promotion_blocked_game" && (
              <>
                <AlertIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("er_promotion_blocked_game", {})}</p>
                <Button
                  text={L("go_back", {})}
                  method={() => {
                    if (
                      window.document.referrer.includes(window.location.origin)
                    ) {
                      router.back();
                    } else {
                      router.push("/");
                    }
                  }}
                  classType={["green"]}
                />
              </>
            )}
            {errorDisplay === "er_live_game_demo_mode" && (
              <>
                <KeyholeIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("er_live_game_demo_mode", {})}</p>
                <div className={st["demo-error-button-container"]}>
                  <Button
                    buttonType={"link"}
                    query={{ modal: "auth", tab: "login" }}
                    active={false}
                    text={L("login", {})}
                    classType={["header-btn", "grey-button", "login-button"]}
                  />

                  <Button
                    buttonType={"link"}
                    query={{ modal: "auth", tab: "register" }}
                    active={false}
                    text={L("register", {})}
                    classType={["blue", "header-btn"]}
                  />
                </div>
              </>
            )}
            {errorDisplay === "er_currency_not_allowed" && (
              <>
                <ErrorIcon height={50} width={50} />
                <p> {L("er_currency_not_allowed", {})}</p>
                <Button
                  text={L("go_back", {})}
                  method={() => {
                    if (
                      window.document.referrer.includes(window.location.origin)
                    ) {
                      router.back();
                    } else {
                      router.push("/");
                    }
                  }}
                  classType={["green"]}
                />
              </>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }, [L, errorDisplay, router]);

  const mobileContentDisplay = useMemo(() => {
    if (isViewMobile && !errorContent && type !== "original") {
      return (
        <div className={st["mobile-option-display"]}>
          {blockLiveGameDemo && !hasUserData ? (
            <div className={st["error-display"]}>
              <div className={st["error-content"]}>
                <KeyholeIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("er_live_game_demo_mode", {})}</p>
                <div className={st["demo-error-button-container"]}>
                  <Button
                    buttonType={"link"}
                    query={{ modal: "auth", tab: "login" }}
                    active={false}
                    text={L("login", {})}
                    classType={["header-btn", "grey-button", "login-button"]}
                  />

                  <Button
                    buttonType={"link"}
                    query={{ modal: "auth", tab: "register" }}
                    active={false}
                    text={L("register", {})}
                    classType={["blue", "header-btn"]}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={st["mobile-toggles-content"]}>
                {overlay && (
                  <div
                    {...classnames(
                      st,
                      "mobile-toggle-container",
                      !hasUserData && "mobile-toggle-container__turn-off"
                    )}
                  >
                    <Toggle
                      active={demo}
                      method={() => {
                        setDemo((item) => !item);
                        setOverlay(true);
                      }}
                      classType={["gradient-blue"]}
                    />

                    <span>{L("play_in_demo_mode", {})}</span>
                  </div>
                )}

                <div className={st["mobile-toggle-container"]}>
                  <Toggle
                    active={mobileFullScreen}
                    method={() => {
                      setMobileFullScreen((item) => !item);
                    }}
                    classType={["gradient-blue"]}
                  />

                  <span>{L("full_screen", {})}</span>
                </div>
              </div>

              <div className={st["mobile-play-btn-container"]}>
                {!overlay && (
                  <div className={st["mobile-register-login-container"]}>
                    <Button
                      buttonType={"link"}
                      query={{ modal: "auth", tab: "login" }}
                      active={false}
                      text={L("login", {})}
                      style={{ backgroundColor: "#3d457c" }}
                      classType={["mid-height", "full-width"]}
                    />
                    <Button
                      buttonType={"link"}
                      query={{ modal: "auth", tab: "register" }}
                      active={false}
                      text={L("register", {})}
                      classType={["blue", "mid-height", "full-width"]}
                    />
                  </div>
                )}

                <Button
                  buttonType={"link"}
                  text={
                    demo ? L("play_game_in_gemo_mode", {}) : L("play_game", {})
                  }
                  classType={["green", "icon-left", "mid-height"]}
                  isShallow={true}
                  icon={<PlayIcon width={15} height={13} />}
                  url={{
                    pathname: router.asPath,
                    query: { play: demo ? "fun" : "real" },
                  }}
                />
              </div>
            </>
          )}
        </div>
      );
    } else if (isViewMobile && errorContent) {
      return errorContent;
    } else {
      return null;
    }
  }, [
    errorContent,
    demo,
    mobileFullScreen,
    router.asPath,
    isViewMobile,
    overlay,
    L,
    hasUserData,
    blockLiveGameDemo,
  ]);

  return (
    <>
      {type !== "original" && (
        <Script
          id="provider-slots-script"
          src={process.env.NEXT_PUBLIC_SS_SCRIPT_URL}
          onLoad={() => {
            setIsScriptLoaded(true);
          }}
        />
      )}

      {type !== "original" && (
        <div
          className={st["container"]}
          style={{
            borderRadius: `${theaterMode && type !== "original" ? 0 : 6}px`,
          }}
        >
          <div {...classnames(st, "game-content")}>
            <div
              ref={refOverlay}
              {...mergeClasses(
                st["overlay"],
                overlay || errorDisplay ? st["close"] : null
              )}
            >
              <div className={st["button-content"]}>
                {hasUserData && userChecked ? (
                  <>
                    {Number(activeBalance) > 0 ? null : (
                      <>
                        <Button
                          buttonType={"link"}
                          url={{
                            pathname: router.asPath,
                            query: { modal: "wallet", tab: "deposit" },
                          }}
                          active={false}
                          text={L("deposit", {})}
                          classType={["green", "deposit"]}
                        />
                        {!metaGameInfo?.live ? (
                          <Button
                            text={L("play_demo", {})}
                            method={() => {
                              setOverlay(true);
                              setDemo(true);
                            }}
                          />
                        ) : (
                          <Button
                            text={L("play", {})}
                            method={() => {
                              setOverlay(true);
                              setDemo(false);
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      buttonType={"link"}
                      query={{ modal: "auth", tab: "register" }}
                      active={false}
                      text={L("register", {})}
                      classType={["green"]}
                    />

                    {/*Live games dont have demo for guests */}
                    {/* NOT LIVE YET******* */}
                    {!metaGameInfo?.live && (
                      <Button
                        text={L("play_demo", {})}
                        method={() => {
                          setOverlay(true);
                          setDemo(true);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {/* <div className={st["game-display-container"]}> */}
            <div className={st["aspect-ratio"]} style={aspectRatioStyle}>
              {!isViewMobile && !!errorContent && errorContent}

              <div
                id="game_wrapper"
                {...classnames(
                  st,
                  "slot-game-container",
                  isViewMobile && "mobile-view",
                  (!errorContent &&
                    isViewMobile &&
                    mobile_real_demo_setting === "fun") ||
                    (!errorContent &&
                      isViewMobile &&
                      mobile_real_demo_setting === "real")
                    ? "active-mobile-view"
                    : null,
                  isViewMobile && mobileFullScreen && "full-screen"
                )}
                style={!isViewMobile ? slotGameContainerStyle : null}
              />
            </div>
            <div
              className={`${st["slot-game-bar"]}`}
              style={{
                borderBottomRightRadius: `${theaterMode && type !== "original" ? 0 : 6}px`,
                borderBottomLeftRadius: `${theaterMode && type !== "original" ? 0 : 6}px`,
              }}
            >
              <div className={`${st["slot-game-bar-left-container"]}`}>
                <Logo />
                <div className={st["divider-line"]}></div>
                <div className={`${st["game-info"]}`}>
                  <h2>{metaGameInfo?.title}</h2>
                  <div onClick={scrollToTop}>
                    <Link
                      shallow
                      className={st["game-link-content"]}
                      prefetch={false}
                      href={
                        metaGameInfo?.type === "slots"
                          ? {
                              pathname: `/casino/slots`,
                              query: { provider: metaGameInfo.producer },
                            }
                          : {
                              pathname: `/casino/${metaGameInfo?.type === "live" ? "" : "live"}/${metaGameInfo?.type}`,
                              query: { provider: metaGameInfo.producer },
                            }
                      }
                    >
                      <h3>{metaGameInfo?.producer}</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                      >
                        <path
                          d="M0.46967 8.85639C0.176777 9.14928 0.176777 9.62416 0.46967 9.91705C0.762563 10.2099 1.23744 10.2099 1.53033 9.91705L0.46967 8.85639ZM9.52817 1.60854C9.52817 1.19433 9.19239 0.858544 8.77817 0.858544L2.02817 0.858544C1.61396 0.858544 1.27817 1.19433 1.27817 1.60854C1.27817 2.02276 1.61396 2.35854 2.02817 2.35854H8.02817V8.35854C8.02817 8.77276 8.36396 9.10854 8.77817 9.10854C9.19239 9.10854 9.52817 8.77276 9.52817 8.35854L9.52817 1.60854ZM1.53033 9.91705L9.3085 2.13887L8.24784 1.07821L0.46967 8.85639L1.53033 9.91705Z"
                          fill="#E8E5FF"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className={`${st["game-options"]}`}>
                <div className={`${st["game-options-buttons"]}`}>
                  <ToolTip
                    text={"Theater Mode"}
                    placement={"top"}
                    size={"small"}
                    transparent={true}
                  >
                    <Button
                      classType={[
                        "icon-only",
                        "full-screen",
                        theaterMode && type !== "original" && "theather-active",
                      ]}
                      method={() => {
                        toggleTheaterMode();
                      }}
                      icon={<TheatherModeIcon />}
                    />
                  </ToolTip>
                  <ToolTip
                    text={L("full_screen", {})}
                    placement={"top"}
                    size="small"
                    transparent={true}
                  >
                    <Button
                      classType={["icon-only", "full-screen"]}
                      method={() => {
                        fullScreen();
                      }}
                      icon={<FullFrameIcon />}
                    />
                  </ToolTip>
                </div>

                <div
                  className={` ${st["demo-toggle"]}  
                        ${!hasUserData ? st["guest"] : ""}
                        ${blockLiveGameDemo ? st["guest"] : ""}
                      `}
                >
                  <span
                    className={`${st["real-play-span"]} ${
                      demo && st["demo-mode"]
                    } ${!hasUserData && overlay ? st["real-play-demo"] : ""}`}
                  >
                    {L("fun_play", {})}
                  </span>
                  <Toggle
                    active={!demo}
                    method={() => {
                      setDemo((item) => !item);
                      setOverlay(true);
                    }}
                    classType={["gradient-blue"]}
                  />
                  <span className={`${demo && st["demo-mode-real"]}`}>
                    {L("real_play", {})}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={st["game-details-container"]}>
        <GameDescription
          meta={metaGameInfo}
          gameImage={
            gameDetails?.custom_banner
              ? gameDetails?.custom_banner
              : gameDetails?.icon
          }
          gameDetails={gameContent}
          mobileSlotDisplay={mobileContentDisplay}
        />
      </div>

      {type !== "original" && (
        <div className={st["games-might-like-container"]}>
          <SlotsList
            sortBy={"random"}
            heading="games_you_might_like"
            theme={metaGameInfo?.theme}
            category={metaGameInfo?.category}
            isLive={router.pathname.includes("game-shows")}
            carousel
            grouping={""}
            shouldShowQuickLinks={false}
          />
        </div>
      )}
    </>
  );
};
export default memo(ProviderGamesDisplay);
