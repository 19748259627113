import { NODE_API } from "@lib/api/api";
import usePopup from "@hooks/usePopup";
import { useState, useCallback, ElementType, useMemo } from "react";
import { handleCatchPopup } from "@lib/tools/helpers";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import { isAfter } from "date-fns";
import { useSetRecoilState } from "recoil";
import userRakebackBoosted from "@recoil/rakebackBoosted/atom";
import { CHESTS } from ".";
import ValueDisplay from "@items/ValueDisplay";
import { useUserSession } from "@hooks/useUserSession";
import useLanguage from "@hooks/useLanguage";
import RaffleItemDropdown from "@Games/Raffle/components/raffle-drop-down";
import { Button } from "@components/new-ui/button";
import Link from "next/link";
import useWallet from "@hooks/useWallet";
import RakebackBoost from "@components/rakeback-boost";
import { timeLeft } from "@lib/timer";
import { useRouter } from "next/router";
import { RewardsKeys } from "./rewards-dropdown-keys";

interface RewardContentRowProps {
  countdown: string;
  refetch: () => void;
  hasToken: boolean;
  chest: {
    image: ElementType;
    name: string;
    api: "rakeback" | "calendar" | "daily" | "weekly" | "monthly";
  };
  hasSufficientFunds: boolean;
  hasExpired: boolean;
  amount?: number;
}

function RewardContentRow({
  countdown,
  refetch,
  hasToken,
  chest,
  hasSufficientFunds,
  hasExpired,
  amount,
}: RewardContentRowProps) {
  const { balanceMutate } = useWallet();
  const setMessage = usePopup();
  const L = useLanguage(["meta", "Rewards", "header"]);
  const { mutate, addTokenHeader } = useUserSession();
  const [isLoading, setIsLoading] = useState(false);
  const setRakebackBoosted = useSetRecoilState(userRakebackBoosted);
  const { api, image: ImageComponent, name } = chest;

  const collect = useCallback(
    async (type) => {
      if (!hasToken) return;

      setIsLoading(true);

      try {
        const res = await NODE_API.post(
          type === "calendar"
            ? "v1/rewards/calendar-claim"
            : "v1/rewards/chest-claim",
          type === "calendar"
            ? { date: new Date().toISOString().split("T")[0] }
            : { type },
          addTokenHeader()
        );

        const data = res.data;
        setRakebackBoosted(true);
        setMessage({
          type: 1,
          raw: true,
          code: data.currency_calendar_reward
            ? "responses.su_reward_chest"
            : "responses.su_reward_chest_no_calendar",
          replacements: {
            TOKEN_AMOUNT: data.currency_amount_redeemed,
            ...(data.currency_calendar_reward && {
              CALENDAR_REWARD: data.currency_calendar_reward,
            }),
            ...(data.currency_instant_reward && {
              INSTANT_REWARD: data.currency_instant_reward,
            }),
            ...(data.currency && {
              CURRENCY: data.currency,
            }),
          },
        });
      } catch (error) {
        handleCatchPopup(setMessage)(error);
      } finally {
        setIsLoading(false);

        mutate();
        refetch();
        balanceMutate();
      }
    },
    [
      hasToken,
      addTokenHeader,
      mutate,
      refetch,
      setRakebackBoosted,
      setMessage,
      balanceMutate,
    ]
  );

  const formattedCountdown = useMemo(() => {
    if (!countdown) return "";

    // Extract numbers from countdown string (e.g. "6d, 7h, 1m")
    const days = countdown.match(/(\d+)d/)?.[1];
    const hours = countdown.match(/(\d+)h/)?.[1];
    const minutes = countdown.match(/(\d+)m/)?.[1];

    if (days && parseInt(days) > 0) {
      return `${days} ${parseInt(days) === 1 ? L("day", {}) : L("days", {})}`;
    }
    if (hours && parseInt(hours) > 0) {
      return `${hours} ${parseInt(hours) === 1 ? L("hour", {}) : L("hours", {})}`;
    }
    if (minutes && parseInt(minutes) > 0) {
      return `${minutes} ${parseInt(minutes) === 1 ? "min" : "mins"}`;
    }

    return "1 min";
  }, [L, countdown]);

  const shouldCount =
    hasSufficientFunds || (api !== "rakeback" && api !== "calendar")
      ? formattedCountdown
      : L("claim", {});

  const canClaim =
    api === "rakeback" || api === "calendar"
      ? hasSufficientFunds && hasExpired
      : hasExpired;

  const btnClaimText = canClaim
    ? L("claim", {})
    : shouldCount || L("claim", {});

  const text = isLoading ? <LoadingSpinner type="button" /> : btnClaimText;

  return (
    <div className="flex items-center gap-4 justify-between rounded-md border border-[#7C83B1] border-opacity-15 border-solid bg-gradient-to-b from-[rgba(124,131,177,0.15)] to-[rgba(94,103,158,0.15)] px-4 py-[10px] pr-3">
      <div className="flex items-center gap-3">
        <div className="w-10">
          <ImageComponent />
        </div>
        <div className="flex flex-col gap-[5px]">
          <span className="text-[13px] break-normal">{L(name, {})}</span>
          {hasToken && (api === "rakeback" || api === "calendar") && (
            <ValueDisplay
              amount={amount}
              size={"small"}
              classType={["custom-value-display"]}
              icon={false}
              symbolPosition
            />
          )}
        </div>
      </div>

      {hasToken ? (
        <Button
          onClick={() => collect(api)}
          disabled={!canClaim}
          size="sm"
          variant={canClaim ? "blue" : "normal"}
          className="px-[14px]"
        >
          {text}
        </Button>
      ) : (
        <Link href={{ pathname: "", query: { modal: "auth", tab: "login" } }}>
          <Button variant="blue" size="sm" className="px-[14px]">
            {L("log_in_claim", {})}
          </Button>
        </Link>
      )}
    </div>
  );
}

export default function RewardsDropdownContent({
  countdowns,
  rewardsData,
  getRewardsData,
  now,
  closeDropdown,
  isRakeBoostActive,
  boostProgress,
}) {
  const { hasToken } = useUserSession();
  const L = useLanguage(["header"]);
  const router = useRouter();

  const handleClick = () => {
    closeDropdown();
    setTimeout(() => {
      router.push(`/rewards`);
    }, 100);
  };

  return (
    <div
      data-rewards-dropdown
      className="relative flex flex-col gap-2 shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2 max-w-[330px] w-[330px]"
    >
      <div className="flex flex-col gap-2">
        <RaffleItemDropdown closeDropdown={closeDropdown} />

        {isRakeBoostActive ? (
          <RakebackBoost
            mobile={true}
            rakebackRate={rewardsData?.rakeback_boost?.rate}
            boostType={rewardsData?.rakeback_boost?.type}
            progress={boostProgress}
            leftTime={timeLeft(
              now,
              rewardsData?.rakeback_boost?.expires_at.replace("Z", "")
            )}
          />
        ) : null}

        {CHESTS.map((chest) => {
          const data =
            chest.api === "rakeback"
              ? rewardsData?.rakeback
              : chest.api === "calendar"
                ? rewardsData?.calendar
                : rewardsData?.chest_rewards?.[chest.api];

          return (
            <RewardContentRow
              key={chest.api}
              refetch={getRewardsData}
              chest={chest}
              countdown={countdowns[chest.api] || ""}
              hasToken={hasToken}
              hasSufficientFunds={!!data?.sufficient_funds}
              hasExpired={isAfter(now, data?.claimable.replace("Z", ""))}
              amount={data?.amount}
            />
          );
        })}

        {rewardsData?.keys?.count > 0 && (
          <RewardsKeys keys={rewardsData?.keys} closeDropdown={closeDropdown} />
        )}
      </div>

      <div className="h-[1px] w-full bg-white/10 my-[5px]"></div>

      <Button
        variant="normal"
        className="w-full h-[42px] text-[13px] min-h-[42px] bg-[#3D446C] hover:bg-[#4F588C] border-[1px] hover:text-white text-white border-[#7C83B1] border-opacity-15 hover:border-opacity-50 transition-all duration-300 ease-in-out"
        onClick={handleClick}
      >
        {L("all_rewards", {})}
      </Button>
    </div>
  );
}
