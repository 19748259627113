import { SVGProps } from "react";

type ArrowProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};

const Arrow = (props: ArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill={props.fill}
    {...props}
  >
    <path
      d="M5.25 11.5898C5.25 12.0041 5.58579 12.3398 6 12.3398C6.41421 12.3398 6.75 12.0041 6.75 11.5898H5.25ZM6.53033 1.05951C6.23744 0.76662 5.76256 0.76662 5.46967 1.05951L0.696699 5.83248C0.403806 6.12538 0.403806 6.60025 0.696699 6.89314C0.989593 7.18604 1.46447 7.18604 1.75736 6.89314L6 2.6505L10.2426 6.89314C10.5355 7.18604 11.0104 7.18604 11.3033 6.89314C11.5962 6.60025 11.5962 6.12538 11.3033 5.83248L6.53033 1.05951ZM6.75 11.5898L6.75 1.58984H5.25L5.25 11.5898H6.75Z"
      fill={props.fill}
    />
  </svg>
);

export default Arrow;
