import { GoogleLogoModal } from "@assets/icons/general/GoogleLogo";
import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import { NewLogo, NewLogoModal } from "@assets/icons/general/NewLogo";
import SteamIcon from "@assets/icons/general/SteamIcon";
import LoginModalLogo from "@assets/Images/login-modal.png";
import { LoadingExternalLogin } from "@components/Loading/LoadingExternalLogin";
import useAuth from "@hooks/useAuth";
import useDetectOutside from "@hooks/useDetectOutside";
import { useExternalAuth } from "@hooks/useExternalAuth";
import useLanguage from "@hooks/useLanguage";
import usePopup from "@hooks/usePopup";
import { useUserSession } from "@hooks/useUserSession";
import Button from "@items/Button";
import ModalSpinner from "@items/ModalSpinner";
import { NODE_API } from "@lib/api/api";
import StorageService from "@lib/services/Storage.Service";
import { handleCatchPopup, removeUrlQueries } from "@lib/tools/helpers";
import store from "@store";
import { pageLoadingAtom } from "@store/pageLoading";
import st from "@styles/components/Auth.module.scss";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Login, { LoginForm, useLogin } from "./login";
import Recover from "./recover";
import Register from "./register";
import TwoFactorInput from "./TwoFactorInput";

const useGoogleLogin = () => {
  const { signInToken } = useUserSession();
  const query = useSearchParams();
  const setMessage = usePopup();
  const loginType = query.get("login-type");
  const jwt = query.get("token");
  const error = query.get("error");

  useEffect(() => {
    if (loginType !== "google") return;
    if (!error) return;

    Promise.reject(new Error(error))
      .catch(handleCatchPopup(setMessage))
      .finally(() => {
        setTimeout(() => {
          removeUrlQueries();
        }, 3000);
      });
  }, [error, setMessage, loginType]);

  useEffect(() => {
    if (loginType !== "google") return;
    if (!jwt) return;

    signInToken({ jwt })
      .then(() => setMessage({ code: "responses.successful", type: 1 }))
      .catch(handleCatchPopup(setMessage, true))
      .finally(() => {
        removeUrlQueries();
      });
  }, [jwt, loginType, signInToken, setMessage]);
};

//Auth container,
export default function Auth() {
  // google
  useGoogleLogin();
  useAuth(true);

  const query = useSearchParams();
  const method = query.get("method");
  const modal = query.get("modal");
  const tab = query.get("tab");
  const openid = query.get("openid.ns");
  const referralCode = query.get("r");

  if (referralCode) {
    StorageService.setReferralCode(referralCode);
  }

  const modalLogo = LoginModalLogo;

  const { ref } = useDetectOutside(
    true,
    () => {
      removeUrlQueries();
    },
    undefined
  );
  const { signInToken: login } = useUserSession();
  const L = useLanguage(["common", "auth", "forms"]) as any;
  const setMessage = usePopup();

  const [homeBtnMethod, setHomeBtnMethod] = useState(false);

  const recover = query.get("recover");
  const {
    stateExternalLogin,
    setStateExternalLogin,
    loginSteam,
    metaMaskLogin,
    steam,
  } = useExternalAuth();

  const form = useForm<LoginForm>({
    defaultValues: {
      username: "",
      password: "",
      twoFactorCode: "",
    },
  });

  const username = form.watch("username");
  const password = form.watch("password");
  const resetForm = form.reset;

  const { error, isLoading, data, submit, isSecurityCheck, has2FA } =
    useLogin();

  const [localLoading, setLocalLoading] = useState(false);
  const loading = isLoading || localLoading;

  // login useEffect
  useEffect(() => {
    if (!data?.tokens) return;

    setLocalLoading(true);
    login(data.tokens)
      .then(() => {
        setMessage({ code: "responses.successful", type: 1 });
        removeUrlQueries();
      })
      .catch(() => {
        setLocalLoading(false);
      });
  }, [data?.tokens, login, setMessage]);

  useEffect(() => {
    if (error && error !== "two-factor-missing") {
      handleCatchPopup(setMessage)({ error });
      resetForm();
    }
  }, [error, setMessage, resetForm]);

  const handleTermsAndServiceClick = () => {
    window.open("/terms", "_blank");
  };

  useEffect(() => {
    if (!openid) return;
    if (stateExternalLogin.isLoading && stateExternalLogin.type) return;
    if (stateExternalLogin.type === "done") return;

    setStateExternalLogin({ isLoading: true, type: "steam" });

    loginSteam(query)
      .then(() => {
        setMessage({ code: "responses.successful", type: 1 });
      })
      .catch(handleCatchPopup(setMessage))
      .finally(() => {
        removeUrlQueries();
      });
  }, [
    loginSteam,
    openid,
    query,
    setMessage,
    setStateExternalLogin,
    stateExternalLogin.isLoading,
    stateExternalLogin.type,
  ]);

  useEffect(() => {
    if (method && !homeBtnMethod) {
      if (method === "steam") {
        steam();
      }
      if (method === "metamask") {
        metaMaskLogin();
      }
      setHomeBtnMethod(true);
    }
  }, [homeBtnMethod, metaMaskLogin, method, steam]);

  const switchComponent = (() => {
    if (recover) return <Recover />;

    switch (tab) {
      case "login":
        return <Login form={form} onSubmit={submit} />;
      case "register":
        return <Register />;
      default:
        removeUrlQueries();
        return null;
    }
  })();

  if (stateExternalLogin.isLoading)
    return <LoadingExternalLogin type={stateExternalLogin.type || "default"} />;

  //Switch between sections and create the menu
  return (
    <div
      ref={ref}
      className={`${st["auth-container"]} ${
        has2FA ? st["active-two-factor"] : ""
      }`}
    >
      <ModalSpinner hide={!loading} />
      <div className={st["left-container"]}>
        <div className={st["logo-container"]}>
          <div className={st["auth-logo-container"]}>
            <NewLogo />
          </div>
          <Image
            src={modalLogo}
            height={750}
            quality={100}
            alt="Rainbet"
            className={st["image-block"]}
          />
        </div>
        <div className={st["left-logo"]}>
          <NewLogoModal />
        </div>
        <div className={st["terms-container"]}>
          {L("terms_conditions")}
          <p
            className={st["terms-conditions-logo"]}
            onClick={handleTermsAndServiceClick}
          >
            {L("register-terms")}
          </p>
        </div>
      </div>

      <div className={st["right-container"]}>
        <div className={st["content-wrapper"]}>
          {modal === "auth" && !has2FA && switchComponent}

          {(tab === "login" || tab === "register") && !recover && !has2FA && (
            <div className={st["auth-bottom-content"]}>
              <div className={st["alt-signin"]}>
                <h3>{L("other_top")}</h3>
                <div className={st["btn-content"]}>
                  <Button
                    method={steam}
                    classType={["meta-steam"]}
                    icon={<SteamIcon />}
                  />
                  <Button
                    method={metaMaskLogin}
                    classType={["meta-steam"]}
                    icon={<MetaMaskIcon />}
                  />
                  <Button
                    method={() => {
                      removeUrlQueries();

                      store.set(pageLoadingAtom, true);

                      window.location.href = `${NODE_API.getUri()}/v1/auth/google`;
                    }}
                    classType={["meta-steam"]}
                    icon={<GoogleLogoModal />}
                  />
                </div>
              </div>

              <div className={st["switch-auth-type"]}>
                {tab === "register"
                  ? L("already_have_account")
                  : L("dont_have_account")}
                <Link
                  href={`?modal=auth&tab=${tab === "register" ? "login" : "register"}`}
                >
                  {tab === "register" ? L("login") : L("register")}
                </Link>
              </div>
            </div>
          )}

          {has2FA && (
            <TwoFactorInput
              hasError={error && error !== "two-factor-missing"}
              securityCheck={isSecurityCheck}
              username={username}
              onChange={(twoFactorCode) => {
                submit({ username, password, twoFactorCode });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
