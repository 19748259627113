import Cookies from "js-cookie";
import crypto from "crypto";

const setWalletSettings = (settings) => {
  localStorage.setItem("walletSettings", JSON.stringify(settings));
};

const getWalletSettings = () => {
  const settings = JSON.parse(localStorage.getItem("walletSettings"));
  return settings;
};

const setReferralCode = (code) => {
  localStorage.setItem("referralCode", JSON.stringify(code));
};

const getReferralCode = () => {
  const code = JSON.parse(localStorage.getItem("referralCode"));
  return code;
};

const removeReferralCode = () => {
  //const code = JSON.parse(localStorage.getItem("referralCode"));
  localStorage.removeItem("referralCode");
};

const setOddsCode = (code) => {
  localStorage.setItem("oddsCode", JSON.stringify(code));
};

const getOddsCode = () => {
  const code = JSON.parse(localStorage.getItem("oddsCode"));
  return code;
};

//Stores values in the local storage
/**
 * Stores values in the local storage
 * @param {string} key - The key to store the value under
 * @param {*} value
 */
const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Retrieves values in the local storage
 * @param {string} key - The key used to retrieve the value
 */
const getLocalStorageValue = (key) => {
  return JSON.parse(localStorage?.getItem(key));
};

const clearLocalStorageValue = (key) => {
  localStorage.removeItem(key);
};

// Helper functions to manage cookies
const setCookieValue = (key, value) => {
  Cookies.set(key, value);
};

const getCookieValue = (key) => {
  return Cookies.get(key);
};

const clearCookieValue = (key) => {
  Cookies.remove(key);
};

const ENCRYPTION_KEY = "8hD29JkL3mN6pqRsT7uVwX4zAbC5eFyZ";
// Must be 256 bits (32 characters)
const IV_LENGTH = 16; // For AES, this is always 16

function encrypt(text) {
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(ENCRYPTION_KEY),
    iv
  );
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString("hex") + ":" + encrypted.toString("hex");
}

function decrypt(text) {
  const textParts = text.split(":");
  // Ensure there are two parts: the IV and the encrypted data.
  if (textParts.length !== 2) {
    throw new Error(
      "Invalid input text. The text must include an IV and encrypted data separated by a colon."
    );
  }
  const iv = Buffer.from(textParts.shift(), "hex");
  // Ensure the IV is the correct length.
  if (iv.length !== IV_LENGTH) {
    throw new Error(
      `Invalid IV length: expected ${IV_LENGTH}, got ${iv.length}`
    );
  }
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(ENCRYPTION_KEY),
    iv
  );
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
}

const getPromoCodes = () => {
  const promoCodesLocalStorage = getLocalStorageValue("UELW$@)!LK");
  const promoCodesCookie = getCookieValue("UELW$@)!LK");
  const promoCodes = promoCodesLocalStorage
    ? JSON.parse(decrypt(promoCodesLocalStorage))
    : promoCodesCookie
      ? JSON.parse(decrypt(promoCodesCookie))
      : [];
  return promoCodes;
};

const addPromoCode = (code) => {
  const promoCodes = getPromoCodes();
  promoCodes.push(code);
  const promoCodesStr = JSON.stringify(promoCodes);
  const encryptedPromoCodes = encrypt(promoCodesStr);
  setLocalStorageValue("UELW$@)!LK", encryptedPromoCodes);
  setCookieValue("UELW$@)!LK", encryptedPromoCodes);
};

const setAffiliateTag = (tag) => {
  Cookies.set("stag", tag, {
    expires: 30,
    path: "/",
    sameSite: "Lax",
    secure: true,
  });
};

const getAffiliateTag = () => {
  return Cookies.get("stag");
};

const removeAffiliateTag = () => {
  Cookies.remove("stag", {
    path: "/",
    domain: ".rainbet.com",
    sameSite: "Lax",
    secure: true,
  });
};

const StorageService = {
  setWalletSettings,
  getWalletSettings,
  setReferralCode,
  getReferralCode,
  removeReferralCode,
  setOddsCode,
  getOddsCode,
  setLocalStorageValue,
  getLocalStorageValue,
  clearLocalStorageValue,
  setCookieValue,
  getCookieValue,
  clearCookieValue,
  addPromoCode,
  getPromoCodes,
  setAffiliateTag,
  getAffiliateTag,
  removeAffiliateTag,
};
export default StorageService;
