import HiddenNameIcon from "@assets/icons/general/Hidden";
import useLanguage, { Dict } from "@hooks/useLanguage";
import { GameHistory, useNewGameHistory } from "@hooks/useSocket";
import RewardIcons, { RewardCode } from "@items/RewardIcons";
import ValueDisplay from "@items/ValueDisplay";
import { cn } from "@lib";
import { useQueue } from "@lib/Queue";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useMemo } from "react";
import RollingList from "./RollingList";

const shouldAdd = ({ multiplier }: GameHistory) => {
  if (isNaN(+multiplier)) return false;

  return +multiplier > 1;
};

const statusPing = (
  <span className="relative flex h-2 w-2">
    <span className="absolute inline-flex h-full w-full rounded-full bg-green opacity-75 animate-wins-feed-ping" />
    <span className="relative inline-flex rounded-full h-2 w-2 bg-light-green" />
  </span>
);

const FallbackGameImage = ({ name }: { name: string }) => (
  <div className="relative aspect-3/4 rounded-md bg-dark-one opacity-80 group-hover:-translate-y-1">
    <span className="absolute inset-0 flex items-center justify-center text-center leading-normal">
      {name}
    </span>
  </div>
);

type ResultProps = Readonly<GameHistory>;
function Result({ id, user, currency, currencyPayout, game }: ResultProps) {
  const { username, rankLevel } = user;
  const L = useLanguage("common");
  const icon = game?.["custom_banner"] || game?.["customBanner"] || game?.icon;

  return (
    <Link
      shallow
      href={{
        query: {
          modal: "bet",
          tab: "result",
          betId: id,
        },
      }}
      className="[&_*]:transition-all [&_*]:duration-300 [&_*]:ease-out group"
    >
      {icon ? (
        <Image
          src={icon}
          alt="game"
          width={200}
          height={(4 * 200) / 3} // 3/4 aspect ratio
          className="aspect-3/4 rounded-md group-hover:-translate-y-1 object-cover object-center"
        />
      ) : (
        <FallbackGameImage name={game.name} />
      )}

      <div className="flex flex-col mt-2.5 justify-center items-center group-hover:-translate-y-1">
        <div className="flex items-center gap-1">
          {rankLevel?.name && username ? (
            <RewardIcons
              code={rankLevel?.name?.toUpperCase() as RewardCode}
              size={"large"}
            />
          ) : null}

          <div className={cn("max-w-[85px] flex gap-1.5 justify-center")}>
            <p
              className={cn(
                "text-xs text-input-label-color-light truncate",
                !username && "text-font-color-two"
              )}
            >
              {username || L("hidden")}
            </p>
            {!username && <HiddenNameIcon />}
          </div>
        </div>

        <ValueDisplay
          amount={currencyPayout}
          size="small"
          customClass="[&_span]:text-xs [&_span]:text-light-green/90"
          currencyCode={currency}
        />
      </div>
    </Link>
  );
}

export default function WinsFeed({ initialData = null }) {
  const initial = useMemo(() => initialData, [initialData]);
  const { queue, output } = useQueue<GameHistory>({
    initial,
    limit: 12,
  });

  const list = useMemo(() => output || [], [output]);

  const fn = useCallback(
    (item: GameHistory) => {
      if (shouldAdd(item)) {
        queue.add(item);
      }
    },
    [queue]
  );

  useNewGameHistory(fn);

  if (list?.length === 0) return null;

  return (
    <div
      // 1200px - 32px = 1168px because of the padding
      className="flex flex-col gap-3 mb-10 min-w-[1168px]"
    >
      <div className="flex items-center gap-2">
        {statusPing}

        <Dict
          section="ResultFeed"
          name="live_feed"
          className="text-md font-medium"
          as="h3"
        />
      </div>

      <button
        onClick={() => {
          const getRandomOutput = () => {
            const randomIndex = Math.floor(Math.random() * output.length);
            return output[randomIndex];
          };
          const getNewResult = () =>
            JSON.parse(
              JSON.stringify({ ...getRandomOutput(), id: crypto.randomUUID() })
            );

          const items = Array.from({ length: 10 }, () => getNewResult());

          queue.add(...items);
        }}
      >
        temporary add items ({queue.length} items at {queue.interval}ms)
      </button>

      <RollingList orientation="horizontal" gap={10}>
        {list.map((result, i) => (
          <Result id={result.id} key={`${result.id}-${i}`} {...result} />
        ))}
      </RollingList>
    </div>
  );
}
