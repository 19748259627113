export default function MailIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_20752_34953)">
        <path
          d="M17.8405 3.15425L12.3814 8.57812L17.8405 14.002C17.9392 13.7957 17.999 13.5677 17.999 13.3242V3.83203C17.999 3.5885 17.9392 3.36052 17.8405 3.15425ZM16.417 2.25H1.58105C1.33753 2.25 1.10954 2.30987 0.903277 2.40855L7.88049 9.35061C8.49741 9.96753 9.50063 9.96753 10.1176 9.35061L17.0948 2.40855C16.8885 2.30987 16.6605 2.25 16.417 2.25ZM0.157578 3.15425C0.0588945 3.36052 -0.000976562 3.5885 -0.000976562 3.83203V13.3242C-0.000976562 13.5677 0.0588945 13.7958 0.157578 14.002L5.61661 8.57812L0.157578 3.15425Z"
          fill="#2099FF"
        />
        <path
          d="M17.8405 3.15425L12.3814 8.57812L17.8405 14.002C17.9392 13.7957 17.999 13.5677 17.999 13.3242V3.83203C17.999 3.5885 17.9392 3.36052 17.8405 3.15425ZM16.417 2.25H1.58105C1.33753 2.25 1.10954 2.30987 0.903277 2.40855L7.88049 9.35061C8.49741 9.96753 9.50063 9.96753 10.1176 9.35061L17.0948 2.40855C16.8885 2.30987 16.6605 2.25 16.417 2.25ZM0.157578 3.15425C0.0588945 3.36052 -0.000976562 3.5885 -0.000976562 3.83203V13.3242C-0.000976562 13.5677 0.0588945 13.7958 0.157578 14.002L5.61661 8.57812L0.157578 3.15425Z"
          fill="url(#paint0_linear_20752_34953)"
        />
        <path
          d="M11.6348 9.32031L10.8623 10.0928C9.83439 11.1207 8.16176 11.1207 7.13382 10.0928L6.36137 9.32031L0.902344 14.7442C1.10861 14.8429 1.33659 14.9027 1.58012 14.9027H16.4161C16.6596 14.9027 16.8876 14.8429 17.0938 14.7442L11.6348 9.32031Z"
          fill="#2099FF"
        />
        <path
          d="M11.6348 9.32031L10.8623 10.0928C9.83439 11.1207 8.16176 11.1207 7.13382 10.0928L6.36137 9.32031L0.902344 14.7442C1.10861 14.8429 1.33659 14.9027 1.58012 14.9027H16.4161C16.6596 14.9027 16.8876 14.8429 17.0938 14.7442L11.6348 9.32031Z"
          fill="url(#paint1_linear_20752_34953)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_20752_34953"
          x1="8.99902"
          y1="2.25"
          x2="8.99902"
          y2="14.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1F2" />
          <stop offset="1" stopColor="#3A8DDA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20752_34953"
          x1="8.99809"
          y1="9.32031"
          x2="8.99809"
          y2="14.9027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1F2" />
          <stop offset="1" stopColor="#3A8DDA" />
        </linearGradient>
        <clipPath id="clip0_20752_34953">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
