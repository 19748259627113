import { NODE_API } from "@lib/api/api";
import { logError } from "@lib/tools/logger";
import useSWR, { unstable_serialize } from "swr";

export const getRecentGames = ([url, token], headers = {}) => {
  if (!token) return Promise.resolve([]);

  return NODE_API.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  })
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      logError(err);
      return [];
    });
};

export const useRecentGames = (token, initialData = []) => {
  return useSWR(
    token ? ["v1/user/recent-games", token] : null,
    getRecentGames,
    {
      keepPreviousData: true,
      fallback: {
        [unstable_serialize(["v1/user/recent-games", token])]: initialData,
      },
      revalidateOnMount: !initialData?.length,
      revalidateOnFocus: false,
    }
  );
};
