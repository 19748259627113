import { RewardsKeyIcon } from "@assets/svgs/RewardsIcons";
import { Button } from "@components/new-ui/button";
import { useDict } from "@hooks/useLanguage";
import { RewardsData } from "@/types/rewards";
import { useRouter } from "next/router";

interface RewardsKeysProps {
  keys: RewardsData["keys"];
  closeDropdown: () => void;
}

export function RewardsKeys({ keys, closeDropdown }: RewardsKeysProps) {
  const Dict = useDict(["Rewards"]);
  const router = useRouter();

  const handleClick = () => {
    closeDropdown();
    setTimeout(() => {
      router.push(`/rewards/${keys.best_type}`);
    }, 100);
  };

  return (
    <div className="flex items-center gap-4 justify-between rounded-md border border-[#9CEC60] border-opacity-50 border-solid bg-gradient-to-t from-[#9CEC60]/15 to-[#7C83B1]/10 bg-opacity-20 px-4 py-[10px] pr-3">
      <div className="flex items-center gap-3">
        <div className="w-10">
          <RewardsKeyIcon />
        </div>
        <div className="flex flex-col gap-[5px]">
          <span className="text-[13px] break-normal">
            <Dict
              name="dropdown_keys"
              className="[&>strong]:font-semibold"
              replacements={{
                KEYS: keys.count,
              }}
            />
          </span>
        </div>
      </div>

      <Button
        size="sm"
        variant="green"
        className="px-[14px]"
        onClick={handleClick}
      >
        <Dict name="dropdown_keys_open" />
      </Button>
    </div>
  );
}
