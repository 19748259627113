import BgBanner from "@assets/Images/banner-blackjack.png";
import Character from "@assets/Images/rainman-blackjack.png";
import THEHAT from "@assets/Images/rainman_hat.png";
import Accordion from "@components/Accordion/v2";
import { Banner } from "@components/Banner/Banner";
import { LoggedOutBanner } from "@components/Banner/LoggedOutBanner";
import { PaymentBanner } from "@components/Banner/PaymentBanner";
import { RankBanner } from "@components/Banner/RankBanner";
import { RewardsBannerMonthly } from "@components/Banner/RewardsBannerMonthly";
import { RewardsBannerWeekly } from "@components/Banner/RewardsBannerWeekly";
import { Content } from "@components/BlogPage";
import { CarouselHomeBanners } from "@components/Carousel/CarouselHomeBanners";
import stCarousel from "@components/Carousel/CarouselHomeBanners.module.scss";
import { getRecentGames, useRecentGames } from "@components/recent-games";
import { getSlotList } from "@components/SlotsList";
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import useRewards from "@hooks/useRewards";
import { useUserSession } from "@hooks/useUserSession";
import { cn } from "@lib";
import { NODE_API } from "@lib/api/api";
import { classnames, getClientIp, mergeClasses } from "@lib/tools/helpers";
import { inHouseGames } from "@lib/tools/house-games";
import { logError } from "@lib/tools/logger";
import { fetchPageContent } from "@lib/tools/ssr";
import st from "@styles/Home.module.scss";
import dynamic from "next/dynamic";
import Head from "next/head";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";

const WinsFeed = dynamic(() => import("@components/wins-feed"));
const ProviderList = dynamic(() => import("@components/ProviderList"));
const SlotsList = dynamic(() => import("@components/SlotsList"));
const MetaContent = dynamic(() => import("@items/MetaContent"));

const schema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Rainbet",
  url: "https://rainbet.com/",
};

const BannerImg = ({
  aspectRatio = "",
  chatMobileTwoPromo,
  promoIsFalse,
  mobileImage,
  ...props
}) => {
  return (
    <div
      data-img
      {...mergeClasses(
        chatMobileTwoPromo,
        promoIsFalse,
        st["custom-height-image"],
        mobileImage && st["mobile-image"]
      )}
      style={{
        width: "100%",
        ...(aspectRatio && { "--aspect-ratio": aspectRatio }),
        position: "relative",
        zIndex: 1,
      }}
    >
      <Image
        {...props}
        alt="banner image"
        src={BgBanner}
        priority
        quality={97}
        className="flex rounded-md object-cover object-right float-right w-full h-full"
      />
    </div>
  );
};

export default function Home(props) {
  const {
    chat,
    content,
    recentGames: recentGames_,
    recommended,
    initialNow,
    ip,
  } = props;
  const L = useLanguage(["common", "meta", "Slots"]);
  const { hasToken, token } = useUserSession();
  const isMobile = useMobile();
  const isMobileBanner = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 853,
    breakPointThree: 893,
  });

  const { data: recentGames } = useRecentGames(token, recentGames_);
  const { data: rewardsData } = useRewards({
    filters: ["chest_rewards"],
    swr: {
      keepPreviousData: true,
      refreshInterval: 300000,
      revalidateOnFocus: false,
    },
  });

  const sliderRef = useRef(null);
  const [debug, setDebug] = useState({
    device: null,
    ip,
  });
  const scrollTo = sliderRef.current?.scrollTo;
  const [scrollToIndex, setScrollToIndex] = useState(0);

  useEffect(() => {
    if (!scrollToIndex) return;

    scrollTo?.(scrollToIndex);
  }, [scrollToIndex, scrollTo]);

  return (
    <main
      data-debug={JSON.stringify({
        device: JSON.stringify(debug.device),
        ip: debug.ip,
      })}
      className={cn(
        classnames(st, "home-container", isMobile).className,
        "@container"
      )}
    >
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      </Head>

      <MetaContent meta={content?.meta} />
      {hasToken ? (
        <div
          className={`${st["banner-container-carousel"]} ${chat && isMobileBanner ? st[isMobileBanner] : null}`}
        >
          <BannerImg />

          <div className={st["overlay"]}>
            <div className={cn(st["flex-container"], "z-20")}>
              <CarouselHomeBanners emblaRef={sliderRef}>
                <div className={stCarousel["carousel-item"]} key={0}>
                  <div className={stCarousel["carousel-card"]}>
                    <RankBanner />
                  </div>
                </div>
                <div className={stCarousel["carousel-item"]} key={1}>
                  <div className={stCarousel["carousel-card"]}>
                    <RewardsBannerWeekly
                      rewardsData={rewardsData}
                      initialNow={initialNow}
                      changeScroll={() => {
                        setScrollToIndex((i) => (i !== 2 ? 1 : i));
                      }}
                    />
                  </div>
                </div>
                <div className={stCarousel["carousel-item"]} key={2}>
                  <div className={stCarousel["carousel-card"]}>
                    <RewardsBannerMonthly
                      rewardsData={rewardsData}
                      initialNow={initialNow}
                      changeScroll={() => {
                        setScrollToIndex(2);
                      }}
                    />
                  </div>
                </div>
              </CarouselHomeBanners>
            </div>

            <Image
              data-hat
              alt="hat"
              src={THEHAT}
              className="absolute z-30 hidden @4xl:block pointer-events-none"
              quality={99}
              style={{
                right: 116,
                top: -24,
              }}
            />

            <div className="absolute top-0 right-0 h-full w-auto z-0 hidden @4xl:block">
              <Image
                alt="Character image"
                src={Character}
                priority
                quality={99}
                className="z-0 pointer-events-none object-cover object-right float-right w-full h-full rounded-md"
              />
            </div>

            {/* overlay border */}
            <div
              className="absolute w-full h-full z-10 pointer-events-none"
              style={{
                border: "2.5px solid #2A9CFB",
                borderRadius: "6px",
              }}
            />
          </div>
        </div>
      ) : (
        <LoggedOutBanner chat={chat} heading={content?.heading} />
      )}

      <Banner />
      <WinsFeed initialData={props.gamesHistory} />

      {/* Popular Slots Section */}
      <SlotsList
        heading={"recommended"}
        sortBy="recommended"
        carousel
        initialData={recommended?.games}
        count={recommended?.count}
      />
      <SlotsList
        isStatic
        carousel={isMobile === "mobile-four"}
        heading={"rainbetGames"}
        initialData={inHouseGames}
        number={inHouseGames.length}
      />

      {recentGames?.length ? (
        <SlotsList
          isStatic
          heading={"home.recently_played_games"}
          carousel
          initialData={recentGames}
          number={recentGames.length}
        />
      ) : null}

      {!hasToken && <PaymentBanner />}

      <ProviderList
        heading={L("providers")}
        producersOnly
        carousel
        default_sort_by={"popular"}
        grouping={"slots"}
        isHome
      />

      {content?.content && (
        <Accordion
          header={<div>About Rainbet Crypto Casino</div>}
          className={st.accordion}
          variant="second padding"
        >
          <Content __html={content?.content} />
        </Accordion>
      )}
    </main>
  );
}

/**
 *
 * @param { import("next").GetServerSidePropsContext} context
 */
export async function getServerSideProps(context) {
  try {
    context.res.setHeader(
      "Cache-Control",
      "public, s-maxage=10, stale-while-revalidate=59"
    );

    const { authOptions } = await import("@pages/api/auth/[...nextauth]");
    const { getServerSession } = await import("next-auth");

    const session = await getServerSession(
      context.req,
      context.res,
      authOptions
    );
    const token = session?.user?.access_token;
    const headers = {
      "ssr-secret-yel8wd1sa": process.env.SSR_SECRET,
      "user-agent": context.req.headers["user-agent"],
      cookie: context.req.headers.cookie,
      "ssr-client-ip": getClientIp(context),
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    const [content, recommended, recentGames, gamesHistory] = await Promise.all(
      [
        fetchPageContent(context),
        getSlotList(
          {
            sort_by: "recommended",
            num: 36,
          },
          headers
        ).catch(() => []),
        getRecentGames(["v1/user/recent-games", token], headers).catch(
          () => []
        ),
        NODE_API.post("v1/game-history", { wins: true, take: 13 }, { headers })
          .then((res) => res.data)
          .catch(() => []),
      ]
    );

    return {
      props: {
        content,
        recentGames,
        recommended,
        initialNow: Math.floor(Date.now() / 1000),
        gamesHistory,
        ip: headers["ssr-client-ip"] || null,
      },
    };
  } catch (error) {
    logError(
      "pages/index.jsx",
      "getServerSideProps",
      "Failed to fetch data",
      error.toString()
    );
    return {
      props: {
        error: error.toString(),
      },
    };
  }
}
