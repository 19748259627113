export default function OverEighteen({
  width,
  height,
  fill = "#7179A5",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <path
        d="M9.06721 22.9214V12.5547C8.42945 12.9059 7.21165 13.2487 6.51465 13.3315V11.4183C7.70904 11.1719 9.00291 10.4404 9.82445 9.59375H11.6493V22.9214H9.06721Z"
        fill={fill}
      />
      <path
        d="M17.2297 13.1341C17.2297 14.1098 17.9062 14.8286 19.2994 14.8286C20.6355 14.8286 21.3121 14.2694 21.3121 13.1584C21.3121 12.0977 20.6151 11.3527 19.2729 11.3527C17.8797 11.3527 17.2297 12.021 17.2297 13.1341ZM21.6919 19.0081C21.6919 17.5639 20.7152 16.8611 19.2463 16.8611C17.6099 16.8611 16.8088 17.7558 16.8088 18.9939C16.8088 20.2806 17.759 21.1812 19.2833 21.1812C20.6868 21.1812 21.6919 20.4866 21.6919 19.0081ZM14.2432 19.1193C14.2432 17.3595 15.4716 16.3047 16.5241 15.86C15.443 15.2496 14.793 14.4092 14.793 13.0672C14.793 10.8783 16.394 9.38281 19.3342 9.38281C22.0208 9.38281 23.7487 10.8216 23.7487 13.0329C23.7487 14.5285 22.9576 15.3385 21.9808 15.7346C23.0513 16.1084 24.2433 17.2722 24.2433 19.0363C24.2433 21.7103 22.1026 23.1653 19.1276 23.1653C16.4269 23.1653 14.2432 21.6962 14.2432 19.1193Z"
        fill={fill}
      />
      <path d="M27.7998 12.3281V4.5625H30.1711V12.3281H27.7998Z" fill={fill} />
      <path d="M25.0586 9.61774V7.27344H32.9138V9.61774H25.0586Z" fill={fill} />
      <path
        d="M16.1849 32C7.26051 32 0 24.8225 0 16.0001C0 7.17759 7.26051 0 16.1849 0C19.6532 0 22.9602 1.06896 25.7488 3.09119L24.136 5.26491C21.8183 3.58435 19.0688 2.69602 16.1849 2.69602C8.76423 2.69602 2.72709 8.66414 2.72709 16.0001C2.72709 23.3359 8.76423 29.304 16.1849 29.304C23.6055 29.304 29.6425 23.3359 29.6425 16.0001C29.6425 15.5856 29.6231 15.1682 29.5849 14.759L32.3004 14.5108C32.3464 15.0021 32.3697 15.5032 32.3697 16.0001C32.3697 24.8225 25.1092 32 16.1849 32Z"
        fill={fill}
      />
    </svg>
  );
}
