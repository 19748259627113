import {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAnimate } from "framer-motion";
import { cn } from "@lib";
type Orientation = "vertical" | "horizontal";

type RollingListProps = {
  children: ReactElement[];
  orientation?: Orientation;
  gap?: number;
  className?: string;
};

const RollingList = ({
  children = [],
  orientation = "vertical",
  gap = 0,
  className,
}: RollingListProps) => {
  const childrenLength = children?.length || 0;
  const [scope, animate_] = useAnimate<HTMLDivElement>();
  const getChildren = useCallback(() => {
    return Array.from(scope.current?.children || []) as HTMLElement[];
  }, [scope]);

  const isLast = useCallback(
    (i: number) => i >= childrenLength - 1,
    [childrenLength]
  );

  const isFirst = useCallback((i: number) => i === 0, []);
  const trueGap = (gap / childrenLength) * (childrenLength - 1);
  const isAnimating = useRef(false);

  const animate = useCallback(() => {
    isAnimating.current = true;

    const ps = getChildren().map((c, i) => {
      const transform = [`calc(-100% - ${trueGap}px)`, 0] as const;
      const opacity = [+(isFirst(i) ? 0 : 1), +(isLast(i) ? 0 : 1)] as const;

      const axis = orientation === "vertical" ? "y" : "x";

      return animate_(
        c,
        {
          [axis]: transform,
          // @ts-expect-error not sure how to get this to work, good luck
          opacity,
        },
        {
          x: {
            duration: 0.2,
            ease: "easeInOut",
          },
          y: {
            duration: 0.2,
            ease: "easeInOut",
          },
          opacity: {
            duration: 0.2,
            ease: "easeInOut",
          },
        }
      );
    });

    return Promise.all(ps).finally(() => {
      isAnimating.current = false;
    });
  }, [animate_, getChildren, isFirst, isLast, orientation, trueGap]);

  const initialStyles = (i: number) => ({
    opacity: +!isFirst(i),
    minWidth: "var(--min-width)",
    transform: "var(--transform)",
  });

  const [items, setItems] = useState(children);
  const renderKeys = useMemo(() => items.map((c) => c.key).join(","), [items]);

  useEffect(() => {
    if (!children?.length) return;

    setItems(() => children);
  }, [children]);

  useEffect(() => {
    animate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderKeys]);

  const horizontalStyles = {
    "--min-width": `calc((100% / ${childrenLength - 1}) - ${trueGap}px)`,
    "--transform": `translateX(calc(-100% - ${trueGap}px))`,
    gap: `${gap}px`,
  } as CSSProperties;

  const verticalStyles = {
    "--min-width": `calc((100% / ${childrenLength - 1}) - ${trueGap}px)`,
    "--transform": `translateY(calc(-100% - ${trueGap}px))`,
    gap: `${gap}px`,
  } as CSSProperties;

  if (!children?.length) return null;

  return (
    <div
      className={cn(
        "relative flex",
        orientation === "vertical" ? "flex-col" : "flex-row",
        className
      )}
      ref={scope}
      style={orientation === "vertical" ? verticalStyles : horizontalStyles}
    >
      {items.map((c, i) => (
        <div
          key={c.key}
          data-key={c.key}
          data-index={i}
          style={initialStyles(i)}
          className={cn(isLast(i) && "pointer-events-none")}
        >
          {c}
        </div>
      ))}
    </div>
  );
};

export default RollingList;
