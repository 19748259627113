import PubSub from "pubsub-js";
import { startTransition, useEffect, useRef, useState } from "react";
import randomIndex from "./tools/array/randomIndex";
import removeIndex from "./tools/array/removeIndex";
import exponentialDecay from "./tools/exponencialDecay";

export class Queue<T> {
  private queue: T[] = [];
  public readonly intervalLimit = 2000;
  private isRunning = false;

  static readonly events = {
    change: new Event("change"),
    next: new Event("next"),
  };

  get list() {
    return [...this.queue];
  }

  get length() {
    return this.queue.length;
  }

  get interval() {
    const interval = Math.floor(exponentialDecay(1000, 0.1, this.length));

    return Math.max(Math.min(interval, this.intervalLimit), 0);
  }

  async next() {
    this.isRunning = true;
    if (this.queue.length < 1) {
      this.isRunning = false;
      return;
    }

    const index = randomIndex(this.queue);
    const next = this.queue[index];
    const queue = removeIndex(this.queue, index);

    this.queue = queue;
    PubSub.publishSync(Queue.events.change.type, this.queue);
    PubSub.publish(Queue.events.next.type, next);

    if (this.interval < 17) {
      queueMicrotask(() => {
        this.next();
      });
      return;
    }

    setTimeout(() => {
      this.next();
    }, this.interval) as unknown as number;
  }

  add(...items: T[]) {
    this.queue = [...this.queue, ...items];
    PubSub.publish(Queue.events.change.type, this.queue);

    if (!this.isRunning) this.next();
  }

  // eslint-disable-next-line no-unused-vars
  onChange(listener: (queue: T[]) => void) {
    const tk = PubSub.subscribe(Queue.events.change.type, (_, queue) =>
      listener(queue as T[])
    );

    return () => PubSub.unsubscribe(tk);
  }

  // eslint-disable-next-line no-unused-vars
  onNext(listener: (next: T) => void) {
    const tk = PubSub.subscribe(Queue.events.next.type, (_, next) =>
      listener(next as T)
    );

    return () => PubSub.unsubscribe(tk);
  }

  destroy() {
    this.queue = [];
    this.isRunning = false;
  }
}

const defaultInitial = [];

type UseQueueProps<T> = {
  limit?: number;
  initial?: T[];
  resetsOnInitial?: boolean;
};

export const useQueue = <T,>({
  limit = 10,
  initial = defaultInitial as T[],
  resetsOnInitial = false,
}: UseQueueProps<T>) => {
  const { current: queue } = useRef(new Queue<T>());
  const [list, setList] = useState(queue.list);
  const [output, setOutput] = useState<T[]>(initial);
  const [id, setId] = useState(initial.map((_, i) => i));

  useEffect(() => {
    const unsub = queue.onChange(setList);
    const unsubNext = queue.onNext((e) => {
      if (!e) return;

      startTransition(() => {
        setOutput((prev) => {
          const first = prev.slice(0, limit);
          return [e, ...first];
        });
        setId((prev) => [prev[0] + 1, ...prev]);
      });
    });

    return () => {
      unsub();
      unsubNext();
      queue.destroy();
    };
  }, [queue, limit]);

  useEffect(() => {
    if (resetsOnInitial) {
      setOutput(initial);
      setId(initial.map((_, i) => i));
    }
  }, [initial, resetsOnInitial]);

  return {
    queue,
    list,
    output,
    id,
  };
};
