import { Suspense, lazy, useCallback, startTransition } from "react";
import st from "../styles/components/Modal.module.scss";
import { useRouter } from "next/router";
import useDetectOutside from "../hooks/useDetectOutside";
import useMobile from "../hooks/useMobile";
import gsap from "gsap";
import LoadingSpinner from "./Loading/LoadingSpinner";
import { useSearchParams } from "next/navigation";
import { useGSAP } from "@gsap/react";
import { classnames } from "@lib/tools/helpers";
import AuthModal from "../components/Auth";
import { useUserDetails } from "@hooks/useUserSession";
import usePopup from "@hooks/usePopup";
import { motion } from "framer-motion";

const dynamic = lazy;
const SearchModal = dynamic(() => import("../components/SearchModal"));
const Button = dynamic(() => import("../items/Button"));
const WalletModal = dynamic(() => import("../components/Wallet"));
const WalletSettings = dynamic(() => import("../components/WalletSettings"));
const UserDisplayModal = dynamic(() => import("../components/user-display"));
const BetResultModal = dynamic(() => import("./BetResult"));
const BlockerModal = dynamic(() => import("./Blocker"));
const UserProfileModal = dynamic(() => import("./UserProfile"));
const TwoFactorModal = dynamic(() => import("./two-fa"));
const ResetPasswordModal = dynamic(() => import("./reset-password"));
const PromoTermsModal = dynamic(() => import("./PromoTerms"));
const SteamModal = dynamic(() => import("./Steam"));
const CancelPromotionModal = dynamic(
  () => import("./PromoTerms/CancelPromotionModal")
);
const GiftCardModal = dynamic(
  () => import("./GiftCardModals/GiftCardModal.jsx")
);
const VaultModal = dynamic(() => import("./vault-modal"));
const FairPlayModal = dynamic(() => import("../components/FairPlay"));
const CaseItemsModal = dynamic(
  () => import("../Games/CaseBattles/CaseItemsModal")
);
const CaseBattlesSelectModal = dynamic(
  () => import("../Games/CaseBattles/CaseBattlesModal")
);

const RacesModal = dynamic(() => import("./RacesModal.jsx"));
const VerifyEmailModal = dynamic(() => import("./verify-email-modal"));

const getModal = (type, classType) => {
  switch (type) {
    case "how-races-work":
      return RacesModal;
    case "auth":
      return AuthModal;
    case "wallet":
      return WalletModal;
    case "user":
      return UserDisplayModal;
    case "fairplay":
      return FairPlayModal;
    case "bet":
      return BetResultModal;
    case "twoFactor":
      return TwoFactorModal;
    case "blocker":
      return BlockerModal;
    case "profile":
      return UserProfileModal;
    case "steam":
      return SteamModal;
    case "recover":
      return ResetPasswordModal;
    case "promotion":
      classType.push("promotions-modal");
      return PromoTermsModal;
    case "promotion-cancel":
      classType.push("promotions-modal");
      return CancelPromotionModal;
    case "gift-card":
      classType.push("gift-card-modals");
      return GiftCardModal;
    case "vault":
      classType.push("gift-card-modals");
      return VaultModal;
    case "case-battles-select":
      return CaseBattlesSelectModal;
    case "case-list":
      return CaseItemsModal;
    case "search":
      return SearchModal;
    case "wallet-settings":
      return WalletSettings;
    case "verify-email":
      return VerifyEmailModal;

    default:
      return null;
  }
};

const emptyArray = [];
export function Modal({ blockStatus, classType = emptyArray }) {
  classType = classType.filter(
    (clt) => clt !== "promotions-modal" && clt != "gift-card-modal"
  );

  const isMobile = useMobile();
  const router = useRouter();
  const setMessage = usePopup();

  const search = useSearchParams();
  const modalType = search.get("modal");
  const Modal = getModal(modalType, classType);

  const { data: userDetails } = useUserDetails();

  const isAuthClass = modalType === "auth" ? "auth-modal" : "";
  const isAuthInputs = modalType === "auth" ? "modal-close-btn2" : "close-btn";

  const handleReplace = useCallback(() => {
    startTransition(() => {
      const { asPath, query } = router;
      const playQuery = query.play ? `?play=${query.play}` : "";

      router.replace(asPath.split("?")[0] + playQuery, undefined, {
        shallow: true,
      });
    });
  }, [router]);

  const { ref } = useDetectOutside(true, () => {
    if (blockStatus !== "block") handleReplace();
  });

  useGSAP(
    () => {
      if (!document.getElementById("inner-modal-container-id")) return;
      if (!document.getElementById("main-model-container")) return;

      gsap.to(
        "#inner-modal-container-id",
        {
          duration: 0.3, // Duration for the scaling and opacity effect
          opacity: 1,
          ease: "sine.in",
        }
        // "-=0.05",
      ); // Start this animation halfway through the first animation

      gsap.to("#main-model-container", {
        duration: 0.8, // Duration for the blur effect
        backdropFilter: "blur(2px)",
        ease: "sine.in",
      });
    },
    { dependencies: [Modal] }
  );

  const isSensitiveModal = modalType === "wallet" || modalType === "gift-card";
  if (isSensitiveModal && userDetails?.self_exclusion !== undefined) {
    setMessage({
      code: "responses.er_self_exclusion_modal_self_exclusion_active",
      type: 0,
    });
    handleReplace();
    return null;
  }

  if (!Modal) return null;

  return (
    <motion.div
      id={"main-model-container"}
      className={st["modal-container"]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <div className={st["modal-flow-hidden"]}>
        <div
          ref={ref}
          id="inner-modal-container-id"
          {...classnames(
            st,
            isAuthClass,
            "inner-modal-container",
            ...classType,
            isMobile
          )}
        >
          <Suspense fallback={<LoadingSpinner />}>
            {blockStatus !== "block" && (
              <Button
                icon="close"
                method={() => {
                  handleReplace();
                }}
                classType={["square-icon", isAuthInputs]}
              />
            )}
            <Modal />
          </Suspense>
        </div>
      </div>
    </motion.div>
  );
}

export function ModalNav({ navItems, type = "tab" }) {
  return (
    <div className={st["modal-nav"]}>
      {navItems.map((item, i) => {
        return (
          <Button
            isShallow={true}
            showActive={true}
            key={i}
            text={item.text}
            buttonType={"link"}
            classType={[type, "live-tab", "login-tab"]}
            query={item.query}
          />
        );
      })}
    </div>
  );
}
