import { cn } from "@lib";
import React, { useEffect, useState } from "react";

export function ClickCopy({
  value,
  className,
}: {
  value: string;
  className?: string;
}) {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1200);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <button
      onClick={(e) => {
        e?.preventDefault();
        if (value) {
          navigator.clipboard.writeText(value.toString());
          setCopied(true);
        }
      }}
      className={cn(
        "appearance-none border-0 outline-none cursor-pointer mt-1 mb-0 flex justify-center items-center relative",
        className
      )}
    >
      <div className="relative flex items-center">
        <div className="div-clip">
          <Clippy
            style={{
              strokeDasharray: 50,
              strokeDashoffset: copied ? -50 : 0,
            }}
            className="py-0 text-white w-[18px] h-[18px] transition-all duration-300 ease-in-out"
          />
        </div>
        <Check
          copied={copied || undefined}
          isvisible={copied || undefined}
          style={{
            zIndex: 4,
            strokeDasharray: 50,
            strokeDashoffset: copied ? 0 : -50,
          }}
          className="text-[#6bd35e] absolute top[1]px left-[1]px w-full flex items-center transition-all duration-300 ease-in-out"
        />
      </div>
    </button>
  );
}

function Clippy(props) {
  return (
    <div
      style={{
        opacity: props?.copied ? 0 : 1,
      }}
      className="w-[18px] h-[18px] transition-all duration-300 ease-in-out"
    >
      <svg
        viewBox="0 0 30 30"
        fill="#454E79"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
        style={{
          opacity: props.copied ? 0 : 1,
          width: "16px",
          height: "16px",
        }}
      >
        <path
          d="M27 4H11C10.7348 4 10.4804 4.10536 10.2929 4.29289C10.1054 4.48043 10 4.73478 10 5V10H5C4.73478 10 4.48043 10.1054 4.29289 10.2929C4.10536 10.4804 4 10.7348 4 11V27C4 27.2652 4.10536 27.5196 4.29289 27.7071C4.48043 27.8946 4.73478 28 5 28H21C21.2652 28 21.5196 27.8946 21.7071 27.7071C21.8946 27.5196 22 27.2652 22 27V22H27C27.2652 22 27.5196 21.8946 27.7071 21.7071C27.8946 21.5196 28 21.2652 28 21V5C28 4.73478 27.8946 4.48043 27.7071 4.29289C27.5196 4.10536 27.2652 4 27 4ZM20 26H6V12H20V26ZM26 20H22V11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10H12V6H26V20Z"
          fill="#454E79"
        />
      </svg>
    </div>
  );
}

function Check(props) {
  return (
    <div
      style={{
        opacity: props?.copied ? 1 : 0,
        padding: "2px",
      }}
      className="absolute p-[2px] w-[18px] h-[18px] bg-[#191F3B] rounded-[125px] transition-all duration-300 ease-in-out"
    >
      <svg
        viewBox="0 0 20 20"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <path d="M13.25 4.75L6 12L2.75 8.75" />
      </svg>
    </div>
  );
}
