import NodeAPI from "@lib/api/nodeApi";
import { useEffect, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";
import { NODE_API } from "@lib/api/api";
import { useAtom } from "jotai";
import {
  dynamicBalanceAtom,
  fakeBalanceAtom,
  inPlayAtom,
  userBalanceTypeAtom,
} from "@store/wallet";
import { useHydrateAtoms } from "jotai/utils";
import { CurrencyRates } from "@/types";
import { useSession } from "next-auth/react";
import useToken from "./useToken";
import { logError } from "@lib/tools/logger";

export interface Currency {
  code: string;
  prepend: string;
  append: string;
  rate: number;
  decimals: number;
}

export interface Balance {
  amount: number;
  currency: Currency;
}

const defaultRate = {
  rate: 1,
  display: {
    isDefault: true,
    prepend: "$",
    append: null,
    icon: "https://cdn.rainbet.com/currencies%2Fusd.svg",
  },
};

export const WALLET_KEY = "v1/user/wallet";
export const CURRENCIES_KEY = "v1/public/currencies";
export const BALANCE_KEY = (type: "promotional" | "primary") =>
  `v1/user/balance/${type}`;
export const VAULT_BALANCE_KEY = "v1/user/balance/vault";

export interface Wallet {
  active: Active;
  displayed?: any[];
}

interface Active {
  currency: string;
  promotional: string;
  primary: string;
  vault: string;
}
export const fetchWallet = ([url, token], headers = {}) =>
  NodeAPI.get<Wallet>(url || "v1/user/wallet", {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => res.data);

export const fetchUserBalance = (
  [, token, type],
  headers = {}
): Promise<Balance> =>
  NODE_API.get(`v1/user/balance/${type ? "promotional" : "primary"}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => {
    return res.data;
  });

export const fetchCurrencies = () =>
  NODE_API.get("v1/public/currencies").then((res) => res.data);

export const fetchBalanceVault = (
  [url, token],
  headers = {}
): Promise<Balance> =>
  NodeAPI.get(url || "v1/user/balance/vault", {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => res.data);

export default function useWallet() {
  const session = useSession();
  const token = useToken();
  const hasToken = session.status !== "unauthenticated" && !!token;
  const [inPlay, setInPlay] = useAtom(inPlayAtom);
  const [dynamicBalance, setDynamicBalance] = useAtom(dynamicBalanceAtom);
  const [fakeBalance, setFakeBalance] = useAtom(fakeBalanceAtom);
  const [userBalanceType, setUserBalanceType] = useAtom(userBalanceTypeAtom);
  const balanceType = userBalanceType ? "promotional" : ("primary" as const);
  const setUserActiveBalance = setFakeBalance;
  const lockedBalance = dynamicBalance;
  const { cache } = useSWRConfig();

  const walletBalanceList = useSWR(
    hasToken ? WALLET_KEY : null,
    async (url) => {
      const data = await fetchWallet([url, token], {});
      const balances = splitWallet(data, currenciesData);
      if (!balances) return data;

      // update the cache with the new balances to avoid re-fetching them
      cache.set(BALANCE_KEY(balanceType), {
        data: userBalanceType ? balances.promotional : balances.primary,
      });
      cache.set(VAULT_BALANCE_KEY, { data: balances.vault });

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: !cache.get(WALLET_KEY)?.data,
      keepPreviousData: true,
      fallbackData: cache.get(WALLET_KEY)?.data,
    }
  );

  const { data: userBalance, mutate: balanceMutate } = useSWR<Balance>(
    hasToken ? BALANCE_KEY(balanceType) : null,
    (url) => fetchUserBalance([url, token, userBalanceType], {}),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(BALANCE_KEY(balanceType))?.data,
      fallbackData: cache.get(BALANCE_KEY(balanceType))?.data,
    }
  );

  const { data: vaultBalance, mutate: vaultBalanceMutate } = useSWR<Balance>(
    hasToken ? VAULT_BALANCE_KEY : null,
    (url) => fetchBalanceVault([url, token], {}),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(VAULT_BALANCE_KEY)?.data,
      fallbackData: cache.get(VAULT_BALANCE_KEY)?.data,
    }
  );

  const { data: currenciesData } = useSWR<CurrencyRates>(
    CURRENCIES_KEY,
    async () => {
      const response = await fetchCurrencies();
      const cleanData = Object.entries(response)
        .filter(([key]) => key !== "_k")
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {} as CurrencyRates
        );
      return cleanData;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(CURRENCIES_KEY)?.data,
      revalidateOnReconnect: false,
      keepPreviousData: true,
      refreshInterval: 600000,
      fallbackData: (() => {
        const cachedData = cache.get(CURRENCIES_KEY)?.data;
        if (!cachedData) return undefined;
        const cleanCache = Object.entries(cachedData)
          .filter(([key]) => key !== "_k")
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {} as CurrencyRates
          );
        return cleanCache;
      })(),
    }
  );

  useHydrateAtoms([[fakeBalanceAtom, userBalance?.amount]]);

  const userActiveBalance = useMemo(
    () => (dynamicBalance ? fakeBalance : userBalance?.amount || 0),
    [dynamicBalance, fakeBalance, userBalance?.amount]
  );

  const activeRate = useMemo(
    () => currenciesData?.[userBalance?.currency?.code] || defaultRate,
    [currenciesData, userBalance?.currency?.code]
  );

  useEffect(() => {
    if (dynamicBalance) return;
    setFakeBalance(userActiveBalance);
  }, [dynamicBalance, setFakeBalance, userActiveBalance]);

  return {
    currencies: currenciesData,
    lockedBalance,
    balance: userActiveBalance,
    activeBalance: userActiveBalance,
    userActiveBalance,
    promoBalance: walletBalanceList?.data?.active?.promotional || 0,
    setInPlay,
    inPlay,
    balanceMutate,
    userBalanceType,
    dynamicBalance,
    setDynamicBalance,
    setUserActiveBalance,
    setUserBalanceType,
    setLockBalance: setDynamicBalance,
    userBalance,
    vaultBalance,
    vaultBalanceMutate,
    walletBalanceList,
    walletSetting: userBalance?.currency?.code || "USD",
    activeRate,
    fakeBalance,
  };
}

export function splitWallet(wallet: Wallet, currencies: CurrencyRates) {
  try {
    const currencyCode = wallet?.active?.currency || "USD";
    const currency = {
      ...currencies[currencyCode]?.display,
      code: currencyCode,
    };

    const primary: Balance = {
      amount: +wallet?.active?.primary || 0,
      currency,
    };
    const promotional: Balance = {
      amount: +wallet?.active?.promotional || 0,
      currency,
    };
    const vault: Balance = {
      amount: +wallet?.active?.vault || 0,
      currency,
    };

    return { primary, promotional, vault, currency };
  } catch (error) {
    logError("Error splitting wallet", error);
    return null;
  }
}
