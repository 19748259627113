import { useMemo } from "react";
import { useUserDetails } from "@hooks/useUserSession";
import { convertRanks, ranksArray } from "@components/RewardsContent/ranks";
import useWallet from "./useWallet";
import useSWR from "swr";
import API from "@lib/api/api";
import useConvert from "./useConvert";
import { RewardCode } from "@items/RewardIcons";

export const getRank = (total_wagered, ranks = ranksArray) => {
  return [...ranks].reverse().find((r) => +r.threshold <= total_wagered);
};

export const useRankProgress = () => {
  const { data: userData } = useUserDetails();
  const { activeRate } = useWallet();
  const { getConvertedAmount } = useConvert();

  const { data: claimedRanks, mutate: mutateClaimedRanks } = useSWR(
    "rewards/ranks-claimed",
    () =>
      API.post("rewards/ranks-claimed").then(
        (res) => res.data.result.ranks_claimed
      ),
    { revalidateOnFocus: false, revalidateOnMount: false }
  );

  const data = useMemo(() => {
    const nextRankThreshold = getConvertedAmount(
      userData?.required_to_next_rank_usd,
      { round: { direction: "up" } }
    );
    const ranks = convertRanks(activeRate?.rate);
    const userRank = {
      name: (userData?.betRank ?? "unranked") as RewardCode,
      level: userData?.betRankLevel,
      index: ranks.findIndex(
        (rank) =>
          rank.name === userData?.betRank &&
          rank.level === userData?.betRankLevel
      ),
      totalWagered: userData?.wagered_amount,
    };
    const nextRank = {
      name: userData?.nextRank,
      level: userData?.nextRankLevel,
      threshold: nextRankThreshold,
      totalToNextRank: nextRankThreshold + +userData?.wagered_amount,
    };
    const isComplete = !nextRank.name;
    const progress = isComplete ? 100 : userData?.percentage;
    const isLoading = !(userRank.totalWagered || nextRank.name);

    const unclaimedRanks =
      userRank && claimedRanks
        ? ranks
            .slice(0, userRank.index + 1)
            .filter(
              (rank) =>
                !claimedRanks.some(
                  (claimed) =>
                    claimed.name === rank.name && claimed.level === rank.level
                )
            )
        : [];

    return {
      userRank,
      nextRank,
      progress: progress === 0 ? 1 : progress,
      isComplete,
      unclaimedRanks,
      claimedRanks,
      isLoading,
    };
  }, [
    activeRate?.rate,
    claimedRanks,
    getConvertedAmount,
    userData?.betRank,
    userData?.betRankLevel,
    userData?.nextRank,
    userData?.nextRankLevel,
    userData?.percentage,
    userData?.required_to_next_rank_usd,
    userData?.wagered_amount,
  ]);

  return { ...data, mutateClaimedRanks };
};
