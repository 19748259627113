import { Button } from "@components/new-ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/new-ui/select";
import useFlags from "@hooks/useFlags";
import { Dict } from "@hooks/useLanguage";
import useNow from "@hooks/useNow";
import usePopup from "@hooks/usePopup";
import useRewards from "@hooks/useRewards";
import { useUpdateUserPreferences } from "@hooks/useUpdateUserPreferences";
import { logout, useUserSession } from "@hooks/useUserSession";
import { cn } from "@lib";
import API from "@lib/api/api";
import { NODE_API2 } from "@lib/api/nodeApi";
import StorageService from "@lib/services/Storage.Service";
import userRakebackBoosted from "@recoil/rakebackBoosted/atom";
import { chatLanguageAtom, chatOpenAtom, miniSideAtom } from "@store/global";
import { differenceInMilliseconds } from "date-fns";
import { useAtom, useSetAtom } from "jotai";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import router from "next/router";
import { parseCookies, setCookie } from "nookies";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { BalanceDropdown } from "./balance-dropdown";
import { HeaderContent } from "./header-content";
import {
  CasinoNavBarIcon,
  ChatIcon,
  MenuIcon,
  SearchIcon,
  SportsNavBarIcon,
  WalletIcon,
} from "./icons";

export default function NewHeader() {
  const now = useNow();
  const [rakebackBoosted, setRakebackBoosted] =
    useRecoilState(userRakebackBoosted);
  const { mutate, userData, addTokenHeader } = useUserSession();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const openedModal = useRef(false);
  const affiliateRedeemed = useRef(false);
  const [affiliateApproved, setAffiliateApproved] = useState(null);
  const refSendPromo = useRef(false);
  const refPromoSent = useRef(false);
  const setMessage = usePopup();
  const [sidebarState, setSidebarState] = useAtom(miniSideAtom);

  const { data: rewardsData, mutate: getRewardsData } = useRewards({
    filters: [
      "chest_rewards",
      "rakeback_boost",
      "rakeback",
      "calendar",
      "keys",
    ],
    swr: {
      keepPreviousData: true,
      refreshInterval: 300000,
      revalidateOnFocus: false,
    },
  });

  const removeQueryParam = useCallback(
    (param) => {
      const params = new URLSearchParams(searchParams);
      params.delete(param);
      router.replace(`${pathname}?${params.toString()}`);
    },
    [pathname, searchParams]
  );

  //Logs the user out if delete is present
  useEffect(() => {
    if (userData?.deleted) {
      logout();
    }
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      if (!userData && affiliateApproved) {
        router.push(`/?modal=auth&tab=register`);
      }
      setAffiliateApproved(null);
    }, 500);
  }, [userData, affiliateApproved]);

  const checkReferralCode = useCallback((code) => {
    API.post("affiliate/validate", {
      affiliate_code: code,
    })
      .then((res) => {
        if (res.status === 202) {
          StorageService.setReferralCode(code);
          setAffiliateApproved(true);
        }
      })
      .catch(() => {});
  }, []);

  //Collect the referral code from the url
  useEffect(() => {
    //No # and it detects the full text
    if (searchParams.has("r")) {
      openedModal.current = true;
      const code = searchParams.get("r");
      removeQueryParam("r");
      checkReferralCode(code);
    }
  }, [checkReferralCode, removeQueryParam, searchParams]);

  useEffect(() => {
    //No # and it detects the full text
    if (searchParams.has("promo")) {
      openedModal.current = true;
      StorageService.setLocalStorageValue("promo", searchParams.get("promo"));
      removeQueryParam("promo");
    } else {
      const fragmentIdentifier = window.location.hash;

      // // If no referral code was found in the query string, check the fragment identifier
      if (fragmentIdentifier) {
        refSendPromo.current = true;
        StorageService.setLocalStorageValue("promo", fragmentIdentifier);
        removeQueryParam("promo");
      }
    }
  }, [removeQueryParam, searchParams]);

  //Open the add affiliate modal if the user is eligible

  //assumed that this will get called after the router has processes the query.
  useEffect(() => {
    //If they have an affiliate code, open the affiliate modal
    if (userData && !affiliateRedeemed.current) {
      affiliateRedeemed.current = true;

      const affiliate = StorageService.getReferralCode();
      if (userData?.affiliate?.eligible && affiliate) {
        router.push(`/?modal=wallet&tab=affiliate`);
      } else {
        StorageService.removeReferralCode();
      }
    }

    const localPromo = StorageService.getLocalStorageValue("promo");

    //Send the promo code if the user is logged in
    if (userData && localPromo && !refPromoSent.current) {
      refPromoSent.current = true;

      NODE_API2.post(
        "v1/promotions/redeem-code",
        { promotion_code: localPromo },
        addTokenHeader()
      )
        .then((res) => {
          if (res.data.success) {
            setTimeout(() => {
              mutate();
            }, 500);
          } else {
            setMessage({ code: "responses." + res.data.error, type: 0 });
          }
        })
        .catch((e) => {
          const msg = e?.response?.data?.error || "er_network";
          setMessage({ code: "responses." + msg, type: 0 });
        })
        .finally(() => {
          StorageService.clearLocalStorageValue("promo");
        });
    }
  }, [addTokenHeader, mutate, setMessage, userData]);

  const { isBoostExpired, boostProgress } = useMemo(() => {
    const diffExpire = rewardsData?.rakeback_boost?.expires_at
      ? differenceInMilliseconds(rewardsData?.rakeback_boost?.expires_at, now)
      : 0;

    const diffTotal =
      rewardsData?.rakeback_boost?.expires_at &&
      rewardsData?.rakeback_boost?.started_at
        ? differenceInMilliseconds(
            rewardsData?.rakeback_boost?.expires_at,
            rewardsData?.rakeback_boost?.started_at
          )
        : 0;

    const boostProgress = (diffExpire / diffTotal) * 100;

    const isBoostExpired = diffExpire < 1;

    if (isBoostExpired) {
      return { boostProgress: 0, isBoostExpired: true };
    }

    return { boostProgress, isBoostExpired };
  }, [now, rewardsData?.rakeback_boost]);

  const isRakeBoostActive = !!(rewardsData?.rakeback_boost && !isBoostExpired);

  useEffect(() => {
    if (isBoostExpired) {
      getRewardsData();
    }
  }, [isBoostExpired, getRewardsData]);

  useEffect(() => {
    if (rakebackBoosted) {
      getRewardsData().then(() => {
        setRakebackBoosted(false);
      });
    }
  }, [rakebackBoosted, getRewardsData, setRakebackBoosted]);

  // Sync with cookie value on mount
  useEffect(() => {
    const cookies = parseCookies();
    const cookieValue = cookies.miniSide === "true";
    if (sidebarState !== cookieValue) {
      setSidebarState(cookieValue);
    }
  }, []);

  return (
    <header
      className={cn(
        "sticky top-0 left-0 right-0 bg-[#191F3B] z-[5004] transition-opacity duration-300"
      )}
    >
      <HeaderContent
        isRakeBoostActive={isRakeBoostActive}
        boostProgress={boostProgress}
        rewardsData={rewardsData}
        getRewardsData={getRewardsData}
      />
    </header>
  );
}

export function MenuButton() {
  const [sidebarState, setSidebarState] = useAtom(miniSideAtom);
  const { mutate: updatePreferences } = useUpdateUserPreferences();

  return (
    <Button
      variant="ghost"
      className="w-[42px] h-[42px] min-h-[42px] px-0 py-0 [&_svg]:size-5"
      onClick={() => {
        setSidebarState(!sidebarState);
        setCookie(null, "miniSide", String(!sidebarState), {
          path: "/",
          maxAge: 60 * 60 * 24 * 365,
          sameSite: "lax",
        });
        updatePreferences({
          sidebar_open: !sidebarState,
        });
      }}
    >
      <MenuIcon />
    </Button>
  );
}

export function ChatButton() {
  const [chatState, setChatState] = useAtom(chatOpenAtom);
  const { mutate: updatePreferences } = useUpdateUserPreferences();

  return (
    <Button
      variant="dark"
      className="w-[48px] h-[48px] !min-h-[48px] px-0 py-0 [&_svg]:size-5 2lg:flex hidden"
      aria-selected={chatState}
      onClick={() => {
        setChatState(!chatState);
        setCookie(null, "chatOpen", String(!chatState), {
          path: "/",
          maxAge: 60 * 60 * 24 * 365,
          sameSite: "lax",
        });
        updatePreferences({
          chat_open: !chatState,
        });
      }}
    >
      <ChatIcon />
    </Button>
  );
}

export function SearchButton() {
  const pathname = usePathname();

  return (
    <Link
      href={{ pathname, query: { modal: "search" } }}
      className="2lg:block hidden"
    >
      <Button
        variant="dark"
        className="w-[48px] h-[48px] !min-h-[48px] px-0 py-0 [&_svg]:size-5"
        aria-selected={pathname.includes("search")}
      >
        <SearchIcon />
      </Button>
    </Link>
  );
}

export function CasinoSportsButtons({
  miniVersion,
}: {
  miniVersion?: boolean;
}) {
  const pathname = usePathname();
  const [menuState, setMenuState] = useState("games");

  useEffect(() => {
    const newState =
      pathname.includes("sports") && !pathname.includes("sportsbook-rules")
        ? "sports"
        : "games";
    setMenuState(newState);
  }, [pathname]);

  const buttonClasses = (isActive: boolean) =>
    cn(
      "border-[1.5px] overflow-hidden",
      isActive
        ? "shadow-[inset_0_0_12px_rgba(255,255,255,0.25)]"
        : "bg-transparent border-transparent"
    );

  if (miniVersion) {
    return (
      <div className="border-b border-solid border-b-[rgba(255,255,255,0.17)] mb-[15px] pb-[15px] flex items-center justify-center">
        <div className="flex items-center flex-col gap-[2px] p-[3px] bg-gradient-to-b from-[#7C83B1]/20 to-[#5E679E]/20 border border-solid border-[#5E679E]/35 rounded-md">
          <Link href="/casino">
            <Button
              variant={menuState === "games" ? "ghost-blue" : "normal"}
              className={cn(
                "w-[37px] h-[37px] min-h-[37px] px-[18px] [&_svg]:size-5",
                buttonClasses(menuState === "games"),
                menuState === "games"
                  ? "[&_path]:opacity-100"
                  : "border-none hover:!bg-white/5"
              )}
            >
              <CasinoNavBarIcon miniVersion={true} />
            </Button>
          </Link>
          <Link href="/sportsbook">
            <Button
              variant={menuState === "sports" ? "ghost-orange" : "normal"}
              className={cn(
                "w-[37px] h-[37px] min-h-[37px] px-[18px] [&_svg]:size-5",
                buttonClasses(menuState === "sports"),
                menuState === "sports"
                  ? "[&_path]:opacity-100"
                  : "border-none hover:!bg-white/5"
              )}
            >
              <SportsNavBarIcon miniVersion={true} />
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-[2px] h-[44px] bg-gradient-to-b from-[#7C83B1]/20 to-[#5E679E]/20 border border-solid border-[#5E679E]/35 rounded-md p-[3px]">
      <Link href="/casino">
        <Button
          variant={menuState === "games" ? "ghost-blue" : "normal"}
          className={cn(
            "h-[38px] min-h-[38px] px-[18px] [&_svg]:size-8",
            buttonClasses(menuState === "games"),
            menuState === "games" ? "" : "border-none hover:!bg-white/5"
          )}
        >
          {menuState === "games" ? <CasinoNavBarIcon /> : null}
          <Dict name="casino" as="span" section="common" />
        </Button>
      </Link>
      <Link href="/sportsbook">
        <Button
          variant={menuState === "sports" ? "ghost-orange" : "normal"}
          className={cn(
            "h-[38px] min-h-[38px] px-[18px] [&_svg]:size-8",
            buttonClasses(menuState === "sports"),
            menuState === "sports" ? "" : "border-none hover:!bg-white/5"
          )}
        >
          {menuState === "sports" ? <SportsNavBarIcon /> : null}
          <Dict name="sports" as="span" section="common" />
        </Button>
      </Link>
    </div>
  );
}

export function LoginRegisterButtons() {
  const pathname = usePathname();

  return (
    <div className="flex items-center gap-[6px]">
      <Link href={{ pathname, query: { modal: "auth", tab: "login" } }}>
        <Button variant="normal" className="h-[48px] !min-h-[48px] px-[18px]">
          <Dict name="login" as="span" section="common" />
        </Button>
      </Link>

      <Link href={{ pathname, query: { modal: "auth", tab: "register" } }}>
        <Button
          variant="simple-blue"
          className="h-[48px] !min-h-[48px] px-[18px]"
        >
          <Dict name="register" as="span" section="common" />
        </Button>
      </Link>
    </div>
  );
}

export function ChatHeader({ isHeader }: { isHeader?: boolean }) {
  const { langMap } = useFlags();
  const [lang, setLang] = useAtom(chatLanguageAtom);
  const setChatState = useSetAtom(chatOpenAtom);
  const { mutate: updatePreferences } = useUpdateUserPreferences();

  return (
    <div
      className={cn(
        "px-3 bg-[#12172f] border-l border-solid border-[#FFFFFF]/5 flex items-center justify-between h-full",
        !isHeader ? "2lg:hidden flex h-[115px]" : ""
      )}
    >
      <Select value={lang} onValueChange={setLang}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select a fruit" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.keys(langMap).map((key, i) => {
              return (
                <SelectItem key={i} value={key}>
                  <div className="flex items-center gap-2 cursor-pointer">
                    <Image
                      alt="flag"
                      src={langMap[key].src}
                      width={16}
                      height={16}
                      loading="lazy"
                    />
                    <span>{langMap[key].text}</span>
                  </div>
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Button
        variant="dark"
        size="sm"
        className="rounded-full"
        onClick={() => {
          setChatState(false);
          setCookie(null, "chatOpen", String(false), {
            path: "/",
            maxAge: 60 * 60 * 24 * 365,
            sameSite: "lax",
          });
          updatePreferences({
            chat_open: false,
          });
        }}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z"
            fill="white"
          />
        </svg>
      </Button>
    </div>
  );
}

export function BalanceWallet() {
  const pathname = usePathname();

  return (
    <>
      <BalanceDropdown />
      <Link href={{ pathname, query: { modal: "wallet", tab: "deposit" } }}>
        <Button
          variant="simple-green"
          className={cn(
            "h-[48px] min-h-[48px] min-w-[48px] px-[18px] [@media(max-width:767px)]:px-0 [@media(max-width:767px)]:rounded-l-none "
          )}
        >
          <WalletIcon />
          <Dict
            name="wallet"
            as="span"
            section="common"
            className="[@media(max-width:767px)]:hidden 1100-1320:hidden block"
          />
        </Button>
      </Link>
    </>
  );
}
