import { NewLogo, NewLogoIcon } from "@assets/icons/general/NewLogo";
import Link from "next/link";
import useToken from "@hooks/useToken";
import { cn, timeLeft } from "@lib";
import {
  BalanceWallet,
  CasinoSportsButtons,
  ChatButton,
  ChatHeader,
  LoginRegisterButtons,
  MenuButton,
  SearchButton,
} from ".";
import RewardsDropdown from "./rewards-dropdown";
import { RewardsData } from "@/types/rewards";
import useNow from "@hooks/useNow";
import RakebackBoost from "@components/rakeback-boost";
import { activeRewardsDropdownAtom } from "@store/rewards-dropdown-atom";
import { useAtomValue } from "jotai";
import NotificationDropdown from "./notification-dropdown";
import ProfileDropdown from "./profile-dropdown";
import { miniSideAtom } from "@store/global";
import { chatOpenAtom } from "@store/global";

interface HeaderContentProps {
  isRakeBoostActive: boolean;
  boostProgress: number;
  rewardsData: RewardsData;
  getRewardsData: () => void;
}

export function HeaderContent({
  isRakeBoostActive,
  boostProgress,
  rewardsData,
  getRewardsData,
}: HeaderContentProps) {
  const token = useToken();
  const now = useNow();
  const activeRewardsDropdown = useAtomValue(activeRewardsDropdownAtom);
  const chatOpen = useAtomValue(chatOpenAtom);
  const miniSide = useAtomValue(miniSideAtom);

  return (
    <div className="flex h-[68px]">
      {/* Sidebar - Only visible on desktop */}
      <div
        className={cn(
          "hidden 2lg:flex items-center justify-center gap-2 border-b border-r border-solid border-[#E8E5FF]/[0.05] transition-[width] duration-50 ease-in-out",
          miniSide ? "w-[80px]" : "w-[250px]"
        )}
      >
        <MenuButton />
        {miniSide ? null : <CasinoSportsButtons />}
      </div>

      <div className="flex-1 border-b border-solid border-[#E8E5FF]/[0.05]">
        <div className="max-w-[1200px] mx-auto h-full flex justify-between items-center px-4 gap-2">
          {/* Logo + Balance section */}
          <div className="flex items-center space-x-[10px] [@media(max-width:767px)]:space-x-2">
            <Link href="/">
              <NewLogoIcon
                height={42}
                className={cn(
                  "[@media(max-width:450px)]:block hidden",
                  chatOpen && !miniSide && "1100-1320:block"
                )}
              />
              <NewLogo
                height={42}
                className={cn(
                  "[@media(max-width:450px)]:hidden",
                  chatOpen && !miniSide ? "1100-1320:hidden block" : "block"
                )}
              />
            </Link>

            {token && (
              <div className="flex items-center [@media(max-width:767px)]:gap-[0px] gap-[6px] 2lg:hidden">
                <BalanceWallet />
              </div>
            )}
          </div>

          {token && (
            <div className="items-center gap-[6px] hidden 2lg:flex">
              <BalanceWallet />
            </div>
          )}

          {/* Right side section */}
          <div className="flex items-center">
            {token ? (
              <div className="flex items-center space-x-[10px] [@media(max-width:450px)]:space-x-0">
                {isRakeBoostActive && (
                  <RakebackBoost
                    rakebackRate={rewardsData?.rakeback_boost?.rate}
                    boostType={rewardsData?.rakeback_boost?.type}
                    progress={boostProgress}
                    leftTime={timeLeft(
                      now,
                      rewardsData?.rakeback_boost?.expires_at?.replace("Z", "")
                    )}
                    strokeWidth={1.5}
                    noHover={activeRewardsDropdown}
                    header={true}
                  />
                )}

                <SearchButton />
                <div className="flex items-center [@media(min-width:450px)]:border [@media(min-width:450px)]:border-[#33395C] [@media(min-width:450px)]:border-solid [@media(min-width:450px)]:rounded-md [@media(min-width:450px)]:overflow-hidden">
                  <RewardsDropdown
                    rewardsData={rewardsData}
                    getRewardsData={getRewardsData}
                    isRakeBoostActive={isRakeBoostActive}
                    boostProgress={boostProgress}
                  />
                  <NotificationDropdown />
                  <ProfileDropdown />
                </div>
                <ChatButton />
              </div>
            ) : (
              <div className="flex items-center space-x-[10px]">
                <SearchButton />
                <RewardsDropdown
                  rewardsData={rewardsData}
                  getRewardsData={getRewardsData}
                  isRakeBoostActive={isRakeBoostActive}
                  boostProgress={boostProgress}
                />
                <LoginRegisterButtons />
                <ChatButton />
              </div>
            )}
          </div>
        </div>
      </div>
      {chatOpen && (
        <div className="hidden 2lg:block w-[310px]">
          <ChatHeader isHeader={true} />
        </div>
      )}
    </div>
  );
}
