import { gameImageMap } from "../../pages/casino/originals/[game]";

interface IinHouseGames {
  id: number;
  name: string;
  url: string;
  icon: string;
}

export const inHouseGames: IinHouseGames[] = [
  
  {
    id: 9,
    name: "Mines",
    url: "mines-game",
    icon: gameImageMap["mines"],
  },
  {
    id: 10,
    name: "Keno",
    url: "keno",
    icon: gameImageMap["keno"],
  },
  {
    id: 3,
    name: "Dice",
    url: "dice",
    icon: gameImageMap["dice"],
  },
  {
    id: 4,
    name: "Plinko",
    url: "plinko",
    icon: gameImageMap["plinko"],
  },
  {
    id: 8,
    name: "Case Battles",
    url: "case-battles",
    icon: gameImageMap["case-battles"],
  },
  {
    id: 1,
    name: "Case Open",
    url: "case-opening",
    icon: gameImageMap["case-opening"],
  },
];
