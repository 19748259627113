import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { differenceInMilliseconds } from "date-fns";
import Cookies from "js-cookie";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function removeDateOffset(date: Date | string | number) {
  const d = new Date(date);
  return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
}

const MS_PER_SECOND = 1000;
const MS_PER_MINUTE = MS_PER_SECOND * 60;
const MS_PER_HOUR = MS_PER_MINUTE * 60;
const MS_PER_DAY = MS_PER_HOUR * 24;

interface TimeRemaining {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const isCustomDateFormat = (dateString: string) => {
  const customFormatRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return customFormatRegex.test(dateString);
};

export const timeLeft = (
  dstart: Date | number | string,
  dend: Date | number | string | null
): TimeRemaining => {
  let convertedDend = dend;

  // Replace the space with 'T' for ISO 8601 compatibility
  if (typeof dend === "string" && isCustomDateFormat(dend)) {
    convertedDend = dend.replace(" ", "T");
  }

  if (!convertedDend) {
    return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const timeDiff = differenceInMilliseconds(convertedDend, dstart);

  const time: TimeRemaining = {
    total: Math.max(timeDiff, 0),
    days: Math.floor(timeDiff / MS_PER_DAY),
    hours: Math.floor((timeDiff % MS_PER_DAY) / MS_PER_HOUR),
    minutes: Math.floor((timeDiff % MS_PER_HOUR) / MS_PER_MINUTE),
    seconds: Math.floor((timeDiff % MS_PER_MINUTE) / MS_PER_SECOND),
  };

  return {
    total: Number.isNaN(time.total) ? 0 : Math.max(timeDiff, 0),
    days: Number.isNaN(time.days) ? 0 : Math.max(0, time.days),
    hours: Number.isNaN(time.hours) ? 0 : Math.max(0, time.hours),
    minutes: Number.isNaN(time.minutes) ? 0 : Math.max(0, time.minutes),
    seconds: Number.isNaN(time.seconds) ? 0 : Math.max(0, time.seconds),
  };
};

export const getTimeRemaining = (
  currentTime: Date | number,
  utcDate: Date | string | number
): string => {
  const timeRemaining = timeLeft(currentTime, utcDate);

  if (!timeRemaining) return "";

  const { days, hours, minutes, seconds } = timeRemaining;

  const timeFormats = [
    { condition: days >= 1, format: () => `${days}d ${hours}h ${minutes}m` },
    { condition: hours >= 1, format: () => `${hours}h ${minutes}m` },
    { condition: minutes >= 7, format: () => `${minutes}m` },
    { condition: minutes >= 1, format: () => `${minutes}m ${seconds}s` },
    { condition: true, format: () => `${seconds}s` },
  ];

  return timeFormats.find(({ condition }) => condition)?.format() || "";
};
