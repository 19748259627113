import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/new-ui/popover";
import useLanguage from "@hooks/useLanguage";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import useWallet from "@hooks/useWallet";
import usePopup from "@hooks/usePopup";
import ToolTip from "@items/ToolTip";
import { NotificationIcon } from "../icons";
import { useRouter } from "next/router";
import { differenceInMinutes } from "date-fns";
import { format } from "date-fns";
import { fromUnixTime } from "date-fns";
import { LoadingSimpleSpinner } from "@components/Loading/LoadingSimpleSpinner";
import { Button, buttonVariants } from "@components/new-ui/button";
import useSocket from "@hooks/useSocket";
import CurrencyIcons from "@items/CurrencyIcons";
import ValueDisplay from "@items/ValueDisplay";
import NotificationsIcons from "./notification-dropdown-icons";

export default function NotificationDropdown() {
  const socket = useSocket(process.env.NEXT_PUBLIC_ALERTS_SOCKET_URL);
  const L = useLanguage(["NotificationDropdown"]);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const [dropdownState, setDropdownState] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blipActive, setBlipActive] = useState(false);
  const setMessage = usePopup();
  const { balanceMutate } = useWallet();
  const refAudio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    refAudio.current = new Audio("/audios/notification.mp3");
  }, []);

  //Used to trigger a mutate on the front to update the users balance
  const currentQueryRef = useRef(router.query);

  useEffect(() => {
    currentQueryRef.current = router.query;
  }, [router]);

  const closeModalIfPixPixpay = useCallback(() => {
    if (
      currentQueryRef.current?.modal === "wallet" &&
      currentQueryRef.current?.tab === "deposit" &&
      (currentQueryRef.current?.method === "pix" ||
        currentQueryRef.current?.method === "picpay")
    ) {
      router.replace(router.asPath.split("?")[0], undefined, {
        shallow: true,
      });
    }
  }, [router]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit("open-alert", []);
      });

      socket.on("all-notifications", (data) => {
        const filteredDeletedNotifications = data.filter(
          (notification) => notification.deleted == !1
        );

        if (data.length === 0) {
          setLoading(false);
        } else {
          setNotificationData(filteredDeletedNotifications);
          setLoading(false);
        }
      });

      socket.on("new-notification", (data) => {
        const blip = data?.blip;
        const read = data?.read;
        const replacements = data?.replacements;
        const title = data?.title;
        const relationId = data?.relation_id;

        const isFiatDeposit = title.includes("fiat_deposit");

        if (
          title === "notif_crypto_deposit_confirmed" &&
          replacements &&
          replacements?.TOKEN_AMOUNT
        ) {
          const tokenAmount = replacements?.TOKEN_AMOUNT;
          (window as any).gtag("event", "conversion", {
            send_to: process.env.NEXT_PUBLIC_GTAG_EVENT_ID,
            value: tokenAmount,
            currency: "USD",
            transaction_id: relationId,
          });
        }

        // Update to prevent duplicates
        setNotificationData((prevNotifications) => {
          // Check if notification already exists
          const exists = prevNotifications.some(
            (notification) => notification.id === data.id
          );

          if (exists) {
            return prevNotifications;
          }
          return [data, ...prevNotifications];
        });

        if (blip !== 0 && read === 0) {
          const isSpecialNotification =
            data.title === "keys_received" ||
            data.title === "raffle_tickets_earned";

          setMessage({
            code: "NotificationDropdown." + data.description,
            replacements: data.replacements,
            raw: isFiatDeposit,
            type: 1,
            fixedDuration: isSpecialNotification ? 1000 : null,
          });
        }
        if (
          data.title !== "keys_received" &&
          data.title !== "raffle_tickets_earned"
        ) {
          refAudio.current.play();
          balanceMutate();
        }
        //Closes the modal if the user is on the pix or pixpay modal
        if (data.title === "notif_fiat_deposit_complete") {
          closeModalIfPixPixpay();
        }

        setBlipActive(true);
        setDropdownState(data);
      });

      socket.on("unread-notifications", (data) => {
        if (data) {
          setBlipActive(true);
        }
      });

      //ToDo later, find out if these will cleanup themselves and not create multiple connections
      return () => {
        socket.off("new-notification");
        socket.off("all-notifications");
      };
    }
  }, [closeModalIfPixPixpay, balanceMutate, setMessage, socket]);

  const staticTime = (unixTimestamp) => {
    const date = fromUnixTime(unixTimestamp);
    return format(date, "MMM d, yyyy, h:mm a");
  };

  const relativeTime = (unixTimestamp) => {
    const now = new Date();
    const notificationTime = fromUnixTime(unixTimestamp);

    // Calculate the difference in minutes between now and the notification time
    const minutesDiff = differenceInMinutes(now, notificationTime);

    // Customize the output based on the time difference
    if (minutesDiff < 1) {
      return "Just now";
    } else {
      return formatDistanceToNow(notificationTime, { addSuffix: true });
    }
  };

  const openCloseDropdown = () => {
    socket.emit("open-alert", notificationData);

    if (dropdownState) {
      dropdownState.blip = 0;
    }

    setBlipActive(false);
    setIsOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const RenderDescription = useCallback(
    ({ description, notObj }) => {
      const notification = { ...notObj };

      const replaceWithJSX = (key) => {
        switch (key) {
          case "CURRENCY_AMOUNT":
          case "TOKEN_AMOUNT":
            const amount =
              notification.replacements?.["CURRENCY_AMOUNT"] ||
              notification.replacements?.[key];
            if (!amount) return null;

            return (
              <span className="">
                <ValueDisplay
                  amount={amount}
                  currencyCode={notification.replacements?.CURRENCY}
                  raw={notification.description.includes("fiat_deposit")}
                />
              </span>
            );

          case "USER_NAME":
            return (
              <span className="">
                {notification.replacements?.[key] || "user"}
              </span>
            );

          case "CRYPTO_AMOUNT":
            const code = notification.replacements?.["CRYPTO_CODE"];
            if (!code) return null;

            return (
              <span className="">
                <span>
                  <CurrencyIcons code={code?.toLowerCase()} size={"small"} />
                </span>

                {notification.replacements?.[key]}
              </span>
            );

          default:
            return notification.replacements[key];
        }
      };

      let additions = {};

      // Define the keys you want to replace with JSX
      const keysToReplaceWithJSX = [
        "CURRENCY_AMOUNT",
        "TOKEN_AMOUNT",
        "CRYPTO_AMOUNT",
        "USER_NAME",
      ];

      keysToReplaceWithJSX.forEach((key) => {
        additions = { ...additions, [key]: replaceWithJSX(key) };
      });

      const replacements = notification.replacements;

      const objsent = { ...replacements, ...additions };
      return L(description, objsent);
    },
    [L]
  );

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          openCloseDropdown();
        }
        setIsOpen(open);
      }}
    >
      <PopoverTrigger className="relative">
        <div
          className={buttonVariants({
            variant: "dark",
            className:
              "w-[48px] h-[48px] !min-h-[48px] !px-0 !py-0 [&_svg]:size-5 relative border-none rounded-none [@media(max-width:450px)]:bg-transparent [@media(max-width:450px)]:px-0 [@media(max-width:450px)]:w-[38px] [@media(max-width:450px)]:hover:!bg-transparent",
          })}
          aria-selected={isOpen}
        >
          <NotificationIcon />
        </div>
        {blipActive ? (
          <span className="absolute top-2.5 right-2.5 min-w-[8px] h-[8px] flex items-center justify-center text-[10px] rounded-full border border-solid border-white/50 bg-gradient-to-b from-[#7DD934] to-[#7DD934] text-white shadow-[0px_0px_12px_0px_rgba(0,119,219,0.5),0px_0px_6px_0px_rgba(0,0,0,0.3)] transition-all duration-300 ease animate-ping" />
        ) : null}
      </PopoverTrigger>

      <PopoverContent
        align="end"
        className="grid gap-4 w-[415px] [@media(max-width:480px)]:w-[330px] shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2"
      >
        <div className="flex flex-col overflow-y-auto gap-[6px] max-h-[400px]">
          {loading ? (
            <div className="flex items-center justify-center min-h-[100px]">
              <LoadingSimpleSpinner className="w-5 h-5" />
            </div>
          ) : notificationData.length === 0 ? (
            <div className="flex items-center justify-center min-h-[100px]">
              <h4 className="text-sm text-[#7078a3]">
                {L("no_notifications", {})}
              </h4>
            </div>
          ) : (
            notificationData?.map((notification) => (
              <div
                className="pl-4 pr-3 py-3.5 bg-gradient-to-b flex items-center gap-[14px] from-[#7b83b1]/15 to-[#5e679e]/15 rounded-md border border-solid border-[#7b83b1]/15"
                key={notification.id}
              >
                <NotificationsIcons notification={notification} />

                <div className="flex flex-col gap-[8px]">
                  <div className="flex items-center gap-2">
                    <h4 className="text-[13px] font-medium text-white">
                      {notification.title === "keys_received"
                        ? L(notification.title, {
                            TYPE: notification.replacements.TYPE,
                          })
                        : L(notification.title, {})}
                    </h4>
                    {notification.read === 0 ? (
                      <div className="text-[#db9a4e] text-[9px] bg-[rgba(219,154,78,0.25)] px-[7px] h-[15px] flex items-center justify-center rounded-[3px]">
                        {L("new", {})}
                      </div>
                    ) : null}
                  </div>

                  <div className="text-[13px] text-[#E8E5FF]/75">
                    <RenderDescription
                      description={notification.description}
                      notObj={notification}
                    />
                  </div>

                  <div className="flex">
                    <ToolTip
                      placement="top"
                      text={staticTime(notification.time)}
                      size="small"
                      transparent
                    >
                      <span className="text-[12px] text-[#6971A6]">
                        {relativeTime(notification.time)}
                      </span>
                    </ToolTip>
                  </div>
                </div>

                {notification?.url ? (
                  notification.title === "keys_received" ? (
                    <div className="ml-auto">
                      <Button
                        variant="normal"
                        size="sm"
                        onClick={() => {
                          setIsOpen(false);
                          router.push(notification.url);
                        }}
                      >
                        Open Case
                      </Button>
                    </div>
                  ) : (
                    <div className="ml-auto">
                      <Link href={notification?.url} legacyBehavior>
                        <Button
                          variant="normal"
                          size="sm"
                          onClick={() => setIsOpen(false)}
                        >
                          Open
                        </Button>
                      </Link>
                    </div>
                  )
                ) : null}
              </div>
            ))
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
