import React, { memo, useCallback } from "react";
import Link from "next/link";
import Image from "next/image";
import { WeeklyIcon } from "@assets/svgs/WeeklyIcon";
import useLanguage from "../hooks/useLanguage";
import useWallet from "../hooks/useWallet";
import outline from "../assets/Images/laurel_outline.png";
import bg from "../assets/icons/races_cta_bg.png";
import st from "../styles/components/RacesCTA.module.scss";
import useNow from "@hooks/useNow";
import {
  addMonths,
  nextSaturday,
  setHours,
  setMinutes,
  setSeconds,
  startOfMonth,
} from "date-fns";
import { timeLeft } from "@lib";
import { useAtomValue } from "jotai";
import { racesAtom } from "@store/global";

const RacesCTA = ({
  type,
  mini,
}: {
  type: "weekly" | "monthly";
  mini?: boolean;
}) => {
  const L = useLanguage(["races", "Rewards"]);
  const { activeRate } = useWallet();
  const now = useNow();
  const data = useAtomValue(racesAtom);

  const prizeWeekly = +data?.weekly?.total_rewards / 1000;
  const prizeMonthly = +data?.monthly?.total_rewards / 1000;
  const prizeTextWeekly = `${activeRate.display.prepend}${Math.floor(prizeWeekly)}K`;
  const prizeTextMonthly = `${activeRate.display.prepend}${Math.floor(prizeMonthly)}K`;

  const img = mini ? (
    <>
      <Image src={outline.src} alt="Golden Laurel Crown" fill sizes="100px" />
      <Image src={bg.src} alt="Golden Laurel Crown" fill sizes="100px" />
    </>
  ) : (
    <WeeklyIcon />
  );

  const timeLeftWeekly = timeLeft(
    now,
    setHours(setMinutes(setSeconds(nextSaturday(now), 0), 0), 0)
  );
  const timeLeftMonthly = timeLeft(now, startOfMonth(addMonths(now, 1)));

  const formatTime = useCallback(
    (time) => {
      if (time.days > 0) {
        return `${time.days} ${time.days === 1 ? L("day", {}) : L("days", {})}`;
      } else {
        const hours = String(time.hours).padStart(2, "0");
        const minutes = String(time.minutes).padStart(2, "0");
        const seconds = String(time.seconds).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      }
    },
    [L]
  );

  return (
    <Link
      className={st["cta"]}
      href={type === "weekly" ? `/weekly-race` : `/monthly-race`}
    >
      <div className={st.img}>{img}</div>
      <div className={st.container_text}>
        <span className={st.number}>
          {type === "weekly" ? prizeTextWeekly : prizeTextMonthly}
        </span>
        <div className={st.text}>
          {type === "weekly" ? L(`weekly_race`, {}) : L(`monthly_race`, {})}
        </div>
      </div>

      {type === "weekly"
        ? timeLeftWeekly.total > 0 && (
            <div className={st.countdown_container}>
              <p className={st.countdown}>{formatTime(timeLeftWeekly)}</p>
            </div>
          )
        : timeLeftMonthly.total > 0 && (
            <div className={st.countdown_container}>
              <p className={st.countdown}>{formatTime(timeLeftMonthly)}</p>
            </div>
          )}
    </Link>
  );
};

export default memo(RacesCTA);
