import { useId } from "react";
import st from "@styles/components/Logo.module.scss";
import { classnames } from "@lib/tools/helpers";
import { SvgRainbetLogo } from "@components/Svg/SvgStore";

const Gold = (p) => {
  const uniqueID0 = useId();
  const uniqueID1 = useId();
  const uniquePathID0 = useId();
  const uniquePathID1 = useId();

  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        id={uniquePathID0}
        d="M15.5914 0.182032L0.0580473 23.5594C0.023884 23.6108 0.00429309 23.6706 0.00136183 23.7322C-0.00156942 23.7939 0.0122689 23.8552 0.0414022 23.9097C0.0705355 23.9641 0.113872 24.0096 0.166796 24.0413C0.219719 24.073 0.280247 24.0898 0.34193 24.0898L3.32979 24.0898C3.47749 24.0911 3.61973 24.1459 3.73006 24.2442C3.8404 24.3425 3.91132 24.4776 3.92966 24.6243C4.23523 26.7886 5.12072 28.8296 6.49208 30.5306C7.86343 32.2315 9.66943 33.5288 11.7182 34.2846C11.9362 34.3636 12.1235 34.5097 12.2532 34.702L15.6733 39.8477C15.7045 39.8945 15.7467 39.9329 15.7962 39.9595C15.8457 39.986 15.9011 39.9999 15.9572 39.9999C16.0134 39.9999 16.0687 39.986 16.1183 39.9594C16.1678 39.9329 16.21 39.8944 16.2411 39.8476L19.6531 34.712C19.7748 34.5279 19.9494 34.3851 20.1539 34.3026C20.7937 34.0523 21.4169 33.7618 22.02 33.4327C23.6081 32.5097 24.9657 31.2371 25.99 29.7112C27.0142 28.1853 27.6783 26.4462 27.9319 24.6254C27.9503 24.4784 28.0214 24.343 28.132 24.2445C28.2426 24.146 28.3852 24.091 28.5333 24.0898H31.5198C31.5814 24.0898 31.642 24.0731 31.6949 24.0414C31.7478 24.0096 31.7912 23.9642 31.8203 23.9097C31.8494 23.8553 31.8633 23.794 31.8603 23.7323C31.8574 23.6706 31.8378 23.6109 31.8037 23.5595L16.2703 0.182093C16.2331 0.126095 16.1826 0.08017 16.1234 0.0484014C16.0642 0.0166328 15.998 5.99979e-06 15.9309 1.62348e-09C15.8637 -5.99654e-06 15.7975 0.0166089 15.7383 0.0483669C15.6791 0.0801249 15.6287 0.126042 15.5914 0.182032Z"
        fill={`url(#${uniqueID0})`}
      />
      <path
        id={uniquePathID1}
        d="M22.5103 18.8209L15.9296 8.48242L9.34963 18.8209C8.59793 20.0017 8.17709 21.363 8.13116 22.7625C8.08523 24.1619 8.4159 25.5479 9.08857 26.7756C9.76123 28.0032 10.7511 29.0272 11.9547 29.7406C13.1583 30.4539 14.5313 30.8302 15.93 30.8302C17.3287 30.8302 18.7017 30.4539 19.9053 29.7406C21.1088 29.0272 22.0987 28.0032 22.7714 26.7756C23.4441 25.5479 23.7747 24.1619 23.7288 22.7625C23.6829 21.363 23.262 20.0017 22.5103 18.8209Z"
        fill={`url(#${uniqueID1})`}
      />
      <defs>
        <linearGradient
          id={uniqueID0}
          x1="15.9309"
          y1="-3.43413e-05"
          x2="15.9309"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.10069" stopColor="#FFB93F" />
          <stop offset="1" stopColor="#FFA200" />
        </linearGradient>
        <linearGradient
          id={uniqueID1}
          x1="15.93"
          y1="30.8309"
          x2="15.93"
          y2="8.48242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.37014" stopColor="white" />
          <stop offset="1" stopColor="#FFBC70" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Blue = (p) => {
  const uniqueID0 = useId();
  const uniqueID1 = useId();
  const uniquePathID0 = useId();
  const uniquePathID1 = useId();

  return (
    <svg
      width="40"
      height="50"
      viewBox="0 0 40 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        id={uniquePathID0}
        d="M19.4905 0.22754L0.0737798 29.4493C0.0310757 29.5135 0.00658706 29.5882 0.00292299 29.6653C-0.000741077 29.7424 0.0165568 29.819 0.0529734 29.8871C0.0893901 29.9551 0.143561 30.012 0.209716 30.0516C0.27587 30.0913 0.35153 30.1122 0.428633 30.1122L4.16345 30.1123C4.34808 30.1138 4.52588 30.1824 4.6638 30.3053C4.80172 30.4282 4.89037 30.597 4.91329 30.7804C5.29525 33.4858 6.40212 36.037 8.11632 38.1632C9.83051 40.2893 12.088 41.9109 14.649 42.8558C14.9214 42.9545 15.1556 43.1371 15.3178 43.3774L19.5929 49.8096C19.6318 49.8681 19.6846 49.9161 19.7465 49.9493C19.8084 49.9825 19.8775 49.9999 19.9478 49.9999C20.018 49.9999 20.0872 49.9825 20.1491 49.9493C20.211 49.9161 20.2637 49.868 20.3026 49.8095L24.5677 43.39C24.7197 43.1598 24.938 42.9814 25.1936 42.8782C25.9933 42.5654 26.7724 42.2022 27.5262 41.7909C29.5114 40.6371 31.2084 39.0463 32.4887 37.139C33.769 35.2316 34.5991 33.0577 34.9161 30.7818C34.9391 30.598 35.028 30.4288 35.1663 30.3056C35.3045 30.1825 35.4827 30.1138 35.6678 30.1123H39.4009C39.478 30.1123 39.5537 30.0913 39.6198 30.0517C39.686 30.0121 39.7402 29.9552 39.7766 29.8872C39.813 29.8191 39.8303 29.7425 39.8266 29.6654C39.823 29.5883 39.7985 29.5136 39.7558 29.4494L20.339 0.227616C20.2925 0.157619 20.2295 0.100212 20.1555 0.0605017C20.0814 0.020791 19.9988 7.49973e-06 19.9148 2.02934e-09C19.8308 -7.49568e-06 19.7482 0.0207611 19.6741 0.0604586C19.6001 0.100156 19.537 0.157552 19.4905 0.22754Z"
        fill={`url(#${uniqueID0})`}
      />
      <path
        id={uniquePathID1}
        d="M28.1399 23.5247L19.914 10.6016L11.689 23.5247C10.7494 25.0007 10.2233 26.7023 10.1659 28.4516C10.1085 30.2009 10.5218 31.9334 11.3627 33.468C12.2035 35.0025 13.4409 36.2826 14.9454 37.1742C16.4498 38.0659 18.1661 38.5363 19.9144 38.5363C21.6628 38.5363 23.379 38.0659 24.8835 37.1742C26.388 36.2826 27.6254 35.0025 28.4662 33.468C29.307 31.9334 29.7204 30.2009 29.663 28.4516C29.6056 26.7023 29.0795 25.0007 28.1399 23.5247Z"
        fill={`url(#${uniqueID1})`}
      />
      <defs>
        <linearGradient
          id={uniqueID0}
          x1="19.9148"
          y1="-4.29266e-05"
          x2="19.9148"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.10069" stopColor="#3F51FF" />
          <stop offset="1" stopColor="#00D1FF" />
        </linearGradient>
        <linearGradient
          id={uniqueID1}
          x1="19.9144"
          y1="38.5372"
          x2="19.9144"
          y2="10.6016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.37014" stopColor="white" />
          <stop offset="1" stopColor="#70D9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Text = (p) => (
  <svg
    width="116"
    height="26"
    viewBox="0 0 116 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...p}
  >
    <path
      d="M14.2895 24.9565L9.66481 16.9777H4.66487V24.9565H0.138672V1.9727H10.8782C11.88 1.96913 12.8727 2.16348 13.7994 2.54461C14.726 2.92575 15.5685 3.48618 16.2784 4.19379C16.9884 4.90141 17.5518 5.7423 17.9366 6.66828C18.3213 7.59427 18.5198 8.58716 18.5206 9.59005C18.4966 11.0128 18.067 12.3991 17.2826 13.5857C16.4981 14.7723 15.3913 15.7099 14.0925 16.2881L19.1766 24.9564L14.2895 24.9565ZM4.66487 6.20831V13.0049H10.8782C11.7413 12.9591 12.5519 12.5752 13.1348 11.9364C13.7178 11.2975 14.0264 10.4547 13.9941 9.59005C14.0255 8.72888 13.7155 7.89022 13.1319 7.25679C12.5483 6.62336 11.7382 6.24649 10.8782 6.20831H4.66487Z"
      fill="white"
    />
    <path
      d="M33.6022 8.54072H37.8333V24.9578H33.6022V23.0207C32.9647 23.8159 32.1474 24.4479 31.2178 24.8647C30.2882 25.2814 29.273 25.4709 28.2559 25.4175C27.192 25.4289 26.1381 25.2122 25.1649 24.7817C24.1918 24.3513 23.322 23.7172 22.6141 22.9221C21.0678 21.2446 20.2329 19.0314 20.2855 16.7494C20.233 14.4772 21.0685 12.2741 22.6141 10.6092C23.3203 9.80983 24.189 9.17072 25.162 8.7347C26.135 8.29869 27.1898 8.07584 28.2559 8.08108C29.273 8.02771 30.2883 8.21723 31.2179 8.63401C32.1474 9.05079 32.9647 9.68288 33.6022 10.4781V8.54072ZM29.0429 21.3789C29.6428 21.3986 30.2404 21.2951 30.7988 21.0748C31.3573 20.8546 31.8648 20.5222 32.2901 20.0983C32.7257 19.6594 33.0669 19.1359 33.2926 18.5599C33.5183 17.9839 33.6236 17.3677 33.6022 16.7494C33.6267 16.1356 33.5226 15.5234 33.2966 14.9523C33.0707 14.3811 32.7278 13.8636 32.2901 13.4331C31.4187 12.5905 30.2544 12.1196 29.0429 12.1196C27.8314 12.1196 26.6671 12.5905 25.7957 13.4331C25.3689 13.8701 25.0354 14.3894 24.8156 14.9596C24.5957 15.5297 24.494 16.1387 24.5167 16.7494C24.497 17.3647 24.6 17.9777 24.8196 18.5527C25.0392 19.1277 25.371 19.6531 25.7957 20.0983C26.2211 20.5222 26.7286 20.8545 27.287 21.0747C27.8454 21.295 28.443 21.3985 29.0429 21.3789Z"
      fill="white"
    />
    <path
      d="M40.8823 2.18464C41.0418 1.81556 41.2849 1.48873 41.5925 1.23007C41.9001 0.971398 42.2636 0.787999 42.6543 0.694401C43.0132 0.608644 43.3863 0.600083 43.7488 0.669284C44.1113 0.738485 44.455 0.883874 44.7573 1.09585C45.0595 1.30783 45.3134 1.58158 45.5022 1.89902C45.691 2.21646 45.8104 2.57038 45.8524 2.9374C45.8945 3.30442 45.8583 3.67621 45.7463 4.02821C45.6343 4.38021 45.449 4.70442 45.2025 4.97944C44.9561 5.25446 44.6543 5.47402 44.3168 5.62364C43.9794 5.77326 43.6141 5.84953 43.2451 5.84742C42.813 5.84436 42.3885 5.73374 42.0098 5.52553C41.6311 5.31731 41.3101 5.01805 41.0757 4.65471C40.8413 4.29136 40.7009 3.87534 40.6671 3.44414C40.6334 3.01294 40.7073 2.58011 40.8823 2.18464Z"
      fill="white"
    />
    <path d="M45.3766 8.53906H41.1455V24.9562H45.3766V8.53906Z" fill="white" />
    <path
      d="M82.3101 10.6078C83.8786 12.2607 84.7279 14.469 84.6718 16.748C84.728 19.0368 83.8793 21.2551 82.3101 22.9207C81.6044 23.7184 80.7351 24.3542 79.7616 24.7849C78.788 25.2155 77.733 25.4308 76.6687 25.4161C75.6559 25.4729 74.6443 25.2848 73.7194 24.8677C72.7945 24.4505 71.9836 23.8165 71.3552 23.0193V24.9564H67.124V1.97266H71.3552V10.4767C71.9836 9.6795 72.7945 9.04547 73.7194 8.62824C74.6442 8.211 75.6559 8.02285 76.6687 8.07968C77.7352 8.07112 78.791 8.29248 79.7645 8.72872C80.7379 9.16496 81.6061 9.80585 82.3101 10.6078ZM75.8817 21.3776C76.4815 21.3971 77.0791 21.2936 77.6374 21.0734C78.1958 20.8531 78.7033 20.5208 79.1286 20.0969C79.5643 19.6581 79.9055 19.1345 80.1312 18.5585C80.3569 17.9825 80.4622 17.3663 80.4407 16.748C80.4652 16.1342 80.3612 15.522 80.1352 14.9508C79.9092 14.3797 79.5663 13.8622 79.1286 13.4317C78.2572 12.5891 77.093 12.1182 75.8815 12.1182C74.67 12.1182 73.5059 12.5891 72.6345 13.4317C72.2076 13.8686 71.874 14.388 71.6541 14.9581C71.4342 15.5283 71.3325 16.1373 71.3552 16.748C71.3355 17.3633 71.4385 17.9764 71.6581 18.5514C71.8778 19.1264 72.2097 19.6518 72.6345 20.0969C73.0598 20.5208 73.5673 20.8542 74.1258 21.0747C74.6842 21.2937 75.2818 21.3972 75.8817 21.3776Z"
      fill="white"
    />
    <path
      d="M90.9687 18.4882C91.5263 20.5567 93.0678 21.5745 95.5937 21.5745C96.2927 21.6167 96.9915 21.4895 97.631 21.2037C98.2705 20.918 98.8317 20.4821 99.2672 19.9331L102.678 21.9031C101.886 23.0523 100.813 23.9788 99.5612 24.594C98.3092 25.2091 96.9205 25.4922 95.528 25.4162C94.3299 25.4724 93.1329 25.283 92.0105 24.8596C90.888 24.4361 89.8639 23.7876 89.0008 22.9538C88.1895 22.1422 87.5527 21.1729 87.1296 20.1057C86.7066 19.0385 86.5063 17.8959 86.541 16.7482C86.5111 15.6089 86.7106 14.4752 87.1276 13.4147C87.5445 12.3542 88.1704 11.3885 88.968 10.5752C89.7751 9.74675 90.7467 9.09673 91.82 8.66696C92.8933 8.23719 94.0446 8.03719 95.1998 8.07985C96.3104 8.04422 97.4156 8.24868 98.4401 8.6793C99.4646 9.10991 100.384 9.75656 101.137 10.5752C101.916 11.3983 102.526 12.3672 102.931 13.4264C103.336 14.4856 103.529 15.6144 103.498 16.7482C103.489 17.3316 103.435 17.9134 103.334 18.4882L90.9687 18.4882ZM99.2672 15.205C99.1093 14.251 98.6088 13.3876 97.8599 12.777C97.1109 12.1663 96.165 11.8504 95.1998 11.8887C94.2025 11.8203 93.2148 12.1199 92.4232 12.7309C91.6315 13.342 91.0909 14.2221 90.9034 15.205H99.2672Z"
      fill="white"
    />
    <path
      d="M111.755 4.74609H107.524V8.54048H104.67V12.6121H107.524V19.4413C107.524 23.9397 109.656 25.6143 115.461 24.9575V21.1159C113.034 21.2473 111.755 21.2144 111.755 19.4413V12.6121H115.461V8.54048H111.755V4.74609Z"
      fill="white"
    />
    <path
      d="M48.3867 15.1883V24.9569H52.6179V15.7136C52.6179 11.0374 59.9339 11.0037 59.9339 15.7136V24.9569H64.1651V15.1883C64.1651 5.61031 48.3867 5.58708 48.3867 15.1883Z"
      fill="white"
    />
  </svg>
);

const defaultStyles = {
  logo: {},
  figure: {},
  text: {},
};

function Logo({
  gold = false,
  fullWidth = true,
  column = false,
  text = true,
  styles = defaultStyles,
  hasFixedWidth = false,
}) {
  const Figure = gold ? Gold : Blue;

  return (
    <div
      className={`${st.logo} ${column ? st["column-text"] : ""} ${fullWidth ? st["full-width"] : st["default-width"]}`}
      {...classnames(
        st,
        "logo",
        column && "column-text",
        fullWidth ? "full-width" : "default-width",
        hasFixedWidth && "fixed-width"
      )}
      style={styles.logo}
    >
      <SvgRainbetLogo display="block" />
    </div>
  );
}

export default Logo;
