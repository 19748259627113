import st from "@styles/components/IntercomCustomButton.module.scss";
import HeadsetIcon from "@assets/icons/general/HeadsetIcon";
import { show } from "@intercom/messenger-js-sdk";
import { chatOpenAtom } from "@store/global";
import { useAtomValue } from "jotai";

export default function IntercomCustomButton() {
  const chatOpen = useAtomValue(chatOpenAtom);
  const showIntercomChat = () => {
    show();
  };
  return (
    <div
      className={`${st["intercom-custom-button-container"]} ${
        chatOpen && st["chat-open"]
      }`}
      onClick={showIntercomChat}
    >
      <HeadsetIcon />
    </div>
  );
}
