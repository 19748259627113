export const createBrowserID = () =>
  import("@thumbmarkjs/thumbmarkjs").then((r) => r.getFingerprint());

const STORAGE_KEY = "device-id";

export const getBrowserID = async (): Promise<string> => {
  const cached = localStorage.getItem(STORAGE_KEY);
  const id = cached || (await createBrowserID());

  if (!cached) localStorage.setItem(STORAGE_KEY, id);

  return id;
};

export const getDeviceInfo = async () => {
  const [id, browser] = await Promise.all([
    getBrowserID(),
    import("bowser").then((r) => r.default.parse(window.navigator.userAgent)),
  ]);

  return {
    ...browser,
    id,
  };
};
