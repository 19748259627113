import Image from "next/image";
import InteractLogo from "../../Images/payments/interacLogo.png";
import InteractWithdraw from "../../Images/payments/interacWithdraw.png";

export function InteracLogoIcon(props) {
  return (
    <Image
      src={InteractLogo}
      width={40}
      height={40}
      alt="Interac"
      className="max-w-full h-auto"
      {...props}
    />
  );
}

export function InteracWithdrawIcon() {
  return (
    <Image
      src={InteractWithdraw}
      width={100}
      height={60}
      alt="Interac Withdraw"
      className="max-w-full h-auto object-contain object-center"
      priority
    />
  );
}

export default InteracLogoIcon;
