import { useSession, UseSessionOptions } from "next-auth/react";

function useToken<R extends boolean>(
  options?: UseSessionOptions<R>
): string | null {
  const session = useSession<R>(options);
  const token = session?.data?.user?.access_token;

  if (
    !token ||
    typeof token !== "string" ||
    !token.length ||
    session.status === "unauthenticated"
  )
    return null;

  return token;
}

export default useToken;
