import axios from "axios";
import axiosRetry from "axios-retry";
axios.defaults.withCredentials = true;

import TokenService from "../services/Token.Service";
import { REFRESH_TOKEN } from "../tools/customEvents";
import PubSub from "../pubsub";
import { debounce } from "../tools/helpers";
import { log } from "@lib/tools/logger";
import { logout } from "@hooks/useUserSession";

const debounce_refresh = debounce(() => {
  PubSub.publishSync(REFRESH_TOKEN.type, true);
}, 750);

const addTokenToHeader = (config) => {
  if ("Authorization" in config.headers) return config;

  try {
    const token = TokenService.getLocalAccessToken();
    if (!token.length) return config;
    config.headers["Authorization"] = `Bearer ${token}`;
    // config.headers["x-access-token"] = token; // for Node.js Express back-end
    return config;
  } catch (error) {
    delete config.headers["Authorization"];
    return config;
  }
};

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true,
});

const isServer = typeof window === "undefined";

axiosRetry(API, {
  retries: isServer ? 1 : 20,
  // onMaxRetryTimesExceeded: () => {},
  retryDelay: (retryCount) => {
    const time = 1000 * retryCount;
    log(`Retry #${retryCount} in ${time}ms`);

    return time;
  },
  retryCondition: (error) => {
    if (error?.response?.data?.error === "er_login_restricted") {
      logout();
      return false;
    }

    if (error.response?.status < 400) return false;

    if (error.response?.status === 401) {
      debounce_refresh();
      return true;
    }

    return false;
  },
  onRetry: async (retryCount, error, config) => {
    delete config.headers["Authorization"];
    addTokenToHeader(config);
  },
});

API.interceptors.request.use(addTokenToHeader, (er) => Promise.reject(er));

export default API;
export { default as NODE_API } from "./nodeApi";
