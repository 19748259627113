import st from "../styles/components/SlotsList.module.scss";

export const GhostSlot = ({ mini = false }) => {
  return (
    <div
      data-loading
      className={mini ? st["loading-card-mini"] : st["loading-card"]}
    ></div>
  );
};

const GhostSlots = ({ mini = false }) => {
  // Define the number of loading cards
  const loadingCardsCount = mini ? 8 : 6;

  // Create an array from the number and map over it to render divs
  return Array.from({ length: loadingCardsCount }, (_, index) => (
    <GhostSlot key={index} mini={mini} />
  ));
};

export default GhostSlots;
