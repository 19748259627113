import Arrow from "@assets/icons/general/arrow";
import Hidden from "@assets/icons/general/Hidden";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import { Button } from "@components/new-ui/button";
import RollingList from "@components/RollingList";
import { Dict } from "@hooks/useLanguage";
import { GameHistory, useNewGameHistory } from "@hooks/useSocket";
import { useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import GamesIcons from "@items/GamesIcons";
import RewardIcons, { RewardCode } from "@items/RewardIcons";
import ValueDisplay from "@items/ValueDisplay";
import { cn } from "@lib";
import { NODE_API } from "@lib/api/api";
import { useQueue } from "@lib/Queue";
import { round2Decimal } from "@lib/tools/helpers";
import clsx from "clsx";
import Link from "next/link";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";

type Room = "ALL" | "USER";
const rooms = {
  ALL: "ALL",
  USER: "USER",
} satisfies { [key in Room]: key };

const bets_url = "v1/game-history";
const fetcher = (opts = {}) =>
  NODE_API.post(bets_url, { take: 11, ...opts }).then((res) => res.data);

type RowProps = {
  children: [ReactNode, ReactNode, ReactNode, ReactNode, ReactNode];
  className?: string;
  header?: boolean;
};
const Row = ({ children, className, header }: RowProps) => {
  const [col1, col2, col3, col4, col5] = children;

  return (
    <div
      data-row={header ? "header" : "body"}
      className={cn(
        "flex justify-between",
        "p-4 rounded-md",
        "[&_*]:font-inherit [&>*]:text-normal",
        className
      )}
    >
      {/* game name */}
      <div className="w-1/5 min-w-28 justify-start [&_*]:text-inherit flex items-center leading-normal">
        {col1}
      </div>

      {/* user */}
      <div className="w-1/5 justify-center [&_*]:text-inherit hidden items-center @2xl/bets-feed:flex">
        {col2}
      </div>

      {/* bet amount */}
      <div className="w-1/5 justify-center [&_*]:text-inherit hidden items-center @lg/bets-feed:flex">
        {col3}
      </div>

      {/* multiplier */}
      <div className="w-1/5 justify-center [&_*]:text-inherit hidden items-center @sm/bets-feed:flex">
        {col4}
      </div>

      {/* payout */}
      <div
        className={cn(
          "w-1/5 justify-end flex items-center",
          "[&_*]:text-inherit"
        )}
      >
        {col5}
      </div>
    </div>
  );
};

const isWin = ({ multiplier }: GameHistory) => +multiplier > 1;

const LocalArrow = ({ isWin }: { isWin: boolean }) => {
  return (
    <div
      className={cn(
        "border border-solid rounded-[5px] relative w-6 h-6",
        isWin ? "border-[#ffb300]/50 bg-[#ffb300]/10" : "border-[#5B628C]"
      )}
      style={{
        ...(isWin && {
          boxShadow:
            "0px 0px 4px 0px #FFB300 inset, 0px 0px 4px 0px rgba(255, 179, 0, 0.25)",
        }),
      }}
    >
      <Arrow
        fill={isWin ? "#FFB300" : "#5B628C"}
        className={cn(
          "w-3",
          !isWin && "rotate-180",
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        )}
      />
    </div>
  );
};

const BetsFeed = () => {
  const [room, setRoom] = useState<Room>(rooms.ALL);
  const { walletBalanceList } = useWallet();
  const { publicId } = useUserSession();
  const { data: bets, mutate } = useSWR(
    [bets_url, room],
    ([, room]) =>
      fetcher(room === rooms.USER ? { public_id: publicId } : undefined),
    {
      revalidateOnFocus: false,
    }
  );

  const { queue, output, id } = useQueue<GameHistory>({
    initial: bets,
    limit: 10,
    resetsOnInitial: true,
  });

  const fn = useCallback(
    (item) => {
      if (room !== rooms.USER) {
        queue.add(item);
      }
    },
    [queue, room]
  );

  useNewGameHistory(fn);
  const betHistory = useMemo(() => output || [], [output]);

  useEffect(() => {
    if (room !== rooms.USER) return;

    mutate();
    // `walletBalanceList` is intentional here,
    // update "my bets" when wallet changes to keep up with the user's bets
  }, [walletBalanceList, room, mutate]);

  return (
    <div className="max-w-[1200px] mx-auto @container/bets-feed">
      {/* buttons */}
      <div className="p-1 bg-[#282D49] rounded-md inline-block border border-solid border-[#3A4169]/50">
        <Button
          variant={room === rooms.ALL ? "blue" : "none"}
          onClick={() => setRoom(rooms.ALL)}
          className={cn("px-5 py-3.5", room !== rooms.ALL && "text-[#5B628C]")}
        >
          <Dict
            section="common ResultFeed"
            name="all_bets"
            className="text-inherit"
          />
        </Button>
        <Button
          variant={room === rooms.USER ? "blue" : "none"}
          onClick={() => {
            if (!publicId) return;

            setRoom(rooms.USER);
          }}
          className={cn("px-5 py-3.5", room !== rooms.USER && "text-[#5B628C]")}
        >
          <Dict
            section="common ResultFeed"
            name="my_bets"
            className="text-inherit"
          />
        </Button>
      </div>

      <button
        onClick={() => {
          const getRandomOutput = () => {
            const randomIndex = Math.floor(Math.random() * output.length);
            return output[randomIndex];
          };
          const getNewResult = () =>
            JSON.parse(
              JSON.stringify({ ...getRandomOutput(), id: crypto.randomUUID() })
            );

          const items = Array.from({ length: 10 }, () => getNewResult());

          queue.add(...items);
        }}
      >
        temporary add items ({queue.length} items at {queue.interval}ms)
      </button>

      {/* bets table */}
      <div className={cn("flex flex-col gap-2", "mt-7")}>
        {/* headers row */}
        <Row className="pt-0 text-[#5B628C]" header>
          <Dict as="span" section="ResultFeed" name="gameName" />
          <Dict as="span" section="ResultFeed" name="user" />
          <Dict as="span" section="ResultFeed" name="betAmount" />
          <Dict as="span" section="ResultFeed" name="multiplier" />
          <Dict as="span" section="ResultFeed" name="payout" />
        </Row>

        {!betHistory?.length && (
          <div className="flex justify-center items-center h-60 !bg-transparent">
            {betHistory?.length !== 0 ? (
              <LoadingSpinner type="slots" />
            ) : (
              <Dict as="span" section="ResultFeed" name="no_bets" />
            )}
          </div>
        )}

        <RollingList orientation="vertical" className={cn("[&>*]:rounded-md")}>
          {/* body/bets rows */}
          {betHistory?.map?.((bet, i) => (
            <Row
              key={`${bet.id}-${i}`}
              className={clsx("text-white", id[i] % 2 === 0 && "bg-[#191f3b]")}
            >
              <Link
                shallow
                href={{
                  query: {
                    modal: "bet",
                    tab: "result",
                    betId: bet.id,
                  },
                }}
                className="flex items-center gap-2"
              >
                <span className="min-w-5 h-5 [&_*]:!w-full">
                  {bet.game.url && <GamesIcons code={bet.game.url} />}
                </span>

                {bet?.game?.name?.length > 15
                  ? bet.game.name.slice(0, 15) + "..."
                  : bet.game.name}
              </Link>

              <Link
                shallow
                href={{
                  query: {
                    modal: publicId === bet.user.publicId ? "profile" : "user",
                    user: bet.user.publicId,
                  },
                }}
                className="flex items-center gap-2"
              >
                {bet?.user?.rankLevel?.name && (
                  <RewardIcons
                    code={
                      bet?.user?.rankLevel?.name?.toUpperCase() as RewardCode
                    }
                    size={"large"}
                  />
                )}
                {bet?.user?.username} {!bet?.user?.username ? <Hidden /> : null}
              </Link>

              <ValueDisplay
                amount={bet.currencyAmount}
                size="small"
                textIcon
                currencyCode={bet.currency}
              />

              <span>{round2Decimal(bet.multiplier)}x</span>

              <span className="flex items-center gap-2">
                <ValueDisplay
                  amount={bet.currencyPayout}
                  size="small"
                  textIcon
                  currencyCode={bet.currency}
                  customClass={
                    isWin(bet) ? "!text-[#FFB300]" : "!text-[#5B628C]"
                  }
                  style={{
                    ...(isWin(bet) && {
                      textShadow: "0px 0px 10px #FB8C00",
                    }),
                  }}
                />

                <LocalArrow isWin={isWin(bet)} />
              </span>
            </Row>
          ))}
        </RollingList>
      </div>
    </div>
  );
};

export default BetsFeed;
