import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import st from "../styles/items/CarouselSlider.module.scss";
import useMobile from "../hooks/useMobile";
import SlotLink from "./SlotLink";
import NextArrowSquareIcon from "@assets/icons/general/NextArrowSquareIcon";
import PrevArrowSquareIcon from "@assets/icons/general/PrevArrowSquareIcon";
import clsx from "clsx";

export default function CarouselSlider({ items, resultCountNumber }) {
  const isMobile = useMobile({ breakPointThree: 600 });
  const [totalOffset, setTotalOffset] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const getContainerWidth = useCallback(
    () => containerRef.current?.clientWidth || 0,
    [containerRef]
  );
  const [containerWidth, setContainerWidth] = useState(getContainerWidth());

  const resultCount = useMemo(() => {
    if (isMobile === "mobile-one") return 6;
    else if (isMobile === "mobile-two") return 5;
    else if (isMobile === "mobile-three") return 4;
    else if (isMobile === "mobile-four") return 3;
    else return resultCountNumber;
  }, [isMobile, resultCountNumber]);

  // Calculate exact widths
  const { pageWidth, totalWidth, itemWidth } = useMemo(() => {
    if (typeof window === "undefined")
      return { pageWidth: 0, totalWidth: 0, itemWidth: 0 };

    // Calculate single item width including gap
    const gap = 10; // gap between items
    const itemWidth = (containerWidth - gap * (resultCount - 1)) / resultCount;
    const totalItems = items.length;
    // Calculate total width with exact gaps
    const totalContent = itemWidth * totalItems + gap * (totalItems - 1);

    return {
      pageWidth: containerWidth,
      totalWidth: totalContent,
      itemWidth,
    };
  }, [containerWidth, resultCount, items.length]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const updateDimensions = () => setContainerWidth(getContainerWidth());

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [getContainerWidth]);

  const getCurrentPage = useCallback(() => {
    return Math.round(Math.abs(totalOffset) / pageWidth);
  }, [totalOffset, pageWidth]);

  const handleNext = useCallback(() => {
    const currentPage = getCurrentPage();
    const nextPage = currentPage + 1;
    const maxOffset = -(totalWidth - pageWidth);
    const newOffset = Math.max(-nextPage * pageWidth, maxOffset);
    setTotalOffset(newOffset);
    setDragOffset(newOffset);
  }, [getCurrentPage, pageWidth, totalWidth]);

  const handlePrevious = useCallback(() => {
    const currentPage = getCurrentPage();
    const prevPage = currentPage - 1;
    const newOffset = -prevPage * pageWidth;
    setTotalOffset(Math.min(0, newOffset));
    setDragOffset(Math.min(0, newOffset));
  }, [getCurrentPage, pageWidth]);

  const isFirstPage = getCurrentPage() <= 0;
  const isLastPage = Math.abs(totalOffset) >= totalWidth - pageWidth;

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      const { deltaX } = eventData;
      const newOffset = totalOffset + deltaX;
      const maxOffset = -(totalWidth - pageWidth);
      const clampedOffset = Math.min(0, Math.max(maxOffset, newOffset));
      setDragOffset(clampedOffset);
      setIsDragging(true);
    },
    onSwiped: () => {
      setTotalOffset(dragOffset);
      setIsDragging(false);
    },
    // preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    trackTouch: true,
  });

  return (
    <div className={st["item-list-carousel-container"]}>
      <div className={st["next-prev-container"]}>
        <div
          className={clsx(st["arrow-icon"], isFirstPage && st["deactive"])}
          onClick={isFirstPage ? null : handlePrevious}
        >
          <PrevArrowSquareIcon />
        </div>
        <div
          className={clsx(st["arrow-icon"], isLastPage && st["deactive"])}
          onClick={isLastPage ? null : handleNext}
        >
          <NextArrowSquareIcon />
        </div>
      </div>

      <div
        {...handlers}
        className={st["slots-list-container"]}
        ref={containerRef}
        style={{
          gridAutoColumns: `${itemWidth}px`,
          gap: "10px",
          transform: `translateX(${dragOffset}px)`,
          transition: isDragging ? "none" : "transform 0.3s ease",
        }}
      >
        {items?.map((item, i) => (
          <SlotLink key={item.id} index={i} {...item} />
        ))}
      </div>
    </div>
  );
}
