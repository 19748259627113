import PubSub from "@lib/pubsub";
import TokenService from "@lib/services/Token.Service";
import { REFRESH_TOKEN } from "@lib/tools/customEvents";
import { getBrowserID } from "@lib/tools/device";
import { logError } from "@lib/tools/logger";
import { resetAll } from "@lib/tools/reset";
import { decode, JwtPayload } from "jsonwebtoken";
import { signIn, useSession, UseSessionOptions } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo } from "react";
import usePopup from "./usePopup";
import { useUserLegacy } from "./useUser";

interface Currency {
  code: string;
  prepend: string;
  append: string;
  rate: number;
}

interface Rewards {
  daily: number;
  weekly: number;
  monthly: number;
  rakeback: number;
  rakeback_amount: number;
}

/**
 * @deprecated use useUser instead
 */
export interface UserData {
  success: boolean;
  username: string;
  balance: string;
  vault_balance: string;
  currency: Currency;
  promo_eligible: boolean;
  type: string;
  email_verified_at: string | null;
  "2fa": number;
  steam_link: boolean;
  steam_config: boolean;
  total_wagered: number;
  affiliate_balance: string;
  rewards: Rewards;
  balance_promo: number;
  affiliate: {
    approved: boolean;
    redeemed: boolean;
    eligible: boolean;
    code: string;
  };
  deleted?: true;
}

const signInToken = async ({ jwt: token }: { jwt: string }) => {
  const device = await getBrowserID();

  return signIn("login-token", {
    redirect: false,
    token,
    device,
  }).catch(logError);
};

const signInCredentials = ({ username, password, code }) =>
  signIn("login-default", {
    redirect: false,
    username,
    password,
    code,
  });

export const logout = resetAll;

let isUpdating = false;
let updateFn = null;

PubSub.subscribe(REFRESH_TOKEN.type, () => {
  if (isUpdating || typeof updateFn !== "function") return;

  isUpdating = true;
  updateFn().finally(() => {
    isUpdating = false;
  });
});

/**
 * Custom hook for managing user session and authentication.
 *
 * @param options - Options for the hook.
 */
export const useUserSession = <R extends boolean>(
  options?: UseSessionOptions<R>,
) => {
  const popup = usePopup();
  const router = useRouter();
  const session = useSession<R>({
    onUnauthenticated: () => {
      // redirect to home
      popup({ code: "responses.er_not_allowed", type: 0 });
      router.push("/");
    },
    required: false,
    ...options,
  });

  updateFn = session.update;

  const token = session?.data?.user?.access_token;
  const decoded = useMemo(() => decode(token), [token]) as JwtPayload;
  const publicId = decoded?.public_id;
  const addTokenHeader = useCallback(
    () => (token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
    [token],
  );

  /**
   * Avoid using this to check if the user is authenticated or if userData is available.
   * Use hasToken instead.
   */
  const { data: userData } = useUserLegacy();
  const error = "error" in session ? session.error : session?.data?.error;

  const hasToken = session.status !== "unauthenticated" && !!token;

  /**
   * @deprecated use hasToken instead
   */
  const hasUserData = Object.keys(userData || {}).length > 0;

  /**
   * @deprecated use !hasToken instead
   */
  const isUnauthed = session.status !== "authenticated";

  /**
   * @deprecated use hasToken or !hasToken instead
   */
  const userChecked = isUnauthed || hasUserData; // done for compatibility with old code

  /**
   * Updates the user session.
   *
   * @returns {Promise<void>}
   */
  const mutate = useCallback(
    () => Promise.resolve(PubSub.publishSync(REFRESH_TOKEN.type, true)),
    [],
  );

  useEffect(() => {
    if (session?.data?.user) TokenService.setUser(session?.data?.user);
    if (!hasToken) TokenService.removeUser();
  }, [hasToken, session?.data?.user]);

  return {
    ...session,
    update: mutate,
    hasToken,
    token,
    decoded,
    session,
    balance: userData?.balance,
    userData: userData,
    mutate,
    error,
    signOut: logout,
    signInToken,
    signInCredentials,
    /**
     * @deprecated use !hasToken instead
     */
    isGuest: isUnauthed,
    hasUserData,
    userChecked,
    addTokenHeader,
    publicId,
  };
};

/**
 * Custom hook that retrieves session data and applies a provided function to it.
 *
 * @param  fn - The function to apply to the session data.
 * @param {*} [_default=undefined] - The default value to return if an error occurs.
 * @returns {*} The result of applying the function to the session data, or the default value if an error occurs.
 */
export const useSessionData = (
  // eslint-disable-next-line no-unused-vars
  fn: <T>(s: ReturnType<typeof useUserSession>) => T,
  _default = undefined,
) => {
  const session = useUserSession();
  const f = useCallback(
    (s) => {
      try {
        return fn(s);
      } catch (e) {
        return _default;
      }
    },
    [fn, _default],
  );
  const data = f(session);

  return useMemo(() => data, [data]);
};

export const useUserDetails = useUserLegacy;
