/**
 * Asserts that a value is not `null` or `undefined`; Asserts true.
 *
 * @template T - The type of the value being asserted.
 * @param value - The value to assert.
 * @param msgOrThrow - Optional message to include in the error if the assertion fails.
 * @param halt - Optional function to execute if the assertion fails. If this function returns `true`, the error will not be thrown.
 * @throws Throws an error if the value is `null` or `undefined` and the `halt` function does not return `true`.
 * @asserts value is NonNullable<T>
 */
export default function assert<T, M>(
  value: T,
  msgOrThrow?: M,
  halt?: (message?: M, value?: T) => true | void,
): asserts value is NonNullable<T> {
  const throwValue =
    typeof msgOrThrow === "string"
      ? new Error(msgOrThrow ?? "Value must be defined")
      : msgOrThrow;

  if (value === undefined || value === null) {
    if (halt) {
      if (!halt(msgOrThrow, value)) throw throwValue;
    } else {
      throw throwValue;
    }
  }
}
