import MetaContent from "@items/MetaContent";
import { logError } from "@lib/tools/logger";
import { fetchPageContent } from "@lib/tools/ssr";
import Link from "next/link";
import { Button } from "@components/new-ui/button";
import { Dict } from "@hooks/useLanguage";
import { GetServerSidePropsContext } from "next";

export default function Custom404({ content }) {
  return (
    <>
      <MetaContent meta={content?.meta || { title: "404 – Rainbet" }} />

      <div className="flex flex-col items-center justify-center h-full md:p-[87px] py-[100px] relative transition-all duration-200 ease-in-out gap-[95px] overflow-hidden">
        <h1 className="md:text-[350px] text-[200px] bg-gradient-to-b from-[#1c213c] from-[14.57%] to-transparent to-[87.8%] bg-clip-text text-transparent leading-[84%]">
          404
        </h1>

        <div className="text-center flex-col flex items-center gap-4">
          <Dict
            name="page_not_found"
            section="PageNotFound"
            className="text-2xl text-white"
            as="h4"
          />

          <div className="flex flex-col gap-0">
            <Dict
              name="page_moved"
              section="PageNotFound"
              className="text-sm text-[#666c88]"
            />
            <Dict
              name="back_homepage"
              section="PageNotFound"
              className="text-sm text-[#666c88]"
            />
          </div>

          <Link href="/">
            <Button variant="green">
              <Dict name="home_page" section="PageNotFound" />
            </Button>
          </Link>
        </div>

        <div className="hidden md:block w-[1003px] h-[396px] w-full flex-shrink-0 rounded-[50%] bg-gradient-to-b from-[rgba(105,110,135,0.08)] from-[0.06%] via-[rgba(105,110,135,0.02)] via-[19.8%] to-transparent to-[37.98%] absolute md:-bottom-[80px] -bottom-[100px]"></div>
      </div>
    </>
  );
}

export async function getStaticProps(context: GetServerSidePropsContext) {
  try {
    const content = await fetchPageContent(context);
    return { props: { content } };
  } catch (err) {
    logError(err);
    return { props: {} };
  }
}
