import React from "react";
import st from "@styles/components/RakebackBoost.module.scss";
import { Dict } from "@hooks/useLanguage";
import InfoBordered from "@assets/icons/general/InfoBordered";
import { useState } from "react";
import dynamic from "next/dynamic";

const RakebackProgress = dynamic(() => import("./rakeback-progress"), {
  ssr: false,
});

const formattedTime = (leftTime: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  const { days, hours, minutes, seconds } = leftTime;

  const totalHours = days * 24 + hours;

  const formattedHours = String(totalHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export default function RakebackBoost({
  progress,
  leftTime,
  rakebackRate,
  boostType,
  mobile = false,
  strokeWidth = 2.5,
  header = false,
  noHover = false,
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (header) {
    return (
      <div className={`${st["container"]} ${noHover ? st["noHover"] : ""}`}>
        <RakebackProgress
          size={25}
          progress={progress}
          strokeWidth={strokeWidth}
        />

        <div className={st["info"]}>
          <div className={st["label"]}>{rakebackRate}% Boost</div>
          <p className={st["time"]}>{formattedTime(leftTime)}</p>
        </div>

        <RakebackBoostTooltip
          rakebackRate={rakebackRate}
          boostType={boostType}
        />
      </div>
    );
  }

  return (
    <div
      className={`${st["container"]} ${st["dropdown-version"]} ${mobile && st["mobile"]} ${st["no-hover"]} ${isTooltipOpen && st["info-hovered"]}`}
    >
      <RakebackProgress
        size={42}
        progress={progress}
        strokeWidth={mobile ? 1.5 : strokeWidth}
      />

      <div className={st["info"]}>
        <div className={st["label"]}>{rakebackRate}% Boost</div>
        <p className={`${st["time"]} flex items-center gap-2`}>
          {formattedTime(leftTime)}
          <InfoBordered
            className="cursor-pointer !w-[18px] !h-[18px]"
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          />
        </p>
      </div>

      <RakebackBoostTooltip rakebackRate={rakebackRate} boostType={boostType} />
    </div>
  );
}

export function RakebackBoostTooltip({ rakebackRate, boostType }) {
  return (
    <div className={`${st["tooltip"]}`}>
      <span className={st["tooltip-text"]}>
        <Dict
          name="rakeboost_tooltip_label"
          className={st["rake-label"]}
          as="span"
          section="Rewards"
        />
        <Dict
          name="rakeboost_tooltip"
          as="span"
          replacements={{ rate: rakebackRate }}
          section="Rewards"
        />
        <Dict
          name={`BoostType.${boostType}`}
          as="span"
          className={st["boost-type"]}
          section="Rewards"
        />
      </span>
    </div>
  );
}
