import "../styles/globals.scss";

import IntegrationComponent from "@components/IntegrationComponents";
import GoogleSignIn from "@components/IntegrationComponents/google-sign-in";
import JotaiHydration from "@components/jotai-hydration";
import Providers, { defaultIntercomData } from "@components/Providers";
import { useShouldShowFooterFeed } from "@hooks/useShouldShowFooterFeed";
import useSocket from "@hooks/useSocket";
import useSocketManager from "@hooks/useSocketManager";
import useUser from "@hooks/useUser";
import { useUserSession } from "@hooks/useUserSession";
import {
  BALANCE_KEY,
  CURRENCIES_KEY,
  VAULT_BALANCE_KEY,
  WALLET_KEY,
} from "@hooks/useWallet";
import Intercom, { update } from "@intercom/messenger-js-sdk";
import type { InitType } from "@intercom/messenger-js-sdk/dist/types";
import API, { NODE_API } from "@lib/api/api";
import PubSub, { EVENTS, usePubSub, useSubscribe } from "@lib/pubsub";
import { BreadcrumbsSchema } from "@lib/tools/breadcrumbs";
import { getDeviceInfo } from "@lib/tools/device";
import { scrollTop, useLocale } from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import { useNextCssRemovalPrevention } from "@lib/tools/useNextCSSFix";
import { ErrorBoundary } from "@sentry/nextjs";
import {
  chatOpenAtom,
  miniSideAtom,
  racesAtom,
  requestTimeAtom,
} from "@store/global";
import { MobileBottomNav } from "@components/header/mobile-bottom-nav";
import { LoadingSimpleSpinner } from "@components/Loading/LoadingSimpleSpinner";
import { cn } from "@lib";
import StorageService from "@lib/services/Storage.Service";
import { pageLoadingAtom } from "@store/pageLoading";
import { userBalanceTypeAtom } from "@store/wallet";
import { motion } from "framer-motion";
import { useAtom, useSetAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Montserrat, Oswald } from "next/font/google";
import Head from "next/head";
import { useRouter as useNav, usePathname } from "next/navigation";
import { useRouter } from "next/router";
import Script from "next/script";
import { setCookie } from "nookies";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGA from "react-ga4";
import useSWR, { SWRConfig, useSWRConfig } from "swr";
import MicrosoftClarity from "./MicrosoftClarity";
import Tracking from "./Tracking ";
import Turnstile from "./Turnstile";
import { useUpdateUserPreferences } from "@hooks/useUpdateUserPreferences";

const font = Montserrat({
  subsets: ["latin"],
  fallback: ["sans-serif"],
  preload: true,
  variable: "--main-font",
});

const oswald = Oswald({
  subsets: ["latin"],
  weight: ["300", "400", "700"],
  variable: "--oswald-font",
});

const ChatContainer = dynamic(() => import("@components/chat/chat-container"));
const IntercomCustomButton = dynamic(
  () => import("@components/IntercomCustomButton")
);
const Popup = dynamic(() => import("../components/Popup/Popup"));
const NewHeader = dynamic(() => import("../components/header"));
const Footer = dynamic(() => import("../components/Footer"));
const NavSideBar = dynamic(() => import("../components/sidebar"));
const Modal = dynamic(() =>
  import("@components/Modal").then((mod) => mod.Modal)
);

const enable_logs = process.env.NEXT_PUBLIC_ENABLE_LOGS;

// https://github.com/vercel/next.js/issues/33286
// https://github.com/vercel/next.js/issues/17464#issuecomment-1537262075
const applyPreloadStylesheetLinks = () => {
  const preloadStylesheetLinks = document.querySelectorAll<HTMLLinkElement>(
    "link[rel='preload'][as='style']"
  );

  preloadStylesheetLinks.forEach((preloadLink) => {
    const existingStylesheetLink = document.querySelector(
      `link[rel='stylesheet'][href='${preloadLink.href}']`
    );

    if (!existingStylesheetLink) {
      const stylesheetLink = document.createElement("link");
      stylesheetLink.rel = "stylesheet";
      stylesheetLink.href = preloadLink.href;
      document.head.appendChild(stylesheetLink);
    }
  });
};

const idfn = () => ({});

type MyAppProps = AppProps<{
  raffleData: any;
  requestTime: string;
  races: any;
  isMobile: boolean;
  dictionary: any;
  providers: any[];
  wallet: any;
  currencies: any;
  balances: any;
  initialChatOpen: boolean;
  initialMiniSide: boolean;
  initialUserBalanceType: boolean;
}>;

function MyApp(props: MyAppProps) {
  // very important to prevent css removal, do not remove
  useNextCssRemovalPrevention();

  const { Component, pageProps } = props;

  // ALL THE HYDRATION MUST BE DONE BEFORE EVERYTHING ELSE
  // ALL THE HYDRATION MUST BE DONE BEFORE EVERYTHING ELSE

  // filling initial jotai state with server data
  useHydrateAtoms([[requestTimeAtom, pageProps.requestTime]] as any, {
    // needed for dev updates
    dangerouslyForceHydrate: true,
  });
  useHydrateAtoms([
    [racesAtom, pageProps.races],
    [miniSideAtom, pageProps.initialMiniSide],
    [chatOpenAtom, pageProps.initialChatOpen],
    [userBalanceTypeAtom, pageProps.initialUserBalanceType],
  ] as any);

  const { cache } = useSWRConfig();

  // filling initial swr cache data with server data
  if (
    pageProps.wallet &&
    (typeof window === "undefined" || !cache.get(WALLET_KEY)?.data)
  )
    cache.set(WALLET_KEY, { data: pageProps.wallet });

  if (
    pageProps.currencies &&
    (typeof window === "undefined" || !cache.get(CURRENCIES_KEY)?.data)
  )
    cache.set(CURRENCIES_KEY, { data: pageProps.currencies });

  const balanceType = pageProps.initialUserBalanceType
    ? "promotional"
    : ("primary" as const);

  // fix BALANCE_KEY("primary") when API implements it, primary should be dynamic
  if (
    pageProps.balances &&
    (typeof window === "undefined" ||
      !cache.get(BALANCE_KEY(balanceType))?.data)
  )
    cache.set(BALANCE_KEY(balanceType), {
      data: pageProps.balances[balanceType],
    });

  if (
    pageProps.balances &&
    (typeof window === "undefined" || !cache.get(VAULT_BALANCE_KEY)?.data)
  )
    cache.set(VAULT_BALANCE_KEY, { data: pageProps.balances.vault });

  // you cannot easily update the recoil state outside of a component
  // so this is a workaround to pass the init dictionary to the components e.g. Language.jsx
  if (!PubSub.state[EVENTS.DICT])
    PubSub.publishSync(EVENTS.DICT, pageProps.dictionary);

  if (!PubSub.state[EVENTS.PROVIDERS])
    PubSub.publishSync(EVENTS.PROVIDERS, pageProps.providers);

  if (!PubSub.state[EVENTS.MOBILE])
    PubSub.publishSync(EVENTS.MOBILE, pageProps.isMobile);

  if (!PubSub.state[EVENTS.RACES])
    PubSub.publishSync(EVENTS.RACES, pageProps.races);

  const { data: userData, mutate } = useUser();
  const locale = useLocale();
  const [blockStatus, setBlockStatus] = useState<string | false>("check");
  const [loading, setLoading] = useState(false);
  const sports = usePubSub(EVENTS.IS_SPORTSBOOK);
  const session = useUserSession();
  const activeGA = useRef(false);
  const caseBattlesSocket = useSocketManager();
  const shouldShowFooter = useShouldShowFooterFeed();
  const router = useRouter();
  const { replace } = useNav();
  const pathname = usePathname();
  const userCurrency = session?.userData?.currency?.code || "USD";
  const [pageLoading] = useAtom(pageLoadingAtom);
  const setRaces = useSetAtom(racesAtom as any);
  const { mutate: updatePreferences } = useUpdateUserPreferences();

  const isMobileLandingPage = useMemo(
    () => pathname?.includes("ph-mobile"),
    [pathname]
  );

  const lastPathname = useRef(
    locale === "en" ? pathname : `/${locale}${pathname}`
  );

  const races = useSWR(
    ["v1/public/active-races", userCurrency],
    ([url, currency]) =>
      NODE_API.get(url, { params: { currency } }).then((r) => r.data),
    {
      fallbackData: pageProps.races,
      revalidateOnMount: !pageProps?.races,
      revalidateOnFocus: false,
      refreshInterval: 60 * 60 * 1000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setRaces(races.data);
  }, [races.data, setRaces]);

  const clearUrlQueries = useCallback(
    () => replace(pathname, { scroll: true }),
    [pathname, replace]
  );

  useSubscribe(EVENTS.REMOVE_QUERY, clearUrlQueries);

  useEffect(() => {
    const { stag } = router.query;

    if (stag && typeof stag === "string") {
      StorageService.setAffiliateTag(stag);
      // Remove stag from URL while preserving other query parameters
      const params = new URLSearchParams(window.location.search);
      params.delete("stag");
      const newQuery = params.toString();
      const newPath = newQuery ? `${pathname}?${newQuery}` : pathname;
      replace(newPath);
    }
  }, [router.query, pathname, replace]);

  useEffect(() => {
    try {
      const logs = enable_logs.split(",").filter((log) => log);
      const shouldEnable = logs.some((log) => pathname.includes(`/${log}`));

      // eslint-disable-next-line no-console
      console.log = shouldEnable ? window.log : () => {};
    } catch {}
  }, [pathname]);

  useEffect(() => {
    router.events.on("routeChangeComplete", applyPreloadStylesheetLinks);
    return () => {
      router.events.off("routeChangeComplete", applyPreloadStylesheetLinks);
    };
  }, [router.events]);

  // logging buildTime to help with staging deployments
  useEffect(() => {
    console.info(
      `%c>>>>>> Build Time: ${new Date(document.body.dataset.buildTime).toLocaleString(navigator.language || navigator?.["userLanguage"])} <<<<<<`,
      "color: #e8e5ff; font-size: 1rem; font-family: var(--main-font);"
    );
  }, []);

  useEffect(() => {
    try {
      const logs = enable_logs.split(",").filter((log) => log);
      const shouldEnable = logs.some((log) => pathname.includes(`/${log}`));

      // eslint-disable-next-line no-console
      console.log = shouldEnable ? window.log : () => {};
    } catch {}
  }, [pathname]);

  useEffect(() => {
    const measurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
    const production = process.env.NEXT_PUBLIC_PRODUCTION;

    if (measurementId && production !== "false") {
      activeGA.current = true;
      ReactGA.initialize(measurementId);
    } else if (production === "true") {
      throw new Error(
        "Google Analytics Measurement ID is missing or not in production"
      );
    }
  }, []);

  //Send page view to Google Analytics
  useEffect(() => {
    if (activeGA.current)
      ReactGA.send({ hitType: "pageview", page: router.pathname });
  }, [router.pathname]);

  //Changes event listener for IOS devices
  useEffect(() => {
    if (typeof window !== "undefined") {
      const ua = navigator.userAgent;
      if (ua.match(/iPad|iPhone|iPod/)) {
        window.click = "touchstart";
      }
    }
  }, []);

  // const { data: ipData, error: ipError } =
  useSWR(
    blockStatus === "check" ? "v1/public/ip" : null,
    () => NODE_API.get("v1/public/ip").then((res) => res.data),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (err) => {
        logError("IP ERROR", err);
        setBlockStatus(false);
      },
    }
  );

  // useEffect(() => {
  //   if (!ipData) return;

  //   const condition = ipData.details.conditions;
  //   setBlockStatus(condition);

  //   if (condition !== false) {
  //     router.push({
  //       pathname: router.pathname,
  //       query: {
  //         ...router.query,
  //         modal: "blocker",
  //         type: condition,
  //       },
  //     });
  //   }
  // }, [ipData, router]);

  useEffect(() => {
    getDeviceInfo();
  }, []);

  // useEffect(() => {
  //   if (!session?.token) return;

  //   putSession(session.token);
  // }, [session?.token]);

  useEffect(() => {
    const handleStart = (r) => {
      if (!r) return;
      const stripquery = r.split("?")[0];
      if (stripquery === lastPathname.current) return;

      setLoading(true);
    };

    const handleComplete = (r) => {
      if (!r) return;
      const stripquery = r.split("?")[0];
      setLoading(false);
      if (stripquery === lastPathname.current) return;

      scrollTop();
      lastPathname.current = stripquery;

      if (window.innerWidth < 1100) {
        setCookie(null, "chatOpen", String(false), {
          path: "/",
          maxAge: 60 * 60 * 24 * 2, // 2 days
          sameSite: "lax",
        });
        updatePreferences({
          chat_open: false,
        });
      }
    };

    const onError = (r) => {
      logError("Route change error", r);
      setLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", onError);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", onError);
    };
  }, [router.events]);

  const chatSocket = useSocket(process.env.NEXT_PUBLIC_CHAT_SOCKET_URL);
  const userLanguage = userData?.preferences?.language;

  useEffect(() => {
    if (!userLanguage) return;
    if (userLanguage === locale) return;

    API.post("user/update-settings", {
      language: locale,
    }).then(() => mutate());
  }, [locale, mutate, userLanguage]);

  const intercomData: InitType = useMemo(
    () =>
      userData?.intercom?.user_hash
        ? {
            ...defaultIntercomData,
            name: userData.username, // Full name
            email: userData.email, // Email address
            user_id: userData.intercom.external_id,
            user_hash: userData.intercom.user_hash,
            created_at: new Date(userData.profile.registered_at).getTime(),
          }
        : defaultIntercomData,
    [
      userData?.email,
      userData?.intercom?.external_id,
      userData?.intercom?.user_hash,
      userData?.profile?.registered_at,
      userData?.username,
    ]
  );

  useEffect(() => {
    Intercom(intercomData);
    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    update(intercomData);
  }, [intercomData]);

  useEffect(() => {
    if (typeof window !== "undefined")
      document.body.style.setProperty("--main-font", font.style.fontFamily);
  }, []);

  const isLoading = pageLoading || loading;

  return (
    <div
      className={`site-container ${font.className}`}
      style={
        {
          "--main-font": font.style.fontFamily,
          "--oswald-font": oswald.style.fontFamily,
          "--vh": "calc(100vh * 0.01)",
        } as React.CSSProperties
      }
    >
      <IntegrationComponent.FacebookPixel />
      <IntegrationComponent.GoogleAnalyticsGA4 />
      <IntegrationComponent.GoogleTagManagerBody />
      <IntegrationComponent.GoogleTagManagerHead />
      <GoogleSignIn />

      <Head>
        {locale === "pt" && <meta name="robots" content="noindex"></meta>}

        {/* Regular favicons */}
        <link
          rel="icon"
          type="image/png"
          sizes="64x64"
          href="https://cdn.rainbet.com/brand/favicons/64x64.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="128x128"
          href="https://cdn.rainbet.com/brand/favicons/128x128.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="https://cdn.rainbet.com/brand/favicons/192x192.png"
        />

        {/* Apple touch icons */}
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://cdn.rainbet.com/brand/favicons/120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://cdn.rainbet.com/brand/favicons/152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://cdn.rainbet.com/brand/favicons/180x180.png"
        />
      </Head>

      <Modal blockStatus={blockStatus} />
      <Popup />

      {blockStatus !== "block" && (
        <>
          <div className="relative min-h-svh grid grid-rows-[68px_auto]">
            <NewHeader />

            {/* Body Area - Updated to Grid */}
            <div className="grid 2lg:grid-cols-[auto_1fr_auto] flex-1">
              <NavSideBar />

              {/* Content Area */}
              <motion.div
                className={cn(
                  "body-content-container h-full relative [@media(max-width:1100px)]:pb-[68px]",
                  isLoading ? "overflow-hidden" : "overflow-auto"
                )}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                }}
              >
                {isLoading ? (
                  <div className="absolute inset-0 z-[1000] bg-primary-background-color">
                    <div className="flex justify-center items-center w-full h-full">
                      <LoadingSimpleSpinner className="h-14 w-14 animate-spin text-white" />
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="max-w-[1200px] w-full mx-auto relative p-4 inner-body-container"
                      id="mainBody"
                    >
                      <Component
                        {...pageProps}
                        setLoading={idfn}
                        caseBattlesSocket={caseBattlesSocket}
                      />

                      {!sports && <IntercomCustomButton />}
                    </div>

                    {!isMobileLandingPage && <Footer feed={shouldShowFooter} />}
                  </>
                )}
              </motion.div>

              {/* Chat Area - Will collapse when chat is false */}
              <ChatContainer
                isMod={userData?.chat?.moderator || false}
                chatSocket={chatSocket}
              />
            </div>

            <MobileBottomNav />
          </div>
        </>
      )}
    </div>
  );
}

export default function App(props) {
  const {
    pageProps: { session },
  } = props;

  const production = process.env.NEXT_PUBLIC_PRODUCTION;

  return (
    <ErrorBoundary>
      <SWRConfig value={{ provider: () => new Map() }}>
        <Providers session={session}>
          {production !== "false" && (
            <Script src={process.env.NEXT_PUBLIC_MOUSEFLOW_URL} />
          )}

          {production !== "false" && <MicrosoftClarity />}

          <BreadcrumbsSchema />
          <Tracking />
          <Turnstile />
          {/* @ts-expect-error ---- */}
          <JotaiHydration raffleData={props.pageProps.raffleData} />
          <MyApp {...props} />
        </Providers>
      </SWRConfig>
    </ErrorBoundary>
  );
}
