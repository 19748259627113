import { NODE_API } from "@lib/api/api";
import useToken from "./useToken";
import useSWR, { SWRConfiguration, useSWRConfig } from "swr";
import { RewardsFilters, RewardsData } from "@/types/rewards";

type Options = {
  filters?: RewardsFilters[];
  swr?: SWRConfiguration;
};

const getRewards = (
  token: string,
  filters: RewardsFilters[] = [],
  headers: Record<string, string> = {}
) => {
  const url = filters
    ? `v1/rewards/available?filter=${filters}`
    : "v1/rewards/available";

  return NODE_API.get<RewardsData>(url, {
    headers: { Authorization: `Bearer ${token}`, ...headers },
  }).then((res) => res.data);
};

export const REWARDS_KEY = "v1/rewards/available";

const useRewards = (options: Options = {}) => {
  const token = useToken();
  const filters = options.filters || null;
  const { cache } = useSWRConfig();

  return useSWR<RewardsData, any, SWRConfiguration>(
    token ? REWARDS_KEY : null,
    () => getRewards(token, filters),
    {
      ...options.swr,
      fallbackData: cache.get(REWARDS_KEY)?.data,
      revalidateOnMount: !cache.get(REWARDS_KEY)?.data,
    }
  );
};

export default useRewards;
