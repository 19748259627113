import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/new-ui/popover";
import { memo, ElementType, useEffect, useMemo, useState } from "react";
import {
  RewardsCalendarIcon,
  RewardsDailyIcon,
  RewardsMontlyRewardsIcon,
  RewardsWeeklyIcon,
  RewardsGiftIcon,
} from "@assets/svgs/RewardsIcons";
import { cn, getTimeRemaining } from "@lib";
import useNow from "@hooks/useNow";
import { useSetAtom } from "jotai";
import { activeRewardsDropdownAtom } from "@store/rewards-dropdown-atom";
import RewardsDropdownContent from "./rewards-dropdown-content";
import { isAfter } from "date-fns";
import { RewardsData } from "@/types/rewards";
import { GiftIcon } from "../icons";
import { buttonVariants } from "@components/new-ui/button";
import useToken from "@hooks/useToken";

interface RewardsDropdownProps {
  rewardsData: RewardsData;
  getRewardsData: () => void;
  isRakeBoostActive: boolean;
  boostProgress: number;
}

type Chest = Readonly<{
  image: ElementType;
  name: string;
  api: "rakeback" | "calendar" | "daily" | "weekly" | "monthly";
}>;

export const CHESTS: Chest[] = [
  { image: RewardsGiftIcon, name: "rakeback", api: "rakeback" },
  { image: RewardsCalendarIcon, name: "daily_calendar", api: "calendar" },
  {
    image: RewardsDailyIcon,
    name: "daily_bonus",
    api: "daily",
  },
  { image: RewardsWeeklyIcon, name: "weekly_bonus", api: "weekly" },
  { image: RewardsMontlyRewardsIcon, name: "monthly_bonus", api: "monthly" },
];

function RewardsDropdown({
  rewardsData,
  getRewardsData,
  isRakeBoostActive,
  boostProgress,
}: RewardsDropdownProps) {
  const now = useNow();
  const setActiveDropdown = useSetAtom(activeRewardsDropdownAtom);
  const [isOpen, setIsOpen] = useState(false);
  const token = useToken();

  const closeDropdown = () => {
    setIsOpen(false);
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const rewardsAvailable = useMemo(() => {
    if (!rewardsData) return 0;

    const availableRewards = CHESTS.reduce((total, chest) => {
      const data: {
        sufficient_funds?: boolean;
        claimable: string;
      } =
        chest.api === "rakeback"
          ? rewardsData.rakeback
          : chest.api === "calendar"
            ? rewardsData?.calendar
            : rewardsData?.chest_rewards?.[chest.api];

      if (!data) return total;

      const hasSufficientFunds = !!data?.sufficient_funds;
      const hasExpired = isAfter(now, data?.claimable.replace("Z", ""));

      const isAvailable =
        chest.api === "rakeback" || chest.api === "calendar"
          ? hasSufficientFunds && hasExpired
          : hasExpired;

      return total + (isAvailable ? 1 : 0);
    }, 0);

    return availableRewards + (rewardsData?.keys?.count > 0 ? 1 : 0);
  }, [now, rewardsData]);

  const countdowns = useMemo(
    () =>
      !rewardsData
        ? {}
        : CHESTS.reduce((acc, chest) => {
            const data =
              chest.api === "rakeback"
                ? rewardsData?.rakeback?.claimable.replace("Z", "")
                : chest.api === "calendar"
                  ? rewardsData?.calendar?.claimable.replace("Z", "")
                  : rewardsData.chest_rewards?.[chest.api]?.claimable.replace(
                      "Z",
                      ""
                    );

            return {
              ...acc,
              [chest.api]: getTimeRemaining(now, data),
            };
          }, {}),
    [rewardsData, now]
  );

  useEffect(() => {
    if (isOpen) {
      getRewardsData();
    }
  }, [isOpen, getRewardsData]);

  return (
    <Popover
      open={isOpen}
      onOpenChange={() => {
        setIsOpen((prev) => !prev);
        setActiveDropdown((prev) => !prev);
      }}
    >
      <PopoverTrigger className="relative">
        <div
          className={buttonVariants({
            variant: "dark",
            className: cn(
              "w-[48px] h-[48px] !min-h-[48px] !px-0 !py-0 [&_svg]:size-5 relative",
              token &&
                "border-none rounded-none [@media(max-width:450px)]:bg-transparent [@media(max-width:450px)]:px-0 [@media(max-width:450px)]:w-[38px] [@media(max-width:450px)]:hover:!bg-transparent"
            ),
          })}
          aria-selected={isOpen}
        >
          <GiftIcon />
        </div>
        {rewardsAvailable >= 1 && (
          <span className="absolute top-1 right-1 min-w-[17px] h-[17px] flex items-center justify-center text-[10px] rounded-full border border-solid border-white/50 bg-gradient-to-b from-[#7DD934] to-[#7DD934] text-white shadow-[0px_0px_12px_0px_rgba(0,119,219,0.5),0px_0px_6px_0px_rgba(0,0,0,0.3)] transition-all duration-300 ease">
            {rewardsAvailable}
          </span>
        )}
      </PopoverTrigger>
      <PopoverContent align="end">
        <RewardsDropdownContent
          countdowns={countdowns}
          rewardsData={rewardsData}
          getRewardsData={getRewardsData}
          closeDropdown={closeDropdown}
          isRakeBoostActive={isRakeBoostActive}
          boostProgress={boostProgress}
          now={now}
        />
      </PopoverContent>
    </Popover>
  );
}

export default memo(RewardsDropdown);
