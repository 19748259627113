export default function TheatherModeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M21.0938 4.6875V9.375C21.0938 9.5822 21.0114 9.78091 20.8649 9.92743C20.7184 10.0739 20.5197 10.1562 20.3125 10.1562C20.1053 10.1562 19.9066 10.0739 19.7601 9.92743C19.6136 9.78091 19.5312 9.5822 19.5312 9.375V6.57324L14.6152 11.4902C14.4686 11.6368 14.2698 11.7192 14.0625 11.7192C13.8552 11.7192 13.6564 11.6368 13.5098 11.4902C13.3632 11.3436 13.2808 11.1448 13.2808 10.9375C13.2808 10.7302 13.3632 10.5314 13.5098 10.3848L18.4268 5.46875H15.625C15.4178 5.46875 15.2191 5.38644 15.0726 5.23993C14.9261 5.09341 14.8438 4.8947 14.8438 4.6875C14.8438 4.4803 14.9261 4.28159 15.0726 4.13507C15.2191 3.98856 15.4178 3.90625 15.625 3.90625H20.3125C20.5197 3.90625 20.7184 3.98856 20.8649 4.13507C21.0114 4.28159 21.0938 4.4803 21.0938 4.6875ZM10.3848 13.5098L5.46875 18.4268V15.625C5.46875 15.4178 5.38644 15.2191 5.23993 15.0726C5.09341 14.9261 4.8947 14.8438 4.6875 14.8438C4.4803 14.8438 4.28159 14.9261 4.13507 15.0726C3.98856 15.2191 3.90625 15.4178 3.90625 15.625V20.3125C3.90625 20.5197 3.98856 20.7184 4.13507 20.8649C4.28159 21.0114 4.4803 21.0938 4.6875 21.0938H9.375C9.5822 21.0938 9.78091 21.0114 9.92743 20.8649C10.0739 20.7184 10.1562 20.5197 10.1562 20.3125C10.1562 20.1053 10.0739 19.9066 9.92743 19.7601C9.78091 19.6136 9.5822 19.5312 9.375 19.5312H6.57324L11.4902 14.6152C11.6368 14.4686 11.7192 14.2698 11.7192 14.0625C11.7192 13.8552 11.6368 13.6564 11.4902 13.5098C11.3436 13.3632 11.1448 13.2808 10.9375 13.2808C10.7302 13.2808 10.5314 13.3632 10.3848 13.5098Z"
        fill="#6C75AC"
      />
    </svg>
  );
}
