import { Provider } from "jotai";
import { SessionProvider } from "next-auth/react";
import React from "react";
import { RecoilRoot } from "recoil";
import store from "@store";

// DO NOT USE RECOIL
// DO NOT USE RECOIL
// DO NOT USE RECOIL
// DO NOT USE RECOIL

export const defaultIntercomData = {
  api_base: "https://api-iam.intercom.io",
  app_id: "w3maa0h3",
  hide_default_launcher: true,
} as const;

const Providers = ({ children, session }) => {
  const refetchInterval =
    +process.env["NEXT_PUBLIC_REFRESH_INTERVAL"] || 25 * 60;

  return (
    <Provider store={store}>
      <RecoilRoot>
        <SessionProvider
          {...(session ? { session } : {})}
          refetchInterval={refetchInterval - 5}
        >
          {children}
        </SessionProvider>
      </RecoilRoot>
    </Provider>
  );
};

export default Providers;
