import CurrencyIcons from "./CurrencyIcons";
import st from "../styles/items/ValueDisplay.module.scss";
import { formatValueDisplay } from "../lib/tools/convert";
import { useMemo, memo, PropsWithoutRef, CSSProperties } from "react";
import { classnames, mergeClasses, useLocale } from "../lib/tools/helpers";
import useWallet from "@hooks/useWallet";
import { sBig } from "@lib/tools/numberHelpers";
import type { Currency } from "@/types";

interface ShouldConvertOptions {
  raw?: boolean;
  customFiatRate?: number | false;
}

export const useShouldConvert = (
  currencyCode: string | false,
  options?: ShouldConvertOptions
) => {
  const { raw, customFiatRate } = options || {};
  const { activeRate, currencies } = useWallet();
  const currency = currencies?.[currencyCode as string];

  const shouldConvert = useMemo(
    () => currency?.rate !== activeRate?.rate,
    [activeRate?.rate, currency?.rate]
  );

  return raw || customFiatRate ? false : shouldConvert;
};

type ValueDisplayProps = Readonly<{
  amount?: number;
  size?: "small" | "mid" | "large";
  customClass?: string;
  classType?: string | string[];
  iconCustomClass?: string;
  icon?: boolean;
  decimals?: number;
  raw?: boolean;
  iconOnly?: boolean;
  currencyCode?: string;
  textSize?: number | false;
  change?: (value: string) => string;
  greyIconOnEmpty?: boolean;
  customFiatRate?: number | false;
  debug?: boolean;
  textIcon?: boolean;
  symbolPosition?: boolean;
  style?: CSSProperties;
  rounding?: "up" | "down";
}>;

function ValueDisplay({
  amount = 0,
  size = "mid",
  customClass,
  classType,
  iconCustomClass,
  icon = false,
  raw = false,
  iconOnly = false,
  currencyCode,
  textSize = false,
  change,
  greyIconOnEmpty = false,
  customFiatRate = false,
  debug,
  textIcon = false,
  symbolPosition = true,
  style,
  rounding,
}: ValueDisplayProps) {
  const { activeRate, currencies } = useWallet();
  const locale = useLocale();
  const shouldConvert = useShouldConvert(currencyCode, { raw, customFiatRate });
  const decimals = activeRate?.display?.decimals ?? 2;

  const processed = useMemo(
    () =>
      shouldConvert
        ? +sBig(amount).div(
            sBig(
              // div 0 fix
              Math.max(
                1,
                currencies?.[currencyCode?.toUpperCase?.()]?.rate || 1
              )
            )
          )
        : amount,
    [amount, shouldConvert, currencies, currencyCode]
  );

  const formattedAmount = useMemo(() => {
    if (iconOnly) return "";

    const formatOptions = {
      rate: customFiatRate
        ? { rate: customFiatRate }
        : !shouldConvert && currencyCode && typeof currencyCode === "string"
          ? currencies?.[currencyCode?.toUpperCase?.()]
          : activeRate,
      raw: !customFiatRate && (!shouldConvert || raw),
      decimals,
      locale,
      symbol: symbolPosition,
      debug,
      ...(rounding && { round: { direction: rounding } }),
    };

    const baseFormatted = formatValueDisplay(processed, formatOptions);

    return change ? change(baseFormatted) : baseFormatted;
  }, [
    activeRate,
    change,
    currencies,
    currencyCode,
    customFiatRate,
    debug,
    decimals,
    iconOnly,
    locale,
    processed,
    raw,
    shouldConvert,
    symbolPosition,
  ]);

  const textStyle = useMemo(
    () => (textSize ? { fontSize: textSize + "px" } : undefined),
    [textSize]
  );

  const containerClassName = useMemo(
    () =>
      mergeClasses(
        classnames(st, textIcon ? "text-display" : "value-display", classType),
        customClass
      ),
    [textIcon, classType, customClass]
  );

  const valueClassName = useMemo(
    () => classnames(st, "value", textIcon ?? "value-number-display"),
    [textIcon]
  );

  return (
    <div {...containerClassName} style={{ ...style, ...textStyle }}>
      {icon && (
        <CurrencyIcons
          size={size}
          classItem={iconCustomClass}
          code={shouldConvert && !raw ? undefined : currencyCode || ""}
          emptyValue={+amount === 0}
          greyEmpty={greyIconOnEmpty}
          textIcon={textIcon}
        />
      )}

      {formattedAmount && <span {...valueClassName}>{formattedAmount}</span>}
    </div>
  );
}

export default memo(ValueDisplay);
