import React, { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import useLanguage from "@hooks/useLanguage";
import { SvgWeeklyCalendar } from "@assets/svgs/SvgWeeklyCalendar";
import { Button } from "../Button/Button";
import st from "./RewardsBannerWeekly.module.scss";
import { useWindowWidth } from "@hooks/useWindowWidth";
import useNow from "@hooks/useNow";
import { timeLeft } from "@lib";
import { RewardsData } from "@/types/rewards";

export function RewardsBannerWeekly({
  rewardsData,
  changeScroll,
}: {
  rewardsData: RewardsData;
  changeScroll: () => void;
}) {
  const L = useLanguage(["pageContent", "common"]);
  const router = useRouter();
  const now = useNow();

  const remainingTime = useMemo(
    () =>
      timeLeft(
        now,
        rewardsData?.chest_rewards?.weekly?.claimable.replace("Z", "")
      ),
    [now, rewardsData?.chest_rewards?.weekly?.claimable]
  );

  const isRewardClaimable = useMemo(() => {
    if (!remainingTime) return true;

    return (
      remainingTime.days < 1 &&
      remainingTime.hours < 1 &&
      remainingTime.minutes < 1 &&
      remainingTime.seconds < 1
    );
  }, [remainingTime]);

  useEffect(() => {
    if (!rewardsData?.chest_rewards?.weekly?.claimable) return;
    const rt = remainingTime || null;
    const isLessThan24Hours = rt?.days === 0;
    if (!isLessThan24Hours) return;
    changeScroll?.();
  }, [
    remainingTime,
    changeScroll,
    rewardsData?.chest_rewards?.weekly?.claimable,
  ]);

  const currentWidth = useWindowWidth();

  return (
    <div
      className={`${st["rewards-banner-container"]} ${isRewardClaimable ? st["height-claim"] : null}`}
    >
      <div className={st["rewards-text-box"]}>
        <div className={st["rewards-flex"]}>
          <span
            className={st["rewards-title"]}
            dangerouslySetInnerHTML={{
              __html: L(
                "homeBanner.loggedIn_banner.countdownTitleWeekly",
                {}
              ) as string,
            }}
          />
        </div>
      </div>

      <div className={st["rewards-container"]}>
        <div className={st["countdown-weekly-container"]}>
          {currentWidth > 749 ? (
            <div className={st["countdown-title-box"]}>
              <div
                style={{ alignItems: "center", display: "flex", gap: "12px" }}
              >
                <SvgWeeklyCalendar />

                <span
                  className={st["weekly-title"]}
                  dangerouslySetInnerHTML={{
                    __html: L(
                      "homeBanner.loggedIn_banner.weeklyTitle",
                      {}
                    ) as string,
                  }}
                />
              </div>

              <div>
                <p
                  className={st["weekly-text"]}
                  dangerouslySetInnerHTML={{
                    __html: L(
                      "homeBanner.loggedIn_banner.weeklyText",
                      {}
                    ) as string,
                  }}
                />
              </div>
            </div>
          ) : (
            <div style={{ alignItems: "center", display: "flex", gap: "12px" }}>
              <SvgWeeklyCalendar />

              <span
                className={st["weekly-title"]}
                dangerouslySetInnerHTML={{
                  __html: L(
                    "homeBanner.loggedIn_banner.weeklyTitle",
                    {}
                  ) as string,
                }}
              />
            </div>
          )}

          {!isRewardClaimable ? (
            <div className={st["countdown-box"]}>
              <div className={st["countdown-box-border"]}>
                <span>{remainingTime.days}</span>

                <span
                  style={{
                    color: "rgba(232, 229, 255, .75)",
                    fontSize: "10px",
                  }}
                >
                  DAYS
                </span>
              </div>

              <div className={st["countdown-box-border"]}>
                <span>{remainingTime.hours}</span>

                <span
                  style={{
                    color: "rgba(232, 229, 255, .75)",
                    fontSize: "10px",
                  }}
                >
                  HOUR
                </span>
              </div>

              <div className={st["countdown-box-border"]}>
                <span>{remainingTime.minutes}</span>

                <span
                  style={{
                    color: "rgba(232, 229, 255, .75)",
                    fontSize: "10px",
                  }}
                >
                  MIN
                </span>
              </div>

              <div className={st["countdown-box-border"]}>
                <span>{remainingTime.seconds}</span>

                <span
                  style={{
                    color: "rgba(232, 229, 255, .75)",
                    fontSize: "10px",
                  }}
                >
                  SEC
                </span>
              </div>
            </div>
          ) : (
            <div className={st["text-button-container"]}>
              <Button
                onClick={() => {
                  router.push("/rewards");
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: L(
                      "homeBanner.loggedIn_banner.buttonClaimReward",
                      {}
                    ) as string,
                  }}
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
