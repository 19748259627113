import st from "../styles/items/GamesIcons.module.scss";

import CaseOpen from "../assets/icons/games/CaseOpenIcon";
import Crash from "../assets/icons/games/Crash";
import Dice from "../assets/icons/games/Dice";
import Plinko from "../assets/icons/games/Plinko";
import Roulette from "../assets/icons/games/Roulette";
import Slots from "../assets/icons/games/Slots";
import LiveGamesIcon from "../assets/icons/games/LiveGamesIcon";
import SportsIcon from "../assets/icons/general/Sports";
import BlackJack from "../assets/icons/games/Blackjack";
import Baccarat from "../assets/icons/games/Baccarat";
import RouletteSlots from "../assets/icons/games/RouletteSlots";
import GameShowIcon from "../assets/icons/games/GameShowIcon";
import MiniBattleIcon from "@assets/icons/games/MiniBattleIcon";
import KenoIcon from "@assets/icons/games/KenoIcon";
import MinesIcon from "@assets/icons/games/MinesIcon";

export default function GamesIcons(props) {
  const {
    code = "CaseOpen",
    size = false,
    noWidth = false,
    hasGlow = false,
    hasDefaultColor = false,
  } = props;

  let icon = null;
  let classNameItem = `${size ? st[size] : null} ${st["value-icon"]} ${noWidth && st["no-width"]} ${hasGlow ? st["has-glow"] : st["no-glow"]} ${!hasDefaultColor && st["has-custom-color"]}`;

  switch (code?.toLowerCase()) {
    case "gold-chests":
    case "Gold Chests":
    case "case open":
    case "caseopen":
    case "case-open":
    case "case-opening":
      icon = (
        <div className={classNameItem}>
          <CaseOpen />
        </div>
      );
      break;
    case "crash":
      icon = (
        <div className={classNameItem}>
          <Crash />
        </div>
      );
      break;
    case "case-battles":
      icon = (
        <div className={classNameItem}>
          <MiniBattleIcon />
        </div>
      );
      break;

    case "double-down":
      icon = (
        <div className={classNameItem}>
          <MiniBattleIcon />
        </div>
      );
      break;

    case "dice":
      icon = (
        <div className={classNameItem}>
          <Dice />
        </div>
      );
      break;
    case "plinko":
      icon = (
        <div className={classNameItem}>
          <Plinko />
        </div>
      );
      break;
    case "roulette":
      icon = (
        <div className={classNameItem}>
          <Roulette />
        </div>
      );
      break;
    case "slots":
      icon = (
        <div className={classNameItem}>
          <Slots />
        </div>
      );
      break;
    case "live-games":
      icon = (
        <div className={classNameItem}>
          <LiveGamesIcon />
        </div>
      );
      break;
    case "live":
      icon = (
        <div className={classNameItem}>
          <LiveGamesIcon />
        </div>
      );
      break;
    case "blackjack":
      icon = (
        <div className={classNameItem}>
          <BlackJack />
        </div>
      );
      break;
    case "baccarat":
      icon = (
        <div className={classNameItem}>
          <Baccarat />
        </div>
      );
      break;
    case "roulette-slots":
      icon = (
        <div className={classNameItem}>
          <RouletteSlots />
        </div>
      );
      break;
    case "game-shows":
      icon = (
        <div className={classNameItem}>
          <GameShowIcon />
        </div>
      );
      break;

    case "sports":
      icon = (
        <div className={classNameItem}>
          <SportsIcon width="20" height="20" viewBox="0 0 20 20" />
        </div>
      );
      break;
    case "slots":
      icon = (
        <div className={classNameItem}>
          <Slots />
        </div>
      );
      break;
    case "keno":
      icon = (
        <div className={classNameItem}>
          <KenoIcon />
        </div>
      );
      break;
    case "mines":
      icon = (
        <div className={classNameItem}>
          <MinesIcon />
        </div>
      );
      break;

    case "mines-game":
      icon = (
        <div className={classNameItem}>
          <MinesIcon />
        </div>
      );
      break;
    default:
      icon = (
        <div className={classNameItem}>
          <Slots />
        </div>
      );
      break;
  }

  return icon;
}
