const key = [
  ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"],
  ["", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"],
  ["", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
] as const;

export default function romanize(ns: number | string): string {
  const n = +ns;
  if (isNaN(n)) return n.toString();

  const digits = String(+n).split("");

  let roman = "";
  let i = 3;

  while (i--) {
    const digit = +digits.pop();
    roman = (key[i][digit] || "") + roman;
  }

  return "M".repeat(+digits.join("")) + roman;
}
