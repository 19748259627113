export default function ShieldTick({
  width = 26,
  height = 31,
  fill = "#7179A5",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 31"
      fill={fill}
      {...props}
    >
      <path
        d="M25.8423 11.9659L24.7634 4.80859C16.9267 4.80859 13.0083 0.808594 13.0083 0.808594C13.0083 0.808594 9.08995 4.80859 1.25321 4.80859L0.174355 11.9659C-0.275547 14.9472 0.18598 17.9977 1.49604 20.7015C2.8061 23.4053 4.90097 25.631 7.49517 27.0753L13.0083 30.1419L18.5215 27.0753C21.1157 25.631 23.2105 23.4053 24.5206 20.7015C25.8307 17.9977 26.2922 14.9472 25.8423 11.9659ZM19.1562 12.4179L11.3195 20.4179C11.0746 20.6679 10.7424 20.8083 10.3961 20.8083C10.0497 20.8083 9.71758 20.6679 9.47264 20.4179L6.8604 17.7513C6.73565 17.6283 6.63615 17.4811 6.56769 17.3185C6.49924 17.1558 6.46321 16.9808 6.4617 16.8038C6.46019 16.6268 6.49324 16.4512 6.55892 16.2873C6.62459 16.1235 6.72157 15.9746 6.84421 15.8494C6.96684 15.7242 7.11267 15.6252 7.27319 15.5582C7.43371 15.4911 7.6057 15.4574 7.77912 15.4589C7.95255 15.4605 8.12394 15.4972 8.28329 15.5671C8.44264 15.637 8.58677 15.7386 8.70725 15.8659L10.3961 17.5899L17.3094 10.5326C17.5557 10.2897 17.8856 10.1553 18.2281 10.1584C18.5706 10.1614 18.8982 10.3016 19.1403 10.5488C19.3825 10.796 19.5199 11.1305 19.5228 11.4801C19.5258 11.8297 19.3942 12.1665 19.1562 12.4179Z"
        fill={fill}
      />
    </svg>
  );
}
